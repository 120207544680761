/* ------------------------------------------------------------------------------
 *
 *  # layout_3
 *
 *  Import all scss files needed for the layout.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Bootstrap v4.1.1 (https://getbootstrap.com)
 *
 *  Copyright 2011-2018 The Bootstrap Authors
 *  Copyright 2011-2018 Twitter, Inc.
 *  Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Global configuration
 *
 *  Here you can change main theme, enable or disable certain components and
 *  optional styles. This allows you to include only components that you need.
 *
 *  'true'  - enables component and includes it to main CSS file.
 *  'false' - disables component and excludes it from main CSS file.
 *
 *  Layout helper: @if $layout == 'base' {...}
 *  Theme helper: @if $theme == 'material' {...}
 *  Component helper: @if $enable-* {...}
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Custom Limitless functions
 *
 *  Utility mixins and functions for evalutating source code across our variables, maps, and mixins.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Custom template mixins
 *
 *  All custom mixins are prefixed with "ll-" to avoid conflicts
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Main colors
 *
 *  List of the template main color palettes
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Default Bootstrap variable overrides
 *
 *  Variables should follow the `$component-state-property-size` formula for
 *  consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
 *  Also includes custom variables, all marked with "!default" flag.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Additional variables
 *
 *  Mainly 3rd party libraries and additional variables for default
 *  Bootstrap components.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Mixins
 *
 *  Import Bootstrap mixins with overrides
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Badge mixin
 *
 *  Override and extend default badge mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Buttons mixin
 *
 *  Override and extend default buttons mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Caret mixin
 *
 *  Override and extend default cared mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Forms mixin
 *
 *  Override and extend default forms mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Background mixin
 *
 *  Override and extend default background mixin.
 *
 * ---------------------------------------------------------------------------- */
:root {
  --blue: #2196F3;
  --indigo: #3F51B5;
  --purple: #673AB7;
  --pink: #E91E63;
  --red: #F44336;
  --orange: #FF7043;
  --yellow: #FF9800;
  --green: #4CAF50;
  --teal: #009688;
  --cyan: #00BCD4;
  --white: #fff;
  --gray: #999;
  --gray-dark: #555;
  --primary: #2196F3;
  --secondary: #777;
  --success: #4CAF50;
  --info: #00BCD4;
  --warning: #FF7043;
  --danger: #F44336;
  --light: #fafafa;
  --dark: #293a50;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #ccc;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #eee !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #ddd; }
  .table .thead-dark th {
    color: inherit;
    border-color: #ddd; } }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5385;
  color: #333;
  text-align: left;
  background-color: #f5f5f5; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.625rem; }

p {
  margin-top: 0;
  margin-bottom: 0.625rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 500; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #2196F3;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0a6ebd;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem 1.25rem;
  padding-bottom: 0.75rem 1.25rem;
  color: #999;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.625rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.5385;
  color: inherit; }

h1, .h1 {
  font-size: 1.5625rem; }

h2, .h2 {
  font-size: 1.4375rem; }

h3, .h3 {
  font-size: 1.3125rem; }

h4, .h4 {
  font-size: 1.1875rem; }

h5, .h5 {
  font-size: 1.0625rem; }

h6, .h6 {
  font-size: 0.9375rem; }

.lead {
  font-size: 1.01562rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.5385; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.5385; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.5385; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.5385; }

hr {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 0;
  border-top: 1px solid #ddd; }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.25rem 0.5rem;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 1.25rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1.25rem;
  font-size: 0.9375rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #999; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.1875rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.625rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #999; }

code {
  font-size: 0.75rem;
  color: #D81B60;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.75rem;
  color: #fff;
  background-color: #333;
  border-radius: 0.125rem;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25); }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
    box-shadow: none; }

pre {
  display: block;
  font-size: 0.75rem;
  color: #333; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 450px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -ms-flex: 0 0 25%;
      flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -ms-flex: 0 0 50%;
      flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -ms-flex: 0 0 75%;
      flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -ms-flex: 0 0 100%;
      flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -ms-flex-order: -1;
      order: -1; }

.order-last {
  -ms-flex-order: 13;
      order: 13; }

.order-0 {
  -ms-flex-order: 0;
      order: 0; }

.order-1 {
  -ms-flex-order: 1;
      order: 1; }

.order-2 {
  -ms-flex-order: 2;
      order: 2; }

.order-3 {
  -ms-flex-order: 3;
      order: 3; }

.order-4 {
  -ms-flex-order: 4;
      order: 4; }

.order-5 {
  -ms-flex-order: 5;
      order: 5; }

.order-6 {
  -ms-flex-order: 6;
      order: 6; }

.order-7 {
  -ms-flex-order: 7;
      order: 7; }

.order-8 {
  -ms-flex-order: 8;
      order: 8; }

.order-9 {
  -ms-flex-order: 9;
      order: 9; }

.order-10 {
  -ms-flex-order: 10;
      order: 10; }

.order-11 {
  -ms-flex-order: 11;
      order: 11; }

.order-12 {
  -ms-flex-order: 12;
      order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -ms-flex-order: -1;
        order: -1; }
  .order-sm-last {
    -ms-flex-order: 13;
        order: 13; }
  .order-sm-0 {
    -ms-flex-order: 0;
        order: 0; }
  .order-sm-1 {
    -ms-flex-order: 1;
        order: 1; }
  .order-sm-2 {
    -ms-flex-order: 2;
        order: 2; }
  .order-sm-3 {
    -ms-flex-order: 3;
        order: 3; }
  .order-sm-4 {
    -ms-flex-order: 4;
        order: 4; }
  .order-sm-5 {
    -ms-flex-order: 5;
        order: 5; }
  .order-sm-6 {
    -ms-flex-order: 6;
        order: 6; }
  .order-sm-7 {
    -ms-flex-order: 7;
        order: 7; }
  .order-sm-8 {
    -ms-flex-order: 8;
        order: 8; }
  .order-sm-9 {
    -ms-flex-order: 9;
        order: 9; }
  .order-sm-10 {
    -ms-flex-order: 10;
        order: 10; }
  .order-sm-11 {
    -ms-flex-order: 11;
        order: 11; }
  .order-sm-12 {
    -ms-flex-order: 12;
        order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -ms-flex-order: -1;
        order: -1; }
  .order-md-last {
    -ms-flex-order: 13;
        order: 13; }
  .order-md-0 {
    -ms-flex-order: 0;
        order: 0; }
  .order-md-1 {
    -ms-flex-order: 1;
        order: 1; }
  .order-md-2 {
    -ms-flex-order: 2;
        order: 2; }
  .order-md-3 {
    -ms-flex-order: 3;
        order: 3; }
  .order-md-4 {
    -ms-flex-order: 4;
        order: 4; }
  .order-md-5 {
    -ms-flex-order: 5;
        order: 5; }
  .order-md-6 {
    -ms-flex-order: 6;
        order: 6; }
  .order-md-7 {
    -ms-flex-order: 7;
        order: 7; }
  .order-md-8 {
    -ms-flex-order: 8;
        order: 8; }
  .order-md-9 {
    -ms-flex-order: 9;
        order: 9; }
  .order-md-10 {
    -ms-flex-order: 10;
        order: 10; }
  .order-md-11 {
    -ms-flex-order: 11;
        order: 11; }
  .order-md-12 {
    -ms-flex-order: 12;
        order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -ms-flex-order: -1;
        order: -1; }
  .order-lg-last {
    -ms-flex-order: 13;
        order: 13; }
  .order-lg-0 {
    -ms-flex-order: 0;
        order: 0; }
  .order-lg-1 {
    -ms-flex-order: 1;
        order: 1; }
  .order-lg-2 {
    -ms-flex-order: 2;
        order: 2; }
  .order-lg-3 {
    -ms-flex-order: 3;
        order: 3; }
  .order-lg-4 {
    -ms-flex-order: 4;
        order: 4; }
  .order-lg-5 {
    -ms-flex-order: 5;
        order: 5; }
  .order-lg-6 {
    -ms-flex-order: 6;
        order: 6; }
  .order-lg-7 {
    -ms-flex-order: 7;
        order: 7; }
  .order-lg-8 {
    -ms-flex-order: 8;
        order: 8; }
  .order-lg-9 {
    -ms-flex-order: 9;
        order: 9; }
  .order-lg-10 {
    -ms-flex-order: 10;
        order: 10; }
  .order-lg-11 {
    -ms-flex-order: 11;
        order: 11; }
  .order-lg-12 {
    -ms-flex-order: 12;
        order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -ms-flex-positive: 1;
        flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
        flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
        flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
        flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -ms-flex-order: -1;
        order: -1; }
  .order-xl-last {
    -ms-flex-order: 13;
        order: 13; }
  .order-xl-0 {
    -ms-flex-order: 0;
        order: 0; }
  .order-xl-1 {
    -ms-flex-order: 1;
        order: 1; }
  .order-xl-2 {
    -ms-flex-order: 2;
        order: 2; }
  .order-xl-3 {
    -ms-flex-order: 3;
        order: 3; }
  .order-xl-4 {
    -ms-flex-order: 4;
        order: 4; }
  .order-xl-5 {
    -ms-flex-order: 5;
        order: 5; }
  .order-xl-6 {
    -ms-flex-order: 6;
        order: 6; }
  .order-xl-7 {
    -ms-flex-order: 7;
        order: 7; }
  .order-xl-8 {
    -ms-flex-order: 8;
        order: 8; }
  .order-xl-9 {
    -ms-flex-order: 9;
        order: 9; }
  .order-xl-10 {
    -ms-flex-order: 10;
        order: 10; }
  .order-xl-11 {
    -ms-flex-order: 11;
        order: 11; }
  .order-xl-12 {
    -ms-flex-order: 12;
        order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1.25rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem 1.25rem;
    vertical-align: top;
    border-top: 1px solid #ddd; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd; }
  .table tbody + tbody {
    border-top: 2px solid #ddd; }
  .table .table {
    background-color: #f5f5f5; }

.table-sm th,
.table-sm td {
  padding: 0.625rem 1.25rem; }

.table-bordered {
  border: 1px solid #ddd; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.03); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c1e2fc; }

.table-hover .table-primary:hover {
  background-color: #a9d7fb; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a9d7fb; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d9d9d9; }

.table-hover .table-secondary:hover {
  background-color: #cccccc; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #cccccc; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cde9ce; }

.table-hover .table-success:hover {
  background-color: #bbe1bd; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #bbe1bd; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8ecf3; }

.table-hover .table-info:hover {
  background-color: #a2e6ef; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a2e6ef; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffd7ca; }

.table-hover .table-warning:hover {
  background-color: #ffc4b1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffc4b1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fccac7; }

.table-hover .table-danger:hover {
  background-color: #fbb3af; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #fbb3af; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fefefe; }

.table-hover .table-light:hover {
  background-color: #f1f1f1; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f1f1f1; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c3c8ce; }

.table-hover .table-dark:hover {
  background-color: #b5bbc3; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b5bbc3; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.015); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.015); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.015); }

.table .thead-dark th {
  color: #fff;
  background-color: #333;
  border-color: rgba(255, 255, 255, 0.35); }

.table .thead-light th {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd; }

.table-dark {
  color: #fff;
  background-color: #333; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: rgba(255, 255, 255, 0.35); }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.4375rem 0.875rem;
  font-size: 0.8125rem;
  line-height: 1.5385;
  color: #333;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ddd;
  border-radius: 0.1875rem;
  box-shadow: 0 0 0 0 transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    outline: 0;
    box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent; }
  .form-control:not(.border-1):not(.border-2):not(.border-3):focus {
    border-color: #ccc; }
  .form-control::-webkit-input-placeholder {
    color: #999;
    opacity: 1; }
  .form-control::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #999;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #999;
    opacity: 1; }
  .form-control::placeholder {
    color: #999;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #fafafa;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: 2.25003rem; }

select.form-control:focus::-ms-value {
  color: #333;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.4375rem + 1px);
  padding-bottom: calc(0.4375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5385; }

.col-form-label-lg {
  padding-top: calc(0.5625rem + 1px);
  padding-bottom: calc(0.5625rem + 1px);
  font-size: 0.875rem;
  line-height: 1.4286; }

.col-form-label-sm {
  padding-top: calc(0.3125rem + 1px);
  padding-bottom: calc(0.3125rem + 1px);
  font-size: 0.75rem;
  line-height: 1.6667; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  margin-bottom: 0;
  line-height: 1.5385;
  color: #333;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.3125rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.6667;
  border-radius: 0.125rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: 2.00002rem; }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5625rem 1rem;
  font-size: 0.875rem;
  line-height: 1.4286;
  border-radius: 0.25rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: 2.50002rem; }

.form-group {
  margin-bottom: 1.25rem; }

.form-text {
  display: block;
  margin-top: 0.5rem; }

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.875rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.875rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #999; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  padding-left: 0;
  margin-right: 1.25rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.625rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 80%;
  color: #4CAF50; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(76, 175, 80, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #4CAF50; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #4CAF50;
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #4CAF50; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #4CAF50; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #a3d7a5; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #6ec071; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #f5f5f5, 0 0 0 0 rgba(76, 175, 80, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #4CAF50; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 80%;
  color: #F44336; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(244, 67, 54, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #F44336; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #F44336;
    box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #F44336; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #F44336; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #fbb4af; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #f77066; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #f5f5f5, 0 0 0 0 rgba(244, 67, 54, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #F44336; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.25); }

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -ms-flex-align: center;
      align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
      -ms-flex-align: center;
          align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: center;
          justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.4375rem 0.875rem;
  font-size: 0.8125rem;
  line-height: 1.5385;
  border-radius: 0.1875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0 transparent; }
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
    box-shadow: none; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none;
    box-shadow: inset 0 0 0 transparent; }
    .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 0 transparent, inset 0 0 0 transparent; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #2196F3; }
  .btn-primary:hover {
    color: #fff;
    background-color: #2196F3; }

.btn-secondary {
  color: #fff;
  background-color: #777; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #777; }

.btn-success {
  color: #fff;
  background-color: #4CAF50; }
  .btn-success:hover {
    color: #fff;
    background-color: #4CAF50; }

.btn-info {
  color: #fff;
  background-color: #00BCD4; }
  .btn-info:hover {
    color: #fff;
    background-color: #00BCD4; }

.btn-warning {
  color: #fff;
  background-color: #FF7043; }
  .btn-warning:hover {
    color: #fff;
    background-color: #FF7043; }

.btn-danger {
  color: #fff;
  background-color: #F44336; }
  .btn-danger:hover {
    color: #fff;
    background-color: #F44336; }

.btn-light {
  color: #333;
  background-color: #fafafa; }
  .btn-light:hover {
    color: #333;
    background-color: #fafafa; }

.btn-dark {
  color: #fff;
  background-color: #293a50; }
  .btn-dark:hover {
    color: #fff;
    background-color: #293a50; }

.btn-outline-primary {
  color: #2196F3;
  background-color: transparent;
  background-image: none;
  border-color: #2196F3; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #2196F3;
    border-color: #2196F3; }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #2196F3;
    background-color: transparent; }
  .btn-outline-primary:not([disabled]):not(.disabled):active, .btn-outline-primary:not([disabled]):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #2196F3;
    border-color: #2196F3; }

.btn-outline-secondary {
  color: #777;
  background-color: transparent;
  background-image: none;
  border-color: #777; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #777;
    border-color: #777; }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #777;
    background-color: transparent; }
  .btn-outline-secondary:not([disabled]):not(.disabled):active, .btn-outline-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #777;
    border-color: #777; }

.btn-outline-success {
  color: #4CAF50;
  background-color: transparent;
  background-image: none;
  border-color: #4CAF50; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #4CAF50;
    border-color: #4CAF50; }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #4CAF50;
    background-color: transparent; }
  .btn-outline-success:not([disabled]):not(.disabled):active, .btn-outline-success:not([disabled]):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4CAF50;
    border-color: #4CAF50; }

.btn-outline-info {
  color: #00BCD4;
  background-color: transparent;
  background-image: none;
  border-color: #00BCD4; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #00BCD4;
    border-color: #00BCD4; }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #00BCD4;
    background-color: transparent; }
  .btn-outline-info:not([disabled]):not(.disabled):active, .btn-outline-info:not([disabled]):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00BCD4;
    border-color: #00BCD4; }

.btn-outline-warning {
  color: #FF7043;
  background-color: transparent;
  background-image: none;
  border-color: #FF7043; }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #FF7043;
    border-color: #FF7043; }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #FF7043;
    background-color: transparent; }
  .btn-outline-warning:not([disabled]):not(.disabled):active, .btn-outline-warning:not([disabled]):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #FF7043;
    border-color: #FF7043; }

.btn-outline-danger {
  color: #F44336;
  background-color: transparent;
  background-image: none;
  border-color: #F44336; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #F44336;
    border-color: #F44336; }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #F44336;
    background-color: transparent; }
  .btn-outline-danger:not([disabled]):not(.disabled):active, .btn-outline-danger:not([disabled]):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #F44336;
    border-color: #F44336; }

.btn-outline-light {
  color: #fafafa;
  background-color: transparent;
  background-image: none;
  border-color: #fafafa; }
  .btn-outline-light:hover {
    color: #fff;
    background-color: #fafafa;
    border-color: #fafafa; }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #fafafa;
    background-color: transparent; }
  .btn-outline-light:not([disabled]):not(.disabled):active, .btn-outline-light:not([disabled]):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #fff;
    background-color: #fafafa;
    border-color: #fafafa; }

.btn-outline-dark {
  color: #293a50;
  background-color: transparent;
  background-image: none;
  border-color: #293a50; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #293a50;
    border-color: #293a50; }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #293a50;
    background-color: transparent; }
  .btn-outline-dark:not([disabled]):not(.disabled):active, .btn-outline-dark:not([disabled]):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #293a50;
    border-color: #293a50; }

.btn-link {
  font-weight: 400;
  color: #2196F3;
  background-color: transparent; }
  .btn-link:hover {
    color: #0a6ebd;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #999;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5625rem 1rem;
  font-size: 0.875rem;
  line-height: 1.4286;
  border-radius: 0.25rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.3125rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.6667;
  border-radius: 0.125rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.15s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  font-family: "icomoon";
  display: inline-block;
  border: 0;
  vertical-align: middle;
  font-size: 0.6875rem;
  margin-left: 0.46875rem;
  line-height: 1;
  position: relative;
  content: '\e9c9'; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  /*rtl:ignore*/
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 11.25rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.8125rem;
  color: #333;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  font-family: "icomoon";
  display: inline-block;
  border: 0;
  vertical-align: middle;
  font-size: 0.6875rem;
  margin-left: 0.46875rem;
  line-height: 1;
  position: relative;
  content: '\e9ca'; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  /*rtl:begin:ignore*/
  right: auto;
  left: 100%;
  /*rtl:end:ignore*/
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  font-family: "icomoon";
  display: inline-block;
  border: 0;
  vertical-align: middle;
  font-size: 0.6875rem;
  margin-left: 0.46875rem;
  line-height: 1;
  position: relative;
  content: '\e9cb'; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  font-family: "icomoon";
  display: inline-block;
  border: 0;
  vertical-align: middle;
  font-size: 0.6875rem;
  margin-left: 0.46875rem;
  line-height: 1;
  position: relative; }

.dropleft .dropdown-toggle::after {
  content: none; }

.dropleft .dropdown-toggle::before {
  font-family: "icomoon";
  display: inline-block;
  border: 0;
  font-size: 0.6875rem;
  margin-right: 0.46875rem;
  line-height: 1;
  position: relative;
  content: '\e9c8'; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.625rem 0;
  overflow: hidden;
  border-top: 1px solid #ddd; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  color: #333;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #333;
    text-decoration: none;
    background-color: #f5f5f5; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #2196F3; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #999;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  color: #999;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.5rem 1rem;
  color: #333; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    -ms-flex: 0 1 auto;
        flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: start;
      justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.65625rem;
  padding-left: 0.65625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 0 0 transparent; }
  .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn-group-vertical {
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: center;
      justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: stretch;
      align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: -ms-flexbox;
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.4375rem 0.875rem;
  margin-bottom: 0;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5385;
  color: #333;
  text-align: center;
  white-space: nowrap;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 0.1875rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5385rem;
  padding-left: 1.875rem; }

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #455A64;
    box-shadow: none; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none; }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #455A64;
    background-color: #9fb3bd;
    box-shadow: none; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #999; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #f5f5f5; }

.custom-control-label {
  position: relative;
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.14425rem;
    left: -1.875rem;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: #eee;
    box-shadow: 0 0 0 0 transparent; }
  .custom-control-label::after {
    position: absolute;
    top: 0.14425rem;
    left: -1.875rem;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.125rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #455A64; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #455A64;
  box-shadow: none; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(69, 90, 100, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(69, 90, 100, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #455A64; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(69, 90, 100, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: 2.25003rem;
  padding: 0.4375rem 1.3125rem 0.4375rem 0.875rem;
  line-height: 1.5385;
  color: #333;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23555' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.875rem center;
  background-size: 8px 10px;
  border: 1px solid #ddd;
  border-radius: 0.1875rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #ccc;
    outline: 0;
    box-shadow: none; }
    .custom-select:focus::-ms-value {
      color: #333;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.875rem;
    background-image: none; }
  .custom-select:disabled {
    color: #999;
    background-color: #f5f5f5; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: 2.00002rem;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  font-size: 0.75rem; }

.custom-select-lg {
  height: 2.50002rem;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  font-size: 0.875rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 2.25003rem;
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 2.25003rem;
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #ccc;
    box-shadow: 0 0 0 0 transparent; }
    .custom-file-input:focus ~ .custom-file-label::after {
      border-color: #ccc; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 2.25003rem;
  padding: 0.4375rem 0.875rem;
  line-height: 1.5385;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.1875rem;
  box-shadow: 0 0 0 0 transparent; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.12503rem;
    padding: 0.4375rem 0.875rem;
    line-height: 1.5385;
    color: #333;
    content: "Browse";
    background-color: #fafafa;
    border-left: 1px solid #ddd;
    border-radius: 0 0.1875rem 0.1875rem 0; }

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #2196F3;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    -webkit-appearance: none;
            appearance: none; }
    .custom-range::-webkit-slider-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #f5f5f5, 0 0 0 0 transparent; }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #cae6fc; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #eee;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #2196F3;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    -moz-appearance: none;
         appearance: none; }
    .custom-range::-moz-range-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #f5f5f5, 0 0 0 0 transparent; }
    .custom-range::-moz-range-thumb:active {
      background-color: #cae6fc; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #eee;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #2196F3;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
    appearance: none; }
    .custom-range::-ms-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #f5f5f5, 0 0 0 0 transparent; }
    .custom-range::-ms-thumb:active {
      background-color: #cae6fc; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .custom-range::-ms-fill-lower {
    background-color: #eee;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #eee;
    border-radius: 1rem; }

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.625rem 1.25rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #999; }

.nav-tabs {
  border-bottom: 1px solid #ddd; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.1875rem;
    border-top-right-radius: 0.1875rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: transparent; }
    .nav-tabs .nav-link.disabled {
      color: #999;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #333;
    background-color: #fff;
    border-color: #ddd #ddd #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.1875rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2196F3; }

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -ms-flex-positive: 1;
      flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 0 1.25rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 1.00002rem;
  padding-bottom: 1.00002rem;
  margin-right: 1.25rem;
  font-size: 0;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-align: center;
      align-items: center; }

.navbar-toggler {
  padding: 0.875rem 0;
  font-size: 0.8125rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -ms-flex-direction: row;
          flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 1.25rem;
        padding-left: 1.25rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -ms-flex-direction: row;
          flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1.25rem;
        padding-left: 1.25rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -ms-flex-direction: row;
          flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.25rem;
        padding-left: 1.25rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
    -ms-flex-pack: start;
        justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -ms-flex-direction: row;
          flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 1.25rem;
        padding-left: 1.25rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
  -ms-flex-pack: start;
      justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -ms-flex-direction: row;
        flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 1.25rem;
      padding-left: 1.25rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: #333; }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: #333; }

.navbar-light .navbar-nav .nav-link {
  color: rgba(51, 51, 51, 0.85); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #333; }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.5); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #333; }

.navbar-light .navbar-toggler {
  color: rgba(51, 51, 51, 0.85);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(51, 51, 51, 0.85)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(51, 51, 51, 0.85); }
  .navbar-light .navbar-text a {
    color: #333; }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: #333; }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.9); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: #fff; }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.5); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.9);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.9); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.1875rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.1875rem;
    border-top-right-radius: 0.1875rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.1875rem;
    border-bottom-left-radius: 0.1875rem; }

.card-body {
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.9375rem; }

.card-subtitle {
  margin-top: -0.46875rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.9375rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.02);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: 0.125rem 0.125rem 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.9375rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.02);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 0.125rem 0.125rem; }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.9375rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: 0.125rem; }

.card-img-top {
  width: 100%;
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem; }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem; }

.card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  .card-deck .card {
    margin-bottom: 1.25rem; }
  @media (min-width: 576px) {
    .card-deck {
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
      margin-right: -1.25rem;
      margin-left: -1.25rem; }
      .card-deck .card {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
        -ms-flex-direction: column;
            flex-direction: column;
        margin-right: 1.25rem;
        margin-bottom: 0;
        margin-left: 1.25rem; } }

.card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  .card-group > .card {
    margin-bottom: 1.25rem; }
  @media (min-width: 576px) {
    .card-group {
      -ms-flex-flow: row wrap;
          flex-flow: row wrap; }
      .card-group > .card {
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.1875rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.1875rem;
            border-top-right-radius: 0.1875rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.1875rem;
            border-bottom-left-radius: 0.1875rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 1.25rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0.625rem 0;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.625rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.625rem;
    color: inherit;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #999; }

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.1875rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.4375rem 0.875rem;
  margin-left: -1px;
  line-height: 1.5385;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd; }
  .page-link:hover {
    z-index: 2;
    color: #333;
    text-decoration: none;
    background-color: #f5f5f5;
    border-color: #ddd; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0 transparent; }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #2196F3;
  border-color: #2196F3; }

.page-item.disabled .page-link {
  color: #999;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #eee; }

.pagination-lg .page-link {
  padding: 0.5625rem 1rem;
  font-size: 0.875rem;
  line-height: 1.4286; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.pagination-sm .page-link {
  padding: 0.3125rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.6667; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem; }

.badge {
  display: inline-block;
  padding: 0.3125rem 0.375rem;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.125rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.4375rem;
  padding-left: 0.4375rem;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #2196F3; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none; }
    .badge-primary[href]:hover:not(.badge-light), .badge-primary[href]:focus:not(.badge-light) {
      box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.075) inset; }

.badge-secondary {
  color: #fff;
  background-color: #777; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none; }
    .badge-secondary[href]:hover:not(.badge-light), .badge-secondary[href]:focus:not(.badge-light) {
      box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.075) inset; }

.badge-success {
  color: #fff;
  background-color: #4CAF50; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none; }
    .badge-success[href]:hover:not(.badge-light), .badge-success[href]:focus:not(.badge-light) {
      box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.075) inset; }

.badge-info {
  color: #fff;
  background-color: #00BCD4; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none; }
    .badge-info[href]:hover:not(.badge-light), .badge-info[href]:focus:not(.badge-light) {
      box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.075) inset; }

.badge-warning {
  color: #fff;
  background-color: #FF7043; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #fff;
    text-decoration: none; }
    .badge-warning[href]:hover:not(.badge-light), .badge-warning[href]:focus:not(.badge-light) {
      box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.075) inset; }

.badge-danger {
  color: #fff;
  background-color: #F44336; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none; }
    .badge-danger[href]:hover:not(.badge-light), .badge-danger[href]:focus:not(.badge-light) {
      box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.075) inset; }

.badge-light {
  color: #333;
  background-color: #fafafa; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #333;
    text-decoration: none; }
    .badge-light[href]:hover:not(.badge-light), .badge-light[href]:focus:not(.badge-light) {
      box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.075) inset; }

.badge-dark {
  color: #fff;
  background-color: #293a50; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none; }
    .badge-dark[href]:hover:not(.badge-light), .badge-dark[href]:focus:not(.badge-light) {
      box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.075) inset; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #f5f5f5;
  border-radius: 0.25rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.9375rem 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.1875rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 500; }

.alert-dismissible {
  padding-right: 3.75003rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.9375rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #114e7e;
  background-color: #dbeefd;
  border-color: #339ef4; }
  .alert-primary hr {
    border-top-color: #1b92f3; }
  .alert-primary .alert-link {
    color: #0b3251; }

.alert-secondary {
  color: #3e3e3e;
  background-color: #e9e9e9;
  border-color: #828282; }
  .alert-secondary hr {
    border-top-color: #757575; }
  .alert-secondary .alert-link {
    color: #252525; }

.alert-success {
  color: #285b2a;
  background-color: #e2f2e3;
  border-color: #5ab55e; }
  .alert-success hr {
    border-top-color: #4ca950; }
  .alert-success .alert-link {
    color: #18381a; }

.alert-info {
  color: #00626e;
  background-color: #d6f4f8;
  border-color: #14c1d7; }
  .alert-info hr {
    border-top-color: #12acc0; }
  .alert-info .alert-link {
    color: #00353b; }

.alert-warning {
  color: #853a23;
  background-color: #ffe8e1;
  border-color: #ff7b52; }
  .alert-warning hr {
    border-top-color: #ff6839; }
  .alert-warning .alert-link {
    color: #5d2818; }

.alert-danger {
  color: #7f231c;
  background-color: #fde1df;
  border-color: #f55246; }
  .alert-danger hr {
    border-top-color: #f43b2e; }
  .alert-danger .alert-link {
    color: #551713; }

.alert-light {
  color: #828282;
  background-color: #fefefe;
  border-color: #fafafa; }
  .alert-light hr {
    border-top-color: #ededed; }
  .alert-light .alert-link {
    color: dimgray; }

.alert-dark {
  color: #151e2a;
  background-color: #dddfe3;
  border-color: #3a4a5e; }
  .alert-dark hr {
    border-top-color: #303e4e; }
  .alert-dark .alert-link {
    color: #040608; }

@-webkit-keyframes progress-bar-stripes {
  /*rtl:begin:ignore*/
  from {
    background-position: 1.125rem 0; }
  to {
    background-position: 0 0; }
  /*rtl:end:ignore*/ }

@keyframes progress-bar-stripes {
  /*rtl:begin:ignore*/
  from {
    background-position: 1.125rem 0; }
  to {
    background-position: 0 0; }
  /*rtl:end:ignore*/ }

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1.125rem;
  overflow: hidden;
  font-size: 0.60938rem;
  background-color: #eee;
  border-radius: 0.1875rem;
  box-shadow: inset 0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.1); }

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #2196F3;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1.125rem 1.125rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start; }

.media-body {
  -ms-flex: 1;
      flex: 1; }

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: rgba(51, 51, 51, 0.85);
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #333;
    text-decoration: none;
    background-color: #f5f5f5; }
  .list-group-item-action:active {
    color: #333;
    background-color: #f5f5f5; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.1875rem;
    border-top-right-radius: 0.1875rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.1875rem;
    border-bottom-left-radius: 0.1875rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #999;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #2196F3;
    border-color: #2196F3; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #114e7e;
  background-color: #c1e2fc; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #114e7e;
    background-color: #a9d7fb; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #114e7e;
    border-color: #114e7e; }

.list-group-item-secondary {
  color: #3e3e3e;
  background-color: #d9d9d9; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #3e3e3e;
    background-color: #cccccc; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #3e3e3e;
    border-color: #3e3e3e; }

.list-group-item-success {
  color: #285b2a;
  background-color: #cde9ce; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #285b2a;
    background-color: #bbe1bd; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #285b2a;
    border-color: #285b2a; }

.list-group-item-info {
  color: #00626e;
  background-color: #b8ecf3; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #00626e;
    background-color: #a2e6ef; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #00626e;
    border-color: #00626e; }

.list-group-item-warning {
  color: #853a23;
  background-color: #ffd7ca; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #853a23;
    background-color: #ffc4b1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #853a23;
    border-color: #853a23; }

.list-group-item-danger {
  color: #7f231c;
  background-color: #fccac7; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7f231c;
    background-color: #fbb3af; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7f231c;
    border-color: #7f231c; }

.list-group-item-light {
  color: #828282;
  background-color: #fefefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #828282;
    background-color: #f1f1f1; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #828282;
    border-color: #828282; }

.list-group-item-dark {
  color: #151e2a;
  background-color: #c3c8ce; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #151e2a;
    background-color: #b5bbc3; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #151e2a;
    border-color: #151e2a; }

.close {
  float: right;
  font-size: 1.25003rem;
  font-weight: 400;
  line-height: 1;
  color: #333;
  text-shadow: none;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #333;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
            transform: translate(0, -25%); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }
  .modal-header .close {
    padding: 1.25rem;
    margin: -1.25rem -1.25rem -1.25rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5385; }

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  padding: 1.25rem; }

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding: 1.25rem;
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-content {
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); }
  .modal-sm {
    max-width: 400px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 900px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0.3125rem;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5385;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.5rem;
    height: 0.25rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.25rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.25rem 0.25rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.25rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    /*rtl:ignore*/
    left: 0;
    width: 0.25rem;
    height: 0.5rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      /*rtl:begin:ignore*/
      right: 0;
      border-width: 0.25rem 0.25rem 0.25rem 0;
      border-right-color: #000;
      /*rtl:end:ignore*/ }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.25rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.25rem 0.25rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.25rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    /*rtl:ignore*/
    right: 0;
    width: 0.25rem;
    height: 0.5rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      /*rtl:begin:ignore*/
      left: 0;
      border-width: 0.25rem 0 0.25rem 0.25rem;
      border-left-color: #000;
      /*rtl:end:ignore*/ }

.tooltip-inner {
  max-width: 200px;
  padding: 0.5rem 0.75rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.1875rem; }

.popover {
  position: absolute;
  top: 0;
  /*rtl:ignore*/
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5385;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 0.75rem;
    height: 0.375rem;
    margin: 0 0.25rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.375rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.375rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.375rem 0.375rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.2); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  /*rtl:ignore*/
  margin-left: 0.375rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    /*rtl:ignore*/
    left: calc((0.375rem + 1px) * -1);
    width: 0.375rem;
    height: 0.75rem;
    margin: 0.25rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    /*rtl:ignore*/
    border-width: 0.375rem 0.375rem 0.375rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    /*rtl:begin:ignore*/
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.2);
    /*rtl:end:ignore*/ }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    /*rtl:begin:ignore*/
    left: 1px;
    border-right-color: #fff;
    /*rtl:end:ignore*/ }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.375rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.375rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.375rem 0.375rem 0.375rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.2); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 0.75rem;
    margin-left: -0.375rem;
    content: "";
    border-bottom: 1px solid transparent; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  /*rtl:ignore*/
  margin-right: 0.375rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    /*rtl:ignore*/
    right: calc((0.375rem + 1px) * -1);
    width: 0.375rem;
    height: 0.75rem;
    margin: 0.25rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    /*rtl:ignore*/
    border-width: 0.375rem 0 0.375rem 0.375rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    /*rtl:begin:ignore*/
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.2);
    /*rtl:end:ignore*/ }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    /*rtl:begin:ignore*/
    right: 1px;
    border-left-color: #fff;
    /*rtl:end:ignore*/ }

.popover-header {
  padding: 0.9375rem 0.9375rem;
  margin-bottom: 0;
  font-size: 0.8125rem;
  color: inherit;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.9375rem 0.9375rem;
  color: #333; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  -ms-flex-align: center;
      align-items: center;
  width: 100%;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
          transform: translateX(0); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next,
    .active.carousel-item-right {
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-prev,
    .active.carousel-item-left {
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0); } }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  -webkit-transform: translateX(0);
          transform: translateX(0); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    -ms-flex: 0 1 auto;
        flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #2196F3 !important; }

.bg-secondary {
  background-color: #777 !important; }

.bg-success {
  background-color: #4CAF50 !important; }

.bg-info {
  background-color: #00BCD4 !important; }

.bg-warning {
  background-color: #FF7043 !important; }

.bg-danger {
  background-color: #F44336 !important; }

.bg-light {
  background-color: #fafafa !important; }

.bg-dark {
  background-color: #293a50 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #ddd !important; }

.border-top {
  border-top: 1px solid #ddd !important; }

.border-right {
  border-right: 1px solid #ddd !important; }

.border-bottom {
  border-bottom: 1px solid #ddd !important; }

.border-left {
  border-left: 1px solid #ddd !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #2196F3 !important; }

.border-secondary {
  border-color: #777 !important; }

.border-success {
  border-color: #4CAF50 !important; }

.border-info {
  border-color: #00BCD4 !important; }

.border-warning {
  border-color: #FF7043 !important; }

.border-danger {
  border-color: #F44336 !important; }

.border-light {
  border-color: #fafafa !important; }

.border-dark {
  border-color: #293a50 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.1875rem !important; }

.rounded-top {
  border-top-left-radius: 0.1875rem !important;
  border-top-right-radius: 0.1875rem !important; }

.rounded-right {
  border-top-right-radius: 0.1875rem !important;
  border-bottom-right-radius: 0.1875rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.1875rem !important;
  border-bottom-left-radius: 0.1875rem !important; }

.rounded-left {
  border-top-left-radius: 0.1875rem !important;
  border-bottom-left-radius: 0.1875rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -ms-flex-direction: row !important;
      flex-direction: row !important; }

.flex-column {
  -ms-flex-direction: column !important;
      flex-direction: column !important; }

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important; }

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }

.flex-fill {
  -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important; }

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
      flex-grow: 0 !important; }

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
      flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important; }

.justify-content-start {
  -ms-flex-pack: start !important;
      justify-content: flex-start !important; }

.justify-content-end {
  -ms-flex-pack: end !important;
      justify-content: flex-end !important; }

.justify-content-center {
  -ms-flex-pack: center !important;
      justify-content: center !important; }

.justify-content-between {
  -ms-flex-pack: justify !important;
      justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }

.align-items-start {
  -ms-flex-align: start !important;
      align-items: flex-start !important; }

.align-items-end {
  -ms-flex-align: end !important;
      align-items: flex-end !important; }

.align-items-center {
  -ms-flex-align: center !important;
      align-items: center !important; }

.align-items-baseline {
  -ms-flex-align: baseline !important;
      align-items: baseline !important; }

.align-items-stretch {
  -ms-flex-align: stretch !important;
      align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
      align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
      align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-sm-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-sm-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-sm-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-sm-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-md-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-md-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-md-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-md-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-lg-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-lg-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-lg-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-lg-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
        flex-direction: row !important; }
  .flex-xl-column {
    -ms-flex-direction: column !important;
        flex-direction: column !important; }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
        flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
        flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
        justify-content: flex-start !important; }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
        justify-content: flex-end !important; }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
        justify-content: center !important; }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
        justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-xl-start {
    -ms-flex-align: start !important;
        align-items: flex-start !important; }
  .align-items-xl-end {
    -ms-flex-align: end !important;
        align-items: flex-end !important; }
  .align-items-xl-center {
    -ms-flex-align: center !important;
        align-items: center !important; }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
        align-items: baseline !important; }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
        align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.3125rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.3125rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.3125rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.3125rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.3125rem !important; }

.m-2 {
  margin: 0.625rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.625rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.625rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.625rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.625rem !important; }

.m-3 {
  margin: 1.25rem !important; }

.mt-3,
.my-3 {
  margin-top: 1.25rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1.25rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1.25rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1.25rem !important; }

.m-4 {
  margin: 1.875rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.875rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.875rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.875rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.875rem !important; }

.m-5 {
  margin: 3.75rem !important; }

.mt-5,
.my-5 {
  margin-top: 3.75rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3.75rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3.75rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3.75rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.3125rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.3125rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.3125rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.3125rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.3125rem !important; }

.p-2 {
  padding: 0.625rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.625rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.625rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.625rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.625rem !important; }

.p-3 {
  padding: 1.25rem !important; }

.pt-3,
.py-3 {
  padding-top: 1.25rem !important; }

.pr-3,
.px-3 {
  padding-right: 1.25rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1.25rem !important; }

.pl-3,
.px-3 {
  padding-left: 1.25rem !important; }

.p-4 {
  padding: 1.875rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.875rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.875rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.875rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.875rem !important; }

.p-5 {
  padding: 3.75rem !important; }

.pt-5,
.py-5 {
  padding-top: 3.75rem !important; }

.pr-5,
.px-5 {
  padding-right: 3.75rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3.75rem !important; }

.pl-5,
.px-5 {
  padding-left: 3.75rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.3125rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.3125rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.3125rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.3125rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.3125rem !important; }
  .m-sm-2 {
    margin: 0.625rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.625rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.625rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.625rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.625rem !important; }
  .m-sm-3 {
    margin: 1.25rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.25rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.25rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.25rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.25rem !important; }
  .m-sm-4 {
    margin: 1.875rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.875rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.875rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.875rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.875rem !important; }
  .m-sm-5 {
    margin: 3.75rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3.75rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3.75rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3.75rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3.75rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.3125rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.3125rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.3125rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.3125rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.3125rem !important; }
  .p-sm-2 {
    padding: 0.625rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.625rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.625rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.625rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.625rem !important; }
  .p-sm-3 {
    padding: 1.25rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.25rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.25rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.25rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.25rem !important; }
  .p-sm-4 {
    padding: 1.875rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.875rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.875rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.875rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.875rem !important; }
  .p-sm-5 {
    padding: 3.75rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3.75rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3.75rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3.75rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3.75rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.3125rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.3125rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.3125rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.3125rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.3125rem !important; }
  .m-md-2 {
    margin: 0.625rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.625rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.625rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.625rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.625rem !important; }
  .m-md-3 {
    margin: 1.25rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.25rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.25rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.25rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.25rem !important; }
  .m-md-4 {
    margin: 1.875rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.875rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.875rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.875rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.875rem !important; }
  .m-md-5 {
    margin: 3.75rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3.75rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3.75rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3.75rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3.75rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.3125rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.3125rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.3125rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.3125rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.3125rem !important; }
  .p-md-2 {
    padding: 0.625rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.625rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.625rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.625rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.625rem !important; }
  .p-md-3 {
    padding: 1.25rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.25rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.25rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.25rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.25rem !important; }
  .p-md-4 {
    padding: 1.875rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.875rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.875rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.875rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.875rem !important; }
  .p-md-5 {
    padding: 3.75rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3.75rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3.75rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3.75rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3.75rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.3125rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.3125rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.3125rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.3125rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.3125rem !important; }
  .m-lg-2 {
    margin: 0.625rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.625rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.625rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.625rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.625rem !important; }
  .m-lg-3 {
    margin: 1.25rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.25rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.25rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.25rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.25rem !important; }
  .m-lg-4 {
    margin: 1.875rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.875rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.875rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.875rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.875rem !important; }
  .m-lg-5 {
    margin: 3.75rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3.75rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3.75rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3.75rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3.75rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.3125rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.3125rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.3125rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.3125rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.3125rem !important; }
  .p-lg-2 {
    padding: 0.625rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.625rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.625rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.625rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.625rem !important; }
  .p-lg-3 {
    padding: 1.25rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.25rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.25rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.25rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.25rem !important; }
  .p-lg-4 {
    padding: 1.875rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.875rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.875rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.875rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.875rem !important; }
  .p-lg-5 {
    padding: 3.75rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3.75rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3.75rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3.75rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3.75rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.3125rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.3125rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.3125rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.3125rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.3125rem !important; }
  .m-xl-2 {
    margin: 0.625rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.625rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.625rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.625rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.625rem !important; }
  .m-xl-3 {
    margin: 1.25rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.25rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.25rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.25rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.25rem !important; }
  .m-xl-4 {
    margin: 1.875rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.875rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.875rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.875rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.875rem !important; }
  .m-xl-5 {
    margin: 3.75rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3.75rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3.75rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3.75rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3.75rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.3125rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.3125rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.3125rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.3125rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.3125rem !important; }
  .p-xl-2 {
    padding: 0.625rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.625rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.625rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.625rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.625rem !important; }
  .p-xl-3 {
    padding: 1.25rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.25rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.25rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.25rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.25rem !important; }
  .p-xl-4 {
    padding: 1.875rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.875rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.875rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.875rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.875rem !important; }
  .p-xl-5 {
    padding: 3.75rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3.75rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3.75rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3.75rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3.75rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #2196F3 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0c7cd5 !important; }

.text-secondary {
  color: #777 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #5e5e5e !important; }

.text-success {
  color: #4CAF50 !important; }

a.text-success:hover, a.text-success:focus {
  color: #3d8b40 !important; }

.text-info {
  color: #00BCD4 !important; }

a.text-info:hover, a.text-info:focus {
  color: #008fa1 !important; }

.text-warning {
  color: #FF7043 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ff4910 !important; }

.text-danger {
  color: #F44336 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #ea1c0d !important; }

.text-light {
  color: #fafafa !important; }

a.text-light:hover, a.text-light:focus {
  color: #e1e1e1 !important; }

.text-dark {
  color: #293a50 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #18222e !important; }

.text-body {
  color: #333 !important; }

.text-muted {
  color: #999 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/* ------------------------------------------------------------------------------
 *
 *  # Bootstrap v4.1.1 (https://getbootstrap.com)
 *
 *  Copyright 2011-2018 The Bootstrap Authors
 *  Copyright 2011-2018 Twitter, Inc.
 *  Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Global configuration
 *
 *  Here you can change main theme, enable or disable certain components and
 *  optional styles. This allows you to include only components that you need.
 *
 *  'true'  - enables component and includes it to main CSS file.
 *  'false' - disables component and excludes it from main CSS file.
 *
 *  Layout helper: @if $layout == 'base' {...}
 *  Theme helper: @if $theme == 'material' {...}
 *  Component helper: @if $enable-* {...}
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Custom Limitless functions
 *
 *  Utility mixins and functions for evalutating source code across our variables, maps, and mixins.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Custom template mixins
 *
 *  All custom mixins are prefixed with "ll-" to avoid conflicts
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Main colors
 *
 *  List of the template main color palettes
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Default Bootstrap variable overrides
 *
 *  Variables should follow the `$component-state-property-size` formula for
 *  consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
 *  Also includes custom variables, all marked with "!default" flag.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Additional variables
 *
 *  Mainly 3rd party libraries and additional variables for default
 *  Bootstrap components.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Mixins
 *
 *  Import Bootstrap mixins with overrides
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Badge mixin
 *
 *  Override and extend default badge mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Buttons mixin
 *
 *  Override and extend default buttons mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Caret mixin
 *
 *  Override and extend default cared mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Forms mixin
 *
 *  Override and extend default forms mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Background mixin
 *
 *  Override and extend default background mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Reboot
 *
 *  Normalization of HTML elements - overrides of default Bootstrap styles
 *
 * ---------------------------------------------------------------------------- */
body {
  position: relative; }

dt {
  margin-bottom: 0.3125rem; }

dd {
  margin-bottom: 1.25rem; }
  dd:last-child {
    margin-bottom: 0; }

dl:last-child {
  margin-bottom: 0; }

pre {
  -moz-tab-size: 4;
    -o-tab-size: 4;
       tab-size: 4;
  border: 1px solid #ddd;
  padding: 1.25rem 1.25rem;
  margin-bottom: 0;
  -webkit-hyphens: none;
     -moz-hyphens: none;
      -ms-hyphens: none;
          hyphens: none; }

svg {
  display: block; }
  .svg-inline svg {
    display: inline-block; }
  .svg-center svg {
    margin: auto; }

input[type="file"] {
  display: block; }

/* ------------------------------------------------------------------------------
 *
 *  # Text styling
 *
 *  Override and extend default Bootstrap styles.
 *
 * ---------------------------------------------------------------------------- */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  letter-spacing: -0.015em; }

.list > li,
.list > li .list > li {
  margin-top: 0.3125rem; }

.list > li:first-child {
  margin-top: 0; }

.list-extended > li,
.list-extended > li .list > li {
  margin-top: 0.625rem; }

ul.list-square {
  list-style-type: square; }

ul.list-circle {
  list-style-type: circle; }

.list-inline-item {
  position: relative; }

.list-inline-condensed .list-inline-item:not(:last-child) {
  margin-right: 0.625rem; }

.list-inline-dotted .list-inline-item:not(:last-child) {
  margin-right: 0.625rem; }
  .list-inline-dotted .list-inline-item:not(:last-child):after {
    content: '\f052';
    font-family: "icomoon";
    font-size: 0.5rem;
    width: 0.4rem;
    text-align: center;
    display: inline-block;
    margin-left: 0.825rem;
    opacity: 0.25; }

.list-inline-dotted.list-inline-condensed .list-inline-item:not(:last-child) {
  margin-right: 0.41667rem; }
  .list-inline-dotted.list-inline-condensed .list-inline-item:not(:last-child):after {
    margin-left: 0.54167rem; }

.list-icons {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center; }
  .list-icons > *:not(:last-child) {
    margin-right: 0.375rem; }
  .list-icons-extended > *:not(:last-child) {
    margin-right: 0.75rem; }

.list-icons-item {
  display: inline-block;
  line-height: 1; }

a.list-icons-item {
  transition: all ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    a.list-icons-item {
      transition: none; } }
  a.list-icons-item:not([class*=text-]) {
    color: inherit; }
  a.list-icons-item:hover, a.list-icons-item:focus,
  .show > a.list-icons-item {
    outline: 0;
    opacity: 0.75; }

.font-weight-black {
  font-weight: 900; }

.font-weight-semibold {
  font-weight: 500; }

.font-weight-thin {
  font-weight: 100; }

.text-white {
  color: #fff; }

a.text-white:not(.btn) {
  transition: color ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    a.text-white:not(.btn) {
      transition: none; } }

a.text-white:hover, a.text-white:focus {
  color: rgba(255, 255, 255, 0.75); }

.text-default {
  color: #333; }

a.text-default:not(.btn) {
  transition: color ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    a.text-default:not(.btn) {
      transition: none; } }

a.text-default:hover, a.text-default:focus {
  color: rgba(51, 51, 51, 0.75); }

.blockquote-bordered {
  border-left: 5px solid #eee; }
  .blockquote-bordered-reverse {
    border-left: 0;
    border-right: 5px solid #eee; }

.blockquote-footer {
  font-size: 0.8125rem; }
  .blockquote-footer::before {
    content: "\2014 \00A0 \00A0 \00A0"; }

.list-feed-item {
  position: relative;
  padding-bottom: 1.25rem;
  padding-left: 1.75rem; }
  .list-feed-item:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.31252rem;
    background-color: #fff;
    display: inline-block;
    border: 2px solid #607D8B;
    z-index: 3;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%; }
  .list-feed-item:after {
    content: '';
    position: absolute;
    top: 0.31252rem;
    left: 0.1875rem;
    bottom: -0.43752rem;
    width: 0;
    border-left: 1px solid #607D8B;
    border-right: 1px solid #607D8B;
    z-index: 2; }
  .list-feed-item:first-child:after {
    top: 0.5rem; }
  .list-feed-item:last-child {
    padding-bottom: 0; }
    .list-feed-item:last-child:after {
      content: none; }
  .list-feed-item[class*=border-]:before, .list-feed-item[class*=border-]:after {
    border-color: inherit; }

.list-feed-solid .list-feed-item:before {
  content: '';
  border-width: 0.25rem;
  width: 0;
  height: 0; }

.list-feed-square .list-feed-item:before {
  content: '';
  border-radius: 0; }

.list-feed-rhombus .list-feed-item:before {
  content: '';
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-radius: 0; }

.list-feed-time .list-feed-item {
  margin-left: 3.25rem; }
  .list-feed-time .list-feed-item .feed-time {
    position: absolute;
    left: -3.25rem;
    top: 0; }

.content-divider {
  text-align: center;
  position: relative;
  z-index: 1; }
  .content-divider > span {
    background-color: #f5f5f5;
    display: inline-block; }
    .card .content-divider > span,
    .tab-content-bordered .content-divider > span {
      background-color: #fff; }
    .modal .content-divider > span {
      background-color: #fff; }
    .content-divider > span:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      height: 1px;
      background-color: #ddd;
      width: 100%;
      z-index: -1; }

.letter-icon {
  width: 1rem;
  height: 1.25003rem;
  display: block; }
  .btn-lg .letter-icon, .btn-group-lg > .btn .letter-icon {
    height: 1.25003rem; }
  .btn-sm .letter-icon, .btn-group-sm > .btn .letter-icon {
    height: 1.25002rem; }

/* ------------------------------------------------------------------------------
 *
 *  # Code blocks
 *
 *  Overrides of default code block styles
 *
 * ---------------------------------------------------------------------------- */
code {
  padding: 0.2rem 0.4rem;
  background-color: #fafafa; }

pre {
  white-space: pre-wrap;
  /*rtl:ignore*/
  text-align: left;
  border-radius: 0.1875rem; }

/* ------------------------------------------------------------------------------
 *
 *  # Table styling
 *
 *  Override and extend default Bootstrap styles.
 *
 * ---------------------------------------------------------------------------- */
.table {
  margin-bottom: 0; }
  .table th,
  .table td {
    vertical-align: middle; }
  .table th {
    font-weight: 500; }
  .table thead th {
    vertical-align: middle;
    border-bottom: 1px solid #b7b7b7; }
  .table tfoot th {
    border-top-color: #b7b7b7; }
  .table caption + thead tr:first-child th,
  .table caption + thead tr:first-child td,
  .table colgroup + thead tr:first-child th,
  .table colgroup + thead tr:first-child td,
  .table thead:first-child tr:first-child th,
  .table thead:first-child tr:first-child td {
    border-top-width: 0; }
  .table tr,
  .table th,
  .table td {
    transition: background-color ease-in-out 0.15s; }
    @media screen and (prefers-reduced-motion: reduce) {
      .table tr,
      .table th,
      .table td {
        transition: none; } }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #e4f2fe; }

.table-hover .table-primary:hover {
  background-color: #cbe6fd; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #cbe6fd; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #efefef; }

.table-hover .table-secondary:hover {
  background-color: #e2e2e2; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #e2e2e2; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #eaf5ea; }

.table-hover .table-success:hover {
  background-color: #d9edd9; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #d9edd9; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #e0f7fa; }

.table-hover .table-info:hover {
  background-color: #caf1f6; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #caf1f6; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeee8; }

.table-hover .table-warning:hover {
  background-color: #ffdbcf; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffdbcf; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fee8e7; }

.table-hover .table-danger:hover {
  background-color: #fdd1cf; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #fdd1cf; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fefefe; }

.table-hover .table-light:hover {
  background-color: #f1f1f1; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f1f1f1; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #e5e7ea; }

.table-hover .table-dark:hover {
  background-color: #d7dadf; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #d7dadf; }

.table-dark thead th,
.table-dark thead td {
  border-color: rgba(255, 255, 255, 0.5); }

@media (max-width: 575.98px) {
  .table-responsive-sm > .table-bordered {
    border: 1px solid #ddd; } }

@media (max-width: 767.98px) {
  .table-responsive-md > .table-bordered {
    border: 1px solid #ddd; } }

@media (max-width: 991.98px) {
  .table-responsive-lg > .table-bordered {
    border: 1px solid #ddd; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl > .table-bordered {
    border: 1px solid #ddd; } }

.table-responsive > .table-bordered {
  border: 1px solid #ddd; }

.table-scrollable {
  max-height: 22.5rem;
  overflow: auto; }

.table-columned tbody td,
.table-columned tbody th,
.table-columned tfoot td,
.table-columned tfoot th {
  border: 0;
  border-left: 1px solid #ddd; }
  .table-columned tbody td:first-child,
  .table-columned tbody th:first-child,
  .table-columned tfoot td:first-child,
  .table-columned tfoot th:first-child {
    border-left: 0; }

.table-columned thead th,
.table-columned thead td {
  border-left: 1px solid #ddd; }
  .table-columned thead th:first-child,
  .table-columned thead td:first-child {
    border-left: 0; }

.table-borderless td,
.table-borderless th {
  border: 0; }

.table-xl th,
.table-xl td {
  padding: 1.25rem 1.25rem; }

.table-lg th,
.table-lg td {
  padding: 1rem 1.25rem; }

.table-xs th,
.table-xs td {
  padding: 0.5rem 1.25rem; }

thead .table-border-solid th,
thead .table-border-solid td {
  border-bottom-width: 2px; }

tbody .table-border-solid td,
tbody .table-border-solid th,
tfoot .table-border-solid td,
tfoot .table-border-solid th {
  border-top-width: 2px; }

thead .table-border-double th,
thead .table-border-double td {
  border-bottom-width: 3px;
  border-bottom-style: double; }

.table-bordered thead .table-border-double:first-child th,
.table-bordered thead .table-border-double:first-child td {
  border-bottom-width: 3px;
  border-bottom-style: double; }

tbody .table-border-double td,
tbody .table-border-double th,
tfoot .table-border-double td,
tfoot .table-border-double th {
  border-top-width: 3px;
  border-top-style: double; }

.table-border-dashed td,
.table-border-dashed th {
  border-top-style: dashed; }

.table thead tr[class*=border-bottom-] th,
.table thead tr[class*=border-bottom-] td {
  border-bottom-color: inherit; }

.table tbody tr[class*=border-top-] th,
.table tbody tr[class*=border-top-] td,
.table tfoot tr[class*=border-top-] th,
.table tfoot tr[class*=border-top-] td {
  border-top-color: inherit; }

.card > .table:first-child caption + thead tr:first-child th,
.card > .table:first-child caption + thead tr:first-child td,
.card > .table:first-child colgroup + thead tr:first-child th,
.card > .table:first-child colgroup + thead tr:first-child td,
.card > .table:first-child thead:first-child tr:first-child th,
.card > .table:first-child thead:first-child tr:first-child td,
.card > [class*=table-responsive]:first-child > .table:first-child caption + thead tr:first-child th,
.card > [class*=table-responsive]:first-child > .table:first-child caption + thead tr:first-child td,
.card > [class*=table-responsive]:first-child > .table:first-child colgroup + thead tr:first-child th,
.card > [class*=table-responsive]:first-child > .table:first-child colgroup + thead tr:first-child td,
.card > [class*=table-responsive]:first-child > .table:first-child thead:first-child tr:first-child th,
.card > [class*=table-responsive]:first-child > .table:first-child thead:first-child tr:first-child td {
  border-top-width: 0; }

.card.card-table {
  border-width: 1px;
  border-color: #ddd; }

.card > .table caption,
.card > [class*=table-responsive] > .table caption {
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.card > .table:first-child,
.card > [class*=table-responsive]:first-child > .table:first-child {
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem; }
  .card > .table:first-child thead:first-child tr:first-child,
  .card > .table:first-child tbody:first-child tr:first-child,
  .card > [class*=table-responsive]:first-child > .table:first-child thead:first-child tr:first-child,
  .card > [class*=table-responsive]:first-child > .table:first-child tbody:first-child tr:first-child {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem; }
    .card > .table:first-child thead:first-child tr:first-child td:first-child,
    .card > .table:first-child thead:first-child tr:first-child th:first-child,
    .card > .table:first-child tbody:first-child tr:first-child td:first-child,
    .card > .table:first-child tbody:first-child tr:first-child th:first-child,
    .card > [class*=table-responsive]:first-child > .table:first-child thead:first-child tr:first-child td:first-child,
    .card > [class*=table-responsive]:first-child > .table:first-child thead:first-child tr:first-child th:first-child,
    .card > [class*=table-responsive]:first-child > .table:first-child tbody:first-child tr:first-child td:first-child,
    .card > [class*=table-responsive]:first-child > .table:first-child tbody:first-child tr:first-child th:first-child {
      border-top-left-radius: 0.125rem; }
    .card > .table:first-child thead:first-child tr:first-child td:last-child,
    .card > .table:first-child thead:first-child tr:first-child th:last-child,
    .card > .table:first-child tbody:first-child tr:first-child td:last-child,
    .card > .table:first-child tbody:first-child tr:first-child th:last-child,
    .card > [class*=table-responsive]:first-child > .table:first-child thead:first-child tr:first-child td:last-child,
    .card > [class*=table-responsive]:first-child > .table:first-child thead:first-child tr:first-child th:last-child,
    .card > [class*=table-responsive]:first-child > .table:first-child tbody:first-child tr:first-child td:last-child,
    .card > [class*=table-responsive]:first-child > .table:first-child tbody:first-child tr:first-child th:last-child {
      border-top-right-radius: 0.125rem; }

.card > .table:last-child,
.card > [class*=table-responsive]:last-child > .table:last-child {
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem; }
  .card > .table:last-child tbody:last-child tr:last-child,
  .card > .table:last-child tfoot:last-child tr:last-child,
  .card > [class*=table-responsive]:last-child > .table:last-child tbody:last-child tr:last-child,
  .card > [class*=table-responsive]:last-child > .table:last-child tfoot:last-child tr:last-child {
    border-bottom-left-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem; }
    .card > .table:last-child tbody:last-child tr:last-child td:first-child,
    .card > .table:last-child tbody:last-child tr:last-child th:first-child,
    .card > .table:last-child tfoot:last-child tr:last-child td:first-child,
    .card > .table:last-child tfoot:last-child tr:last-child th:first-child,
    .card > [class*=table-responsive]:last-child > .table:last-child tbody:last-child tr:last-child td:first-child,
    .card > [class*=table-responsive]:last-child > .table:last-child tbody:last-child tr:last-child th:first-child,
    .card > [class*=table-responsive]:last-child > .table:last-child tfoot:last-child tr:last-child td:first-child,
    .card > [class*=table-responsive]:last-child > .table:last-child tfoot:last-child tr:last-child th:first-child {
      border-bottom-left-radius: 0.125rem; }
    .card > .table:last-child tbody:last-child tr:last-child td:last-child,
    .card > .table:last-child tbody:last-child tr:last-child th:last-child,
    .card > .table:last-child tfoot:last-child tr:last-child td:last-child,
    .card > .table:last-child tfoot:last-child tr:last-child th:last-child,
    .card > [class*=table-responsive]:last-child > .table:last-child tbody:last-child tr:last-child td:last-child,
    .card > [class*=table-responsive]:last-child > .table:last-child tbody:last-child tr:last-child th:last-child,
    .card > [class*=table-responsive]:last-child > .table:last-child tfoot:last-child tr:last-child td:last-child,
    .card > [class*=table-responsive]:last-child > .table:last-child tfoot:last-child tr:last-child th:last-child {
      border-bottom-right-radius: 0.125rem; }

.card > .table-bordered,
.card > [class*=table-responsive] > .table-bordered {
  border: 0; }
  .card > .table-bordered thead th:first-child,
  .card > .table-bordered thead td:first-child,
  .card > .table-bordered tbody th:first-child,
  .card > .table-bordered tbody td:first-child,
  .card > .table-bordered tfoot th:first-child,
  .card > .table-bordered tfoot td:first-child,
  .card > [class*=table-responsive] > .table-bordered thead th:first-child,
  .card > [class*=table-responsive] > .table-bordered thead td:first-child,
  .card > [class*=table-responsive] > .table-bordered tbody th:first-child,
  .card > [class*=table-responsive] > .table-bordered tbody td:first-child,
  .card > [class*=table-responsive] > .table-bordered tfoot th:first-child,
  .card > [class*=table-responsive] > .table-bordered tfoot td:first-child {
    border-left: 0; }
  .card > .table-bordered thead th:last-child,
  .card > .table-bordered thead td:last-child,
  .card > .table-bordered tbody th:last-child,
  .card > .table-bordered tbody td:last-child,
  .card > .table-bordered tfoot th:last-child,
  .card > .table-bordered tfoot td:last-child,
  .card > [class*=table-responsive] > .table-bordered thead th:last-child,
  .card > [class*=table-responsive] > .table-bordered thead td:last-child,
  .card > [class*=table-responsive] > .table-bordered tbody th:last-child,
  .card > [class*=table-responsive] > .table-bordered tbody td:last-child,
  .card > [class*=table-responsive] > .table-bordered tfoot th:last-child,
  .card > [class*=table-responsive] > .table-bordered tfoot td:last-child {
    border-right: 0; }

.card-header:not([class*=bg-]) + .table,
.card-header:not([class*=bg-]) + div[class*=table-responsive],
.card-body + .table,
.card-body + div[class*=table-responsive],
.table + .card-body,
div[class*=table-responsive] + .card-body,
.navbar-light + div[class*=table-responsive],
.navbar-light + .table {
  border-top: 1px solid #ddd; }

.table tbody:first-child tr:first-child th,
div[class*=table-responsive] > .table tbody:first-child tr:first-child th,
.table tbody:first-child tr:first-child td,
div[class*=table-responsive] > .table tbody:first-child tr:first-child td {
  border-top: 0; }

.card-footer + [class*=table-responsive],
.card-footer + .table {
  border-top: 1px solid #ddd; }

/* ------------------------------------------------------------------------------
 *
 *  # Form styles
 *
 *  Basic form styles and overrides of default Bootstrap styles
 *
 * ---------------------------------------------------------------------------- */
legend {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid #ddd;
  font-size: 0.8125rem; }
  fieldset:first-child legend:first-child {
    padding-top: 0; }

select[multiple],
select[size] {
  height: 200px;
  padding: 0.875rem; }
  select[multiple] option,
  select[size] option {
    padding: 0.5rem 1rem;
    border-radius: 0.1875rem; }
    select[multiple] option + option,
    select[size] option + option {
      margin-top: 1px; }

.form-control {
  background-clip: border-box; }
  .form-control:disabled {
    color: #999; }
  .form-control[class*=bg-]:not(.bg-white):not(.bg-light):not(.bg-transparent):disabled, .form-control[class*=bg-]:not(.bg-white):not(.bg-light):not(.bg-transparent)[readonly] {
    background-color: #8c8c8c;
    color: rgba(255, 255, 255, 0.5);
    border-color: #8c8c8c; }
  .form-control[class*=bg-]:not(.bg-white):not(.bg-light):not(.bg-transparent)::-webkit-input-placeholder {
    color: #fff; }
  .form-control[class*=bg-]:not(.bg-white):not(.bg-light):not(.bg-transparent)::-moz-placeholder {
    color: #fff; }
  .form-control[class*=bg-]:not(.bg-white):not(.bg-light):not(.bg-transparent):-ms-input-placeholder {
    color: #fff; }
  .form-control[class*=bg-]:not(.bg-white):not(.bg-light):not(.bg-transparent)::-ms-input-placeholder {
    color: #fff; }
  .form-control[class*=bg-]:not(.bg-white):not(.bg-light):not(.bg-transparent)::placeholder {
    color: #fff; }

.form-group .form-group:last-child {
  margin-bottom: 0; }

@media (max-width: 767.98px) {
  .form-group [class*="col-md-"]:not([class*=col-form-label]) + [class*="col-md-"] {
    margin-top: 1.25rem; } }

@media (max-width: 991.98px) {
  .form-group [class*="col-lg-"]:not([class*=col-form-label]) + [class*="col-lg-"] {
    margin-top: 1.25rem; } }

@media (max-width: 1199.98px) {
  .form-group [class*="col-xl-"]:not([class*=col-form-label]) + [class*="col-xl-"] {
    margin-top: 1.25rem; } }

@media (max-width: 767.98px) {
  [class*=col-form-label][class*="col-md-"] {
    padding-top: 0; } }

@media (max-width: 991.98px) {
  [class*=col-form-label][class*="col-lg-"] {
    padding-top: 0; } }

@media (max-width: 1199.98px) {
  [class*=col-form-label][class*="col-xl-"] {
    padding-top: 0; } }

.form-check:not(.form-check-inline) {
  margin-bottom: 0.5rem; }
  .form-check:not(.form-check-inline):last-child {
    margin-bottom: 0; }

.form-group.row .form-check:not(.dropdown-item) {
  margin-top: 0.5rem; }

.form-check.disabled {
  color: #999; }
  .form-check.disabled .form-check-label {
    cursor: default; }

.form-check-input:disabled ~ .form-check-label {
  cursor: default; }

.form-check-label {
  cursor: pointer; }

.form-check-inline .form-check-label {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: start;
      align-items: flex-start; }

.form-check-inline .form-check-input {
  margin-top: 0.3rem; }

.form-check-inline:last-child {
  margin-right: 0; }

.form-check-inline input {
  position: static; }

.form-check-right {
  padding-left: 0;
  padding-right: 1.875rem; }
  .form-check-right .form-check-input,
  .form-check-right input {
    left: auto;
    right: 0; }
  .form-check-right.form-check-inline {
    padding: 0; }
    .form-check-right.form-check-inline .form-check-input {
      margin-right: 0;
      margin-left: 0.625rem; }

.form-group-feedback {
  position: relative; }
  .form-group-feedback-left .form-control-feedback {
    left: 0; }
  .form-group-feedback-left .form-control {
    padding-left: 2.75rem; }
    .form-group-feedback-left .form-control-lg, .form-group-feedback-left .input-group-lg > .form-control,
    .form-group-feedback-left .input-group-lg > .input-group-prepend > .input-group-text,
    .form-group-feedback-left .input-group-lg > .input-group-append > .input-group-text,
    .form-group-feedback-left .input-group-lg > .input-group-prepend > .btn,
    .form-group-feedback-left .input-group-lg > .input-group-append > .btn {
      padding-left: 3rem; }
    .form-group-feedback-left .form-control-sm, .form-group-feedback-left .input-group-sm > .form-control,
    .form-group-feedback-left .input-group-sm > .input-group-prepend > .input-group-text,
    .form-group-feedback-left .input-group-sm > .input-group-append > .input-group-text,
    .form-group-feedback-left .input-group-sm > .input-group-prepend > .btn,
    .form-group-feedback-left .input-group-sm > .input-group-append > .btn {
      padding-left: 2.5rem; }
  .form-group-feedback-right .form-control-feedback {
    right: 0; }
  .form-group-feedback-right .form-control {
    padding-right: 2.75rem; }
    .form-group-feedback-right .form-control-lg, .form-group-feedback-right .input-group-lg > .form-control,
    .form-group-feedback-right .input-group-lg > .input-group-prepend > .input-group-text,
    .form-group-feedback-right .input-group-lg > .input-group-append > .input-group-text,
    .form-group-feedback-right .input-group-lg > .input-group-prepend > .btn,
    .form-group-feedback-right .input-group-lg > .input-group-append > .btn {
      padding-right: 3rem; }
    .form-group-feedback-right .form-control-sm, .form-group-feedback-right .input-group-sm > .form-control,
    .form-group-feedback-right .input-group-sm > .input-group-prepend > .input-group-text,
    .form-group-feedback-right .input-group-sm > .input-group-append > .input-group-text,
    .form-group-feedback-right .input-group-sm > .input-group-prepend > .btn,
    .form-group-feedback-right .input-group-sm > .input-group-append > .btn {
      padding-right: 2.5rem; }

.form-control-feedback {
  position: absolute;
  top: 0;
  color: #333;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  line-height: 2.25003rem;
  min-width: 1rem; }
  .form-control-feedback-lg {
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: 2.50002rem; }
  .form-control-feedback-sm {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    line-height: 2.00002rem; }
  input[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) + .form-control-feedback {
    color: #fff; }

/* ------------------------------------------------------------------------------
 *
 *  # Button component
 *
 *  Overrides for button bootstrap component
 *
 * ---------------------------------------------------------------------------- */
.btn {
  position: relative; }
  .btn:not(.bg-transparent):not([class*=btn-outline]):not(.btn-light):not(.btn-link):not([class*=alpha-]):not(.fab-menu-btn):hover, .btn:not(.bg-transparent):not([class*=btn-outline]):not(.btn-light):not(.btn-link):not([class*=alpha-]):not(.fab-menu-btn):focus, .btn:not(.bg-transparent):not([class*=btn-outline]):not(.btn-light):not(.btn-link):not([class*=alpha-]):not(.fab-menu-btn).focus, .btn:not(.bg-transparent):not([class*=btn-outline]):not(.btn-light):not(.btn-link):not([class*=alpha-]):not(.fab-menu-btn):active, .btn:not(.bg-transparent):not([class*=btn-outline]):not(.btn-light):not(.btn-link):not([class*=alpha-]):not(.fab-menu-btn).active,
  .show > .btn:not(.bg-transparent):not([class*=btn-outline]):not(.btn-light):not(.btn-link):not([class*=alpha-]):not(.fab-menu-btn).dropdown-toggle {
    box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.075) inset; }
  .btn:not(.bg-transparent):not([class*=btn-outline]):not(.btn-light):not(.btn-link):not([class*=alpha-]):not(.fab-menu-btn).disabled, .btn:not(.bg-transparent):not([class*=btn-outline]):not(.btn-light):not(.btn-link):not([class*=alpha-]):not(.fab-menu-btn):disabled {
    box-shadow: none; }
  .btn.disabled, .btn:disabled {
    cursor: default; }

.btn-light {
  color: #333;
  background-color: #fafafa;
  border-color: #ddd; }
  .btn-light:hover, .btn-light:focus, .btn-light.focus, .btn-light:not([disabled]):not(.disabled):active, .btn-light:not([disabled]):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd; }
  .btn-light.disabled, .btn-light:disabled {
    background-color: #fafafa;
    border-color: #ddd; }

.btn-outline:not(:hover):not(:active):not(.active):not([aria-expanded=true]), .btn-outline.disabled, .btn-outline:disabled {
  background-color: transparent !important; }

.btn-outline:not(.disabled):not(:disabled):not([class*=alpha-]):hover, .btn-outline:not(.disabled):not(:disabled):not([class*=alpha-]):not([disabled]):not(.disabled):active, .btn-outline:not(.disabled):not(:disabled):not([class*=alpha-]):not([disabled]):not(.disabled).active,
.show > .btn-outline:not(.disabled):not(:disabled):not([class*=alpha-]).dropdown-toggle {
  color: #fff !important; }

.btn-outline.bg-white:not(.disabled):not(:disabled):hover, .btn-outline.bg-white:not(.disabled):not(:disabled):not([disabled]):not(.disabled):active, .btn-outline.bg-white:not(.disabled):not(:disabled):not([disabled]):not(.disabled).active,
.show > .btn-outline.bg-white:not(.disabled):not(:disabled).dropdown-toggle {
  color: #333 !important; }

.show > .btn-link.dropdown-toggle {
  color: #0a6ebd; }

.btn-icon {
  padding-left: 0.56252rem;
  padding-right: 0.56252rem; }
  .btn-icon input[type=checkbox],
  .btn-icon input[type=radio] {
    display: block; }
  .btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
    padding-left: 0.43752rem;
    padding-right: 0.43752rem; }
  .btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
    padding-left: 0.68752rem;
    padding-right: 0.68752rem; }

.btn-float {
  padding: 1rem;
  border-radius: 0.1875rem; }
  .btn-float.btn-link {
    padding: 0.8rem; }
  .btn-float i {
    display: block;
    top: 0; }
  .btn-float > span {
    display: block;
    padding-top: 0.625rem;
    margin-bottom: -0.40625rem;
    overflow: hidden;
    text-overflow: ellipsis; }

.btn.border-2 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem; }
  .btn.border-2.btn-lg, .btn-group-lg > .btn.border-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .btn.border-2.btn-sm, .btn-group-sm > .btn.border-2 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .btn.border-2.btn-icon {
    padding-left: 0.50002rem;
    padding-right: 0.50002rem; }
    .btn.border-2.btn-icon.btn-lg, .btn-group-lg > .btn.border-2.btn-icon {
      padding-left: 0.62502rem;
      padding-right: 0.62502rem; }
    .btn.border-2.btn-icon.btn-sm, .btn-group-sm > .btn.border-2.btn-icon {
      padding-left: 0.37502rem;
      padding-right: 0.37502rem; }
  .btn.border-2.btn-float {
    padding: 0.9375rem; }

.btn-labeled > b {
  position: absolute;
  top: -1px;
  background-color: rgba(0, 0, 0, 0.15);
  display: block;
  line-height: 1;
  padding: 0.62503rem; }
  .btn-labeled > b > i {
    top: 0; }

.btn-labeled.rounded-round > b {
  border-radius: 100px; }

.btn-labeled.btn-lg > b, .btn-group-lg > .btn-labeled.btn > b {
  padding: 0.75003rem; }

.btn-labeled.btn-sm > b, .btn-group-sm > .btn-labeled.btn > b {
  padding: 0.50003rem; }

.btn-labeled-left {
  padding-left: 3.12506rem; }
  .btn-labeled-left > b {
    left: -1px;
    border-top-left-radius: 0.1875rem;
    border-bottom-left-radius: 0.1875rem; }
  .btn-labeled-left.btn-lg, .btn-group-lg > .btn-labeled-left.btn {
    padding-left: 3.50005rem; }
    .btn-labeled-left.btn-lg > b, .btn-group-lg > .btn-labeled-left.btn > b {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem; }
  .btn-labeled-left.btn-sm, .btn-group-sm > .btn-labeled-left.btn {
    padding-left: 2.75006rem; }
    .btn-labeled-left.btn-sm > b, .btn-group-sm > .btn-labeled-left.btn > b {
      border-top-left-radius: 0.125rem;
      border-bottom-left-radius: 0.125rem; }

.btn-labeled-right {
  padding-right: 3.12506rem; }
  .btn-labeled-right > b {
    right: -1px;
    border-top-right-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem; }

/* ------------------------------------------------------------------------------
 *
 *  # Dropdown menu component
 *
 *  Overrides for dropdown menu bootstrap component
 *
 * ---------------------------------------------------------------------------- */
.dropdown-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  position: relative;
  outline: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  margin-bottom: 0;
  transition: background-color ease-in-out 0.15s, color ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .dropdown-item {
      transition: none; } }
  .dropdown-item + .dropdown-item,
  .dropdown-submenu + .dropdown-item,
  .dropdown-item + .dropdown-submenu {
    margin-top: 1px; }
  .dropdown-item:active {
    color: #333;
    background-color: #f5f5f5; }
  .dropdown-item.active {
    color: #fff;
    background-color: #2196F3; }
    .dropdown-item.active .badge:not(.badge-mark) {
      background-color: #fff !important;
      color: #333 !important; }
    .dropdown-item.active .badge-mark[class*=bg-] {
      background-color: #fff !important;
      border-color: #fff !important; }
    .dropdown-item.active .badge-mark[class*=border-] {
      border-color: #fff !important; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #999;
    background-color: transparent;
    cursor: default; }
    .dropdown-item.disabled > .badge,
    .dropdown-item.disabled > img, .dropdown-item:disabled > .badge,
    .dropdown-item:disabled > img {
      opacity: 0.8; }

.dropdown-item-open {
  background-color: #f5f5f5;
  color: #333; }

.dropdown-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  line-height: 1.6667; }
  .dropdown-header-highlight {
    margin-top: 0;
    background-color: #f8f8f8; }
    .dropdown-item + .dropdown-header-highlight,
    .dropdown-header-highlight + .dropdown-item {
      margin-top: 0.5rem; }
    .dropdown-header-highlight:first-child {
      margin-top: 0; }

.dropdown-item > i,
.dropdown-item > img,
.dropdown-header > i,
.dropdown-header > img {
  margin-right: 1rem; }

.dropdown-item > i,
.dropdown-header > i {
  top: 0; }

.dropdown-item > img,
.dropdown-header > img {
  max-height: 1.25003rem; }

.dropdown-divider {
  margin: 0.5rem 0; }

.dropdown-item.form-check {
  padding: 0;
  margin-bottom: 0; }
  .dropdown-item.form-check .form-check-label {
    -ms-flex: 1;
        flex: 1;
    padding: 0.5rem 1rem; }
  .dropdown-item.form-check.form-check-right .form-check-label {
    padding-left: 1rem; }

.dropdown-scrollable {
  max-height: 340px;
  overflow-y: auto; }

.dropdown-menu > .dropdown-submenu {
  position: relative; }
  .dropdown-menu > .dropdown-submenu > .dropdown-item {
    padding-right: 2.5rem; }
    .dropdown-menu > .dropdown-submenu > .dropdown-item:after {
      content: '\e9c7';
      font-family: "icomoon";
      position: absolute;
      top: 50%;
      margin-top: -0.5rem;
      right: 1rem;
      font-size: 1rem;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
  .dropdown-menu > .dropdown-submenu.show > .dropdown-item, .dropdown-menu > .dropdown-submenu:hover > .dropdown-item {
    background-color: #f5f5f5;
    color: #333; }
  .dropdown-menu > .dropdown-submenu:hover > .dropdown-item:not(.dropdown-toggle) ~ .dropdown-menu,
  .dropdown-menu > .dropdown-submenu:focus > .dropdown-item:not(.dropdown-toggle) ~ .dropdown-menu {
    display: block; }
  .dropdown-menu > .dropdown-submenu.disabled > .dropdown-menu {
    display: none; }
  .dropdown-menu > .dropdown-submenu.disabled > .dropdown-item {
    background-color: transparent;
    color: #999;
    cursor: default; }
  .dropdown-menu > .dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -0.5625rem; }
  .dropdown-menu > .dropdown-submenu.dropdown-submenu-left > .dropdown-menu {
    left: auto;
    right: 100%; }
  @media (max-width: 991.98px) {
    .dropdown-menu > .dropdown-submenu .dropdown-toggle:after {
      content: '\e9c5'; }
    .dropdown-menu > .dropdown-submenu .dropdown-menu, .dropdown-menu > .dropdown-submenu.dropdown-submenu-left .dropdown-menu {
      position: static;
      margin: 0 !important;
      border-width: 0;
      box-shadow: none;
      border-radius: 0; }
    .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-item,
    .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
      padding-left: 2rem; }
    .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-item,
    .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
      padding-left: 3rem; } }

.dropdown-menu .dropup > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -0.5625rem; }

.dropdown-menu .dropdown-submenu + .dropdown-submenu {
  margin-top: 1px; }

.dropright .dropdown-toggle::after {
  vertical-align: middle; }

.dropleft .dropdown-toggle::before {
  vertical-align: middle; }

.dropleft .dropdown-submenu .dropdown-toggle::before {
  content: none; }

.dropdown-menu-lg .dropdown-item {
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
  font-size: 0.875rem;
  line-height: 1.4286; }

.dropdown-menu-sm .dropdown-item {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  font-size: 0.75rem;
  line-height: 1.6667; }

.dropdown-menu[class*=bg-]:not(.bg-white):not(.bg-light) > .dropdown-item,
.dropdown-menu[class*=bg-]:not(.bg-white):not(.bg-light) > .dropdown-submenu > .dropdown-item {
  color: #fff; }
  .dropdown-menu[class*=bg-]:not(.bg-white):not(.bg-light) > .dropdown-item:hover, .dropdown-menu[class*=bg-]:not(.bg-white):not(.bg-light) > .dropdown-item:focus,
  .dropdown-menu[class*=bg-]:not(.bg-white):not(.bg-light) > .dropdown-submenu > .dropdown-item:hover,
  .dropdown-menu[class*=bg-]:not(.bg-white):not(.bg-light) > .dropdown-submenu > .dropdown-item:focus {
    background-color: rgba(0, 0, 0, 0.1); }
  .dropdown-menu[class*=bg-]:not(.bg-white):not(.bg-light) > .dropdown-item.active,
  .dropdown-menu[class*=bg-]:not(.bg-white):not(.bg-light) > .dropdown-submenu > .dropdown-item.active {
    background-color: rgba(0, 0, 0, 0.2); }
  .dropdown-menu[class*=bg-]:not(.bg-white):not(.bg-light) > .dropdown-item.disabled,
  .dropdown-menu[class*=bg-]:not(.bg-white):not(.bg-light) > .dropdown-submenu > .dropdown-item.disabled {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.6); }

.dropdown-menu[class*=bg-]:not(.bg-white):not(.bg-light) > .dropdown-submenu.show > .dropdown-item, .dropdown-menu[class*=bg-]:not(.bg-white):not(.bg-light) > .dropdown-submenu:hover > .dropdown-item {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff; }

.dropdown-menu[class*=bg-]:not(.bg-white):not(.bg-light) > .dropdown-submenu.disabled > .dropdown-item {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.6); }

.dropdown-menu[class*=bg-]:not(.bg-white):not(.bg-light) > .dropdown-header {
  color: rgba(255, 255, 255, 0.6); }
  .dropdown-menu[class*=bg-]:not(.bg-white):not(.bg-light) > .dropdown-header-highlight {
    background-color: rgba(0, 0, 0, 0.1); }

.dropdown-menu[class*=bg-]:not(.bg-white):not(.bg-light) > .dropdown-divider {
  border-top-color: rgba(255, 255, 255, 0.4); }

/* ------------------------------------------------------------------------------
 *
 *  # Button group component
 *
 *  Overrides for button group bootstrap component
 *
 * ---------------------------------------------------------------------------- */
.btn-group .btn + .btn.border-2,
.btn-group .btn.border-2 + .btn-group,
.btn-group .btn-group + .btn.border-2,
.btn-group-vertical .btn + .btn.border-2,
.btn-group-vertical .btn.border-2 + .btn-group,
.btn-group-vertical .btn-group + .btn.border-2 {
  margin-left: -2px; }

.btn-group .btn + .btn.border-3,
.btn-group .btn.border-3 + .btn-group,
.btn-group .btn-group + .btn.border-3,
.btn-group-vertical .btn + .btn.border-3,
.btn-group-vertical .btn.border-3 + .btn-group,
.btn-group-vertical .btn-group + .btn.border-3 {
  margin-left: -3px; }

.btn-group-justified {
  display: -ms-flexbox;
  display: flex; }
  .btn-group-justified .btn,
  .btn-group-justified .btn-group {
    -ms-flex: 1;
        flex: 1; }

/* ------------------------------------------------------------------------------
 *
 *  # Input group component
 *
 *  Overrides for input group component
 *
 * ---------------------------------------------------------------------------- */
.input-group .form-group-feedback {
  position: relative;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  width: 1%;
  z-index: 4; }
  .input-group .form-group-feedback:not(:last-child) .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group .form-group-feedback:not(:first-child) .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.input-group + .form-control-feedback,
.form-control-feedback + .input-group {
  z-index: 4; }

.input-group-text i {
  display: block;
  top: 0; }

/* ------------------------------------------------------------------------------
 *
 *  # Custom form inputs
 *
 *  Custom checkboxes, radios, selects and file inputs
 *
 * ---------------------------------------------------------------------------- */
.custom-control {
  min-height: 1.25003rem;
  margin-bottom: 0.5rem; }
  .custom-control:last-child {
    margin-bottom: 0; }

.custom-control-inline {
  margin-bottom: 0; }
  .custom-control-inline:last-child {
    margin-right: 0; }

.custom-control-input:active ~ .custom-control-label::before {
  transition: all ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .custom-control-input:active ~ .custom-control-label::before {
      transition: none; } }

.custom-control-input:disabled ~ .custom-control-label {
  cursor: default; }

.custom-control-label {
  cursor: pointer; }
  .custom-control-label::before {
    top: 0.00002rem; }
  .custom-control-label::after {
    top: 0.00002rem; }

.custom-control-right {
  padding-left: 0;
  padding-right: 1.875rem; }
  .custom-control-right .custom-control-input {
    right: 0; }
  .custom-control-right .custom-control-label:before, .custom-control-right .custom-control-label:after {
    left: auto;
    right: 0; }

.custom-select {
  cursor: pointer;
  transition: all ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .custom-select {
      transition: none; } }
  .custom-select:hover, .custom-select:focus {
    box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.01) inset; }
  .custom-select:disabled {
    cursor: default; }
    .custom-select:disabled:hover, .custom-select:disabled:focus {
      box-shadow: none; }

.custom-file-label {
  margin-bottom: 0; }

/* ------------------------------------------------------------------------------
 *
 *  # Nav components
 *
 *  Overrides for nav components
 *
 * ---------------------------------------------------------------------------- */
.nav-link {
  position: relative;
  transition: all ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link.disabled {
    cursor: default; }

.nav-tabs {
  margin-bottom: 1.25rem; }
  .nav-tabs .nav-link {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    color: #777; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      color: #333; }
    .nav-tabs .nav-link.disabled {
      color: #999; }
  .nav-tabs .nav-item.show .nav-link:not(.active) {
    border-color: transparent;
    background-color: transparent; }
  .nav-tabs.border-top-1 {
    border-top-color: #ddd; }

.nav-tabs-responsive {
  overflow-x: auto;
  box-shadow: 0 -1px 0 #ddd inset; }

.nav-tabs-highlight .nav-link,
.nav-tabs-top .nav-link,
.nav-tabs-bottom .nav-link {
  position: relative; }
  .nav-tabs-highlight .nav-link:before,
  .nav-tabs-top .nav-link:before,
  .nav-tabs-bottom .nav-link:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: background-color ease-in-out 0.15s; }
    @media screen and (prefers-reduced-motion: reduce) {
      .nav-tabs-highlight .nav-link:before,
      .nav-tabs-top .nav-link:before,
      .nav-tabs-bottom .nav-link:before {
        transition: none; } }

.nav-tabs-highlight .nav-link {
  border-top-color: transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .nav-tabs-highlight .nav-link:before {
    height: 2px;
    top: -1px;
    left: -1px;
    right: -1px; }

.nav-tabs-highlight .nav-link.active:before {
  background-color: #2196F3; }

.nav-tabs-top .nav-item {
  margin-bottom: 0; }

.nav-tabs-top .nav-link {
  border-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .nav-tabs-top .nav-link:hover:before, .nav-tabs-top .nav-link:focus:before {
    background-color: #ddd; }
  .nav-tabs-top .nav-link:before {
    height: 2px; }
  .nav-tabs-top .nav-link.disabled:before {
    content: none; }

.nav-tabs-top .nav-item.show .nav-link:not(.active):before {
  background-color: #ddd; }

.nav-tabs-top .nav-link.active:before {
  background-color: #EC407A; }

.nav-tabs-top .nav-link.active,
.nav-tabs-top .nav-item.show .nav-link {
  background-color: transparent; }

.nav-tabs-top .dropdown-menu {
  margin-top: 0; }

.nav-tabs-top.border-top-1 .nav-item {
  margin-top: -1px; }

.nav-tabs-bottom .nav-item {
  margin-bottom: 0; }

.nav-tabs-bottom .nav-link {
  border-width: 0; }
  .nav-tabs-bottom .nav-link:before {
    height: 2px;
    top: auto;
    bottom: -1px; }

.nav-tabs-bottom .nav-link.active:before {
  background-color: #EC407A; }

.nav-tabs-bottom .nav-link.active,
.nav-tabs-bottom .nav-item.show .nav-link {
  background-color: transparent; }

.nav-tabs-bottom .dropdown-menu {
  margin-top: 0; }

.nav-tabs-solid {
  background-color: #f5f5f5;
  border: 1px solid #ddd; }
  .nav-tabs-solid .nav-item {
    margin-bottom: 0; }
  .nav-tabs-solid .nav-link {
    border-width: 0; }
  .nav-tabs-solid .nav-item.show .nav-link {
    background-color: transparent; }
  .nav-tabs-solid .nav-link.active,
  .nav-tabs-solid .nav-item.show .nav-link.active {
    color: #fff;
    background-color: #2196F3; }
  .nav-tabs-solid[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-link {
    color: rgba(255, 255, 255, 0.9); }
    .nav-tabs-solid[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-link:hover, .nav-tabs-solid[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-link:focus {
      color: #fff; }
    .nav-tabs-solid[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-link.disabled {
      color: rgba(255, 255, 255, 0.6); }
  .nav-tabs-solid[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-item.show .nav-link {
    color: #fff; }
  .nav-tabs-solid[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-link.active,
  .nav-tabs-solid[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-item.show .nav-link.active {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.1); }
  .nav-tabs-solid[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) + .tab-content[class*=bg-] {
    border-top-color: rgba(255, 255, 255, 0.5); }

.nav-tabs-vertical .nav-link {
  -ms-flex-pack: start;
      justify-content: flex-start; }
  .nav-tabs-vertical .nav-link, .nav-tabs-vertical .nav-link:hover, .nav-tabs-vertical .nav-link:focus, .nav-tabs-vertical .nav-link.disabled {
    border-color: transparent #ddd transparent transparent; }
  .nav-tabs-vertical .nav-link:before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    width: 2px;
    transition: background-color ease-in-out 0.15s; }
    @media screen and (prefers-reduced-motion: reduce) {
      .nav-tabs-vertical .nav-link:before {
        transition: none; } }

.nav-tabs-vertical .nav-item.show .nav-link:not(.active) {
  border-right-color: #ddd;
  border-bottom-color: transparent; }

.nav-tabs-vertical-right .nav-link, .nav-tabs-vertical-right .nav-link:hover, .nav-tabs-vertical-right .nav-link:focus, .nav-tabs-vertical-right .nav-link.disabled {
  border-color: transparent transparent transparent #ddd; }

.nav-tabs-vertical-right .nav-link:before {
  left: auto;
  right: -1px; }

.nav-tabs-vertical-right .nav-item.show .nav-link:not(.active) {
  border-right-color: transparent;
  border-left-color: #ddd; }

.nav-tabs-vertical .nav-link.active:before {
  background-color: #2196F3; }

.nav-tabs-vertical .nav-link.active,
.nav-tabs-vertical .nav-item.show .nav-link {
  background-color: transparent;
  border-color: #ddd transparent; }

.nav-pills {
  margin-bottom: 1.25rem; }
  .nav-pills .nav-link {
    color: #777; }
    .nav-pills .nav-link:not(.active):hover, .nav-pills .nav-link:not(.active):focus {
      color: #333; }
    .nav-pills .nav-link.active .badge:not(.bg-transparent):not(.bg-light):not(.bg-white) {
      background-color: #fff;
      color: #333;
      transition: all ease-in-out 0.15s; }
      @media screen and (prefers-reduced-motion: reduce) {
        .nav-pills .nav-link.active .badge:not(.bg-transparent):not(.bg-light):not(.bg-white) {
          transition: none; } }
    .nav-pills .nav-link.disabled, .nav-pills .nav-link.disabled:hover, .nav-pills .nav-link.disabled:focus {
      color: #999; }
  .nav-pills .nav-item.show .nav-link:not(.active) {
    color: #333;
    background-color: transparent; }
  .nav-pills.flex-column .nav-link {
    -ms-flex-pack: start;
        justify-content: flex-start; }

.nav-pills-bordered .nav-item + .nav-item {
  margin-left: 0.625rem; }

.nav-pills-bordered .nav-link {
  border: 1px solid #ddd; }

.nav-pills-bordered .nav-link:not(.active):hover,
.nav-pills-bordered .nav-link:not(.active):focus,
.nav-pills-bordered .nav-item.show .nav-link:not(.active) {
  background-color: #f5f5f5; }

.nav-pills-bordered .nav-link.active {
  border-color: #2196F3; }

.nav-pills-bordered .nav-link.disabled, .nav-pills-bordered .nav-link.disabled:hover, .nav-pills-bordered .nav-link.disabled:focus {
  background-color: #fafafa; }

.nav-pills-bordered.flex-column .nav-item + .nav-item {
  margin-left: 0;
  margin-top: 0.625rem; }

.nav-pills-toolbar .nav-item + .nav-item {
  margin-left: -1px; }

.nav-pills-toolbar .nav-link {
  border-radius: 0; }
  .nav-pills-toolbar .nav-link.active {
    z-index: 3; }

.nav-pills-toolbar .nav-item:first-child .nav-link {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem; }

.nav-pills-toolbar .nav-item:last-child .nav-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem; }

.nav-pills-toolbar.flex-column .nav-item:first-child .nav-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem; }

.nav-pills-toolbar.flex-column .nav-item:last-child .nav-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-right-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem; }

.nav-pills-toolbar.flex-column .nav-item + .nav-item {
  margin-left: 0;
  margin-top: -1px; }

/* ------------------------------------------------------------------------------
 *
 *  # Navbar component
 *
 *  Overrides for navbar component
 *
 * ---------------------------------------------------------------------------- */
.navbar {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  -ms-flex-align: stretch;
      align-items: stretch; }
  .page-content ~ .navbar:not(.navbar-component):last-of-type,
  .content ~ .navbar:not(.navbar-component):last-of-type {
    border-bottom-color: transparent; }

.navbar-component {
  border: 1px solid transparent;
  margin-bottom: 1.25rem; }
  .page-header .navbar-component {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .navbar-component.navbar-dark {
    border-color: rgba(255, 255, 255, 0.1); }
  .navbar-component.navbar-light {
    border-color: rgba(0, 0, 0, 0.125); }

.navbar-brand img {
  height: 1rem;
  display: block; }

.navbar-text {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem; }

.navbar-nav .nav-link {
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.navbar-nav-link {
  position: relative;
  display: block;
  cursor: pointer;
  padding: 0.875rem 1rem;
  outline: 0;
  transition: all ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .navbar-nav-link {
      transition: none; } }
  .navbar-nav-link.disabled {
    cursor: default; }
    .navbar-nav-link.disabled .badge,
    .navbar-nav-link.disabled img {
      opacity: 0.5; }
  .navbar-nav-link .badge {
    position: absolute;
    top: 0;
    right: 0; }
    .navbar-nav-link .badge-mark {
      top: 0.4375rem;
      right: 0.5rem; }
  .navbar-nav-highlight .navbar-nav-link:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    width: 2px; }
  .navbar-light .navbar-nav-highlight .navbar-nav-link.active:before {
    background-color: #EC407A; }
  .navbar-dark .navbar-nav-highlight .navbar-nav-link.active:before {
    background-color: #fff; }

.navbar-collapse {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.navbar-toggler {
  border: 0;
  line-height: 1.5385;
  cursor: pointer;
  transition: all ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler + .navbar-toggler {
    margin-left: 1.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    outline: 0; }
  .navbar-dark .navbar-toggler:hover, .navbar-dark .navbar-toggler:focus {
    color: #fff; }
  .navbar-light .navbar-toggler {
    color: rgba(51, 51, 51, 0.85); }
    .navbar-light .navbar-toggler:hover, .navbar-light .navbar-toggler:focus, .navbar-light .navbar-toggler[aria-expanded=true] {
      color: #333; }

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-nav:first-child > .nav-item:first-child {
    margin-top: 0.625rem; }
  .navbar-expand-sm .navbar-nav:last-child > .nav-item:not(.show):last-child {
    margin-bottom: 0.625rem; }
  .navbar-expand-sm .navbar-nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
    .navbar-expand-sm .navbar-nav-link .badge:not(.position-static) {
      position: static; }
    .navbar-expand-sm .navbar-nav-link.dropdown-toggle:not(.caret-0) {
      padding-right: 2.5rem; }
      .navbar-expand-sm .navbar-nav-link.dropdown-toggle:not(.caret-0):after {
        position: absolute;
        top: 50%;
        right: 1.25rem;
        margin: 0;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); }
  .navbar-expand-sm.navbar-dark .navbar-collapse {
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
  .navbar-expand-sm.navbar-light .navbar-collapse {
    border-top: 1px solid #ddd; }
  .navbar-expand-sm .navbar-nav > .nav-item > .dropdown-menu {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .navbar-expand-sm .dropdown-submenu .dropdown-toggle:after {
    content: '\e9c5'; }
  .navbar-expand-sm .dropdown-submenu .dropdown-menu, .navbar-expand-sm .dropdown-submenu.dropdown-submenu-left .dropdown-menu {
    margin: 0;
    border-width: 0;
    box-shadow: none;
    border-radius: 0; }
  .navbar-expand-sm .dropdown-submenu > .dropdown-menu > .dropdown-item,
  .navbar-expand-sm .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
    padding-left: 2rem; }
  .navbar-expand-sm .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-item,
  .navbar-expand-sm .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
    padding-left: 3rem; }
  .navbar-expand-sm.fixed-top .navbar-collapse,
  .navbar-expand-sm.fixed-bottom .navbar-collapse {
    max-height: 440px;
    overflow-y: auto; } }

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-brand {
    min-width: 15.625rem; }
  .navbar-expand-sm .navbar-nav-link:before {
    top: auto;
    right: 0;
    width: auto;
    height: 2px; } }

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-nav:first-child > .nav-item:first-child {
    margin-top: 0.625rem; }
  .navbar-expand-md .navbar-nav:last-child > .nav-item:not(.show):last-child {
    margin-bottom: 0.625rem; }
  .navbar-expand-md .navbar-nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
    .navbar-expand-md .navbar-nav-link .badge:not(.position-static) {
      position: static; }
    .navbar-expand-md .navbar-nav-link.dropdown-toggle:not(.caret-0) {
      padding-right: 2.5rem; }
      .navbar-expand-md .navbar-nav-link.dropdown-toggle:not(.caret-0):after {
        position: absolute;
        top: 50%;
        right: 1.25rem;
        margin: 0;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); }
  .navbar-expand-md.navbar-dark .navbar-collapse {
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
  .navbar-expand-md.navbar-light .navbar-collapse {
    border-top: 1px solid #ddd; }
  .navbar-expand-md .navbar-nav > .nav-item > .dropdown-menu {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .navbar-expand-md .dropdown-submenu .dropdown-toggle:after {
    content: '\e9c5'; }
  .navbar-expand-md .dropdown-submenu .dropdown-menu, .navbar-expand-md .dropdown-submenu.dropdown-submenu-left .dropdown-menu {
    margin: 0;
    border-width: 0;
    box-shadow: none;
    border-radius: 0; }
  .navbar-expand-md .dropdown-submenu > .dropdown-menu > .dropdown-item,
  .navbar-expand-md .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
    padding-left: 2rem; }
  .navbar-expand-md .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-item,
  .navbar-expand-md .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
    padding-left: 3rem; }
  .navbar-expand-md.fixed-top .navbar-collapse,
  .navbar-expand-md.fixed-bottom .navbar-collapse {
    max-height: 440px;
    overflow-y: auto; } }

@media (min-width: 768px) {
  .navbar-expand-md .navbar-brand {
    min-width: 15.625rem; }
  .navbar-expand-md .navbar-nav-link:before {
    top: auto;
    right: 0;
    width: auto;
    height: 2px; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-nav:first-child > .nav-item:first-child {
    margin-top: 0.625rem; }
  .navbar-expand-lg .navbar-nav:last-child > .nav-item:not(.show):last-child {
    margin-bottom: 0.625rem; }
  .navbar-expand-lg .navbar-nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
    .navbar-expand-lg .navbar-nav-link .badge:not(.position-static) {
      position: static; }
    .navbar-expand-lg .navbar-nav-link.dropdown-toggle:not(.caret-0) {
      padding-right: 2.5rem; }
      .navbar-expand-lg .navbar-nav-link.dropdown-toggle:not(.caret-0):after {
        position: absolute;
        top: 50%;
        right: 1.25rem;
        margin: 0;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); }
  .navbar-expand-lg.navbar-dark .navbar-collapse {
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
  .navbar-expand-lg.navbar-light .navbar-collapse {
    border-top: 1px solid #ddd; }
  .navbar-expand-lg .navbar-nav > .nav-item > .dropdown-menu {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .navbar-expand-lg .dropdown-submenu .dropdown-toggle:after {
    content: '\e9c5'; }
  .navbar-expand-lg .dropdown-submenu .dropdown-menu, .navbar-expand-lg .dropdown-submenu.dropdown-submenu-left .dropdown-menu {
    margin: 0;
    border-width: 0;
    box-shadow: none;
    border-radius: 0; }
  .navbar-expand-lg .dropdown-submenu > .dropdown-menu > .dropdown-item,
  .navbar-expand-lg .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
    padding-left: 2rem; }
  .navbar-expand-lg .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-item,
  .navbar-expand-lg .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
    padding-left: 3rem; }
  .navbar-expand-lg.fixed-top .navbar-collapse,
  .navbar-expand-lg.fixed-bottom .navbar-collapse {
    max-height: 440px;
    overflow-y: auto; } }

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-brand {
    min-width: 15.625rem; }
  .navbar-expand-lg .navbar-nav-link:before {
    top: auto;
    right: 0;
    width: auto;
    height: 2px; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-nav:first-child > .nav-item:first-child {
    margin-top: 0.625rem; }
  .navbar-expand-xl .navbar-nav:last-child > .nav-item:not(.show):last-child {
    margin-bottom: 0.625rem; }
  .navbar-expand-xl .navbar-nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
    .navbar-expand-xl .navbar-nav-link .badge:not(.position-static) {
      position: static; }
    .navbar-expand-xl .navbar-nav-link.dropdown-toggle:not(.caret-0) {
      padding-right: 2.5rem; }
      .navbar-expand-xl .navbar-nav-link.dropdown-toggle:not(.caret-0):after {
        position: absolute;
        top: 50%;
        right: 1.25rem;
        margin: 0;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); }
  .navbar-expand-xl.navbar-dark .navbar-collapse {
    border-top: 1px solid rgba(255, 255, 255, 0.1); }
  .navbar-expand-xl.navbar-light .navbar-collapse {
    border-top: 1px solid #ddd; }
  .navbar-expand-xl .navbar-nav > .nav-item > .dropdown-menu {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .navbar-expand-xl .dropdown-submenu .dropdown-toggle:after {
    content: '\e9c5'; }
  .navbar-expand-xl .dropdown-submenu .dropdown-menu, .navbar-expand-xl .dropdown-submenu.dropdown-submenu-left .dropdown-menu {
    margin: 0;
    border-width: 0;
    box-shadow: none;
    border-radius: 0; }
  .navbar-expand-xl .dropdown-submenu > .dropdown-menu > .dropdown-item,
  .navbar-expand-xl .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
    padding-left: 2rem; }
  .navbar-expand-xl .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-item,
  .navbar-expand-xl .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
    padding-left: 3rem; }
  .navbar-expand-xl.fixed-top .navbar-collapse,
  .navbar-expand-xl.fixed-bottom .navbar-collapse {
    max-height: 440px;
    overflow-y: auto; } }

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-brand {
    min-width: 15.625rem; }
  .navbar-expand-xl .navbar-nav-link:before {
    top: auto;
    right: 0;
    width: auto;
    height: 2px; } }

.navbar-expand .navbar-nav:first-child > .nav-item:first-child {
  margin-top: 0.625rem; }

.navbar-expand .navbar-nav:last-child > .nav-item:not(.show):last-child {
  margin-bottom: 0.625rem; }

.navbar-expand .navbar-nav-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem; }
  .navbar-expand .navbar-nav-link .badge:not(.position-static) {
    position: static; }
  .navbar-expand .navbar-nav-link.dropdown-toggle:not(.caret-0) {
    padding-right: 2.5rem; }
    .navbar-expand .navbar-nav-link.dropdown-toggle:not(.caret-0):after {
      position: absolute;
      top: 50%;
      right: 1.25rem;
      margin: 0;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }

.navbar-expand.navbar-dark .navbar-collapse {
  border-top: 1px solid rgba(255, 255, 255, 0.1); }

.navbar-expand.navbar-light .navbar-collapse {
  border-top: 1px solid #ddd; }

.navbar-expand .navbar-nav > .nav-item > .dropdown-menu {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }

.navbar-expand .dropdown-submenu .dropdown-toggle:after {
  content: '\e9c5'; }

.navbar-expand .dropdown-submenu .dropdown-menu, .navbar-expand .dropdown-submenu.dropdown-submenu-left .dropdown-menu {
  margin: 0;
  border-width: 0;
  box-shadow: none;
  border-radius: 0; }

.navbar-expand .dropdown-submenu > .dropdown-menu > .dropdown-item,
.navbar-expand .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
  padding-left: 2rem; }

.navbar-expand .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-item,
.navbar-expand .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-menu > .dropdown-submenu > .dropdown-item {
  padding-left: 3rem; }

.navbar-expand.fixed-top .navbar-collapse,
.navbar-expand.fixed-bottom .navbar-collapse {
  max-height: 440px;
  overflow-y: auto; }

.navbar-expand .navbar-brand {
  min-width: 15.625rem; }

.navbar-expand .navbar-nav-link:before {
  top: auto;
  right: 0;
  width: auto;
  height: 2px; }

.navbar-light {
  color: rgba(51, 51, 51, 0.85);
  background-color: #fff;
  border-bottom-color: rgba(0, 0, 0, 0.125); }
  .page-header-content + .navbar-light,
  .content + .navbar-light,
  .page-content + .navbar-light,
  .fixed-bottom > .navbar-light:first-child {
    border-top-color: rgba(0, 0, 0, 0.125); }
  .navbar-light .navbar-nav .nav-link {
    color: #333; }
    .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
      color: #333; }
    .navbar-light .navbar-nav .nav-link.disabled {
      color: #999; }
  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .nav-link.active {
    color: #333; }
  .navbar-light .navbar-nav-link {
    color: rgba(51, 51, 51, 0.85); }
    .navbar-light .navbar-nav-link:hover, .navbar-light .navbar-nav-link:focus {
      color: #333;
      background-color: rgba(0, 0, 0, 0.04); }
    .navbar-light .navbar-nav-link.disabled {
      color: rgba(0, 0, 0, 0.5);
      background-color: transparent; }
  .navbar-light .show > .navbar-nav-link,
  .navbar-light .active > .navbar-nav-link,
  .navbar-light .navbar-nav-link.show,
  .navbar-light .navbar-nav-link.active {
    color: #333;
    background-color: rgba(0, 0, 0, 0.04); }
  .navbar-light .navbar-text a {
    color: #2196F3; }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: #0a6ebd; }

.navbar-dark {
  color: rgba(255, 255, 255, 0.9);
  background-color: #293a50;
  border-bottom-color: rgba(255, 255, 255, 0.1); }
  .page-header-content + .navbar-dark,
  .content + .navbar-dark,
  .page-content + .navbar-dark,
  .fixed-bottom > .navbar-dark:first-child {
    border-top-color: rgba(255, 255, 255, 0.1); }
  .navbar-dark .navbar-nav .nav-link {
    color: #333; }
    .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
      color: #333; }
    .navbar-dark .navbar-nav .nav-link.disabled {
      color: #999; }
  .navbar-dark .navbar-nav .show > .nav-link,
  .navbar-dark .navbar-nav .active > .nav-link,
  .navbar-dark .navbar-nav .nav-link.show,
  .navbar-dark .navbar-nav .nav-link.active {
    color: #333; }
  .navbar-dark .navbar-nav-link {
    color: rgba(255, 255, 255, 0.9); }
    .navbar-dark .navbar-nav-link:hover, .navbar-dark .navbar-nav-link:focus {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.15); }
    .navbar-dark .navbar-nav-link.disabled {
      color: rgba(255, 255, 255, 0.5);
      background-color: transparent; }
  .navbar-dark .show > .navbar-nav-link,
  .navbar-dark .active > .navbar-nav-link,
  .navbar-dark .navbar-nav-link.show,
  .navbar-dark .navbar-nav-link.active {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.15); }

.navbar-nav .nav-tabs .nav-link {
  color: #777; }
  .navbar-nav .nav-tabs .nav-link:hover, .navbar-nav .nav-tabs .nav-link:focus {
    color: #333; }
  .navbar-nav .nav-tabs .nav-link.disabled {
    color: #999; }

.navbar-nav .nav-tabs .show > .nav-link,
.navbar-nav .nav-tabs .active > .nav-link,
.navbar-nav .nav-tabs .nav-link.show,
.navbar-nav .nav-tabs .nav-link.active {
  color: #333; }

.navbar-nav .nav-pills .nav-link {
  color: #777; }
  .navbar-nav .nav-pills .nav-link:not(.active):hover, .navbar-nav .nav-pills .nav-link:not(.active):focus {
    color: #333; }
  .navbar-nav .nav-pills .nav-link.active .badge:not(.badge-mark) {
    background-color: #fff !important;
    color: #333 !important; }
  .navbar-nav .nav-pills .nav-link.active .badge-mark[class*=bg-] {
    background-color: #fff !important;
    border-color: #fff !important; }
  .navbar-nav .nav-pills .nav-link.active .badge-mark[class*=border-] {
    border-color: #fff !important; }
  .navbar-nav .nav-pills .nav-link.active,
  .show:not(.navbar-collapse) > .navbar-nav .nav-pills .nav-link {
    color: #fff; }
  .navbar-nav .nav-pills .nav-link.disabled:hover, .navbar-nav .nav-pills .nav-link.disabled:focus {
    color: #999; }

.navbar .btn-group .dropdown-menu-right {
  right: 0;
  left: auto; }

.dropdown-user .navbar-nav-link,
.dropdown-user .navbar-nav-link > span {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem; }

.dropdown-user .navbar-nav-link > span {
  display: inline-block;
  padding-left: 0.625rem; }

.dropdown-user .navbar-nav-link > img {
  max-height: 2.12503rem;
  vertical-align: top; }

.img-flag {
  height: 0.875rem;
  margin-top: 0.18752rem;
  vertical-align: top;
  -ms-flex-item-align: start;
      align-self: flex-start; }

.mega-menu-left > .dropdown-menu {
  /*rtl:ignore*/
  left: auto; }

.mega-menu-right > .dropdown-menu {
  left: auto;
  right: 0; }

.mega-menu-full > .dropdown-menu {
  left: 1.25rem;
  right: 1.25rem; }
  .navbar-component .mega-menu-full > .dropdown-menu {
    left: 0;
    right: 0; }

.dropdown-content-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem; }
  .dropdown-content-header:not([class*=bg-]) {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .dropdown-content-header[class*=bg-] {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .dropdown-content-header + .table-responsive,
  .dropdown-content-header + .table {
    border-top: 1px solid #ddd; }

.dropdown-content:not(ul) {
  padding-top: 0;
  padding-bottom: 0; }

.dropdown-content-body {
  padding: 1.25rem 1.25rem; }
  .dropdown-content-header:not([class*=bg-]) + .dropdown-content-body {
    padding-top: 0; }

.dropdown-content-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  padding: 0.75rem 1.25rem;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem; }

.navbar-top {
  padding-top: 3.12503rem; }

.navbar-lg-top {
  padding-top: 3.37503rem; }

.navbar-sm-top {
  padding-top: 2.87503rem; }

.navbar-lg-md-top {
  padding-top: 6.50006rem; }

.navbar-md-md-top {
  padding-top: 6.25006rem; }

.navbar-sm-md-top {
  padding-top: 6.00006rem; }

.navbar-lg-sm-top {
  padding-top: 6.25006rem; }

.navbar-bottom {
  padding-bottom: 3.12503rem; }

.navbar-lg-md-bottom {
  padding-bottom: 6.50006rem; }

.navbar-md-md-bottom {
  padding-bottom: 6.25006rem; }

.navbar-sm-md-bottom {
  padding-bottom: 6.00006rem; }

.navbar-lg-sm-bottom {
  padding-bottom: 6.25006rem; }

.navbar-lg .navbar-brand {
  padding-top: 1.12502rem;
  padding-bottom: 1.12502rem; }

.navbar-lg .navbar-nav-link {
  padding: 1rem 1.25rem; }
  .navbar-lg .navbar-nav-link .badge-mark {
    top: 0.5rem;
    right: 0.625rem; }

.navbar-lg .navbar-text {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.navbar-lg .dropdown-user > .navbar-nav-link,
.navbar-lg .dropdown-user > .navbar-nav-link > span {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-lg .dropdown-user > .navbar-nav-link > img {
  max-height: 2.25003rem; }

.navbar-sm .navbar-brand {
  padding-top: 0.87502rem;
  padding-bottom: 0.87502rem; }

.navbar-sm .navbar-nav-link {
  padding: 0.75rem 0.875rem; }
  .navbar-sm .navbar-nav-link .badge-mark {
    top: 0.375rem;
    right: 0.4375rem; }

.navbar-sm .navbar-text {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; }

.navbar-sm .dropdown-user > .navbar-nav-link,
.navbar-sm .dropdown-user > .navbar-nav-link > span {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem; }

.navbar-sm .dropdown-user > .navbar-nav-link > img {
  max-height: 2.00003rem; }

.navbar-header {
  margin-top: -1px;
  margin-bottom: -1px;
  margin-left: -1.25rem;
  margin-right: 1.25rem;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent; }
  .navbar-header.navbar-dark {
    border-bottom-color: rgba(255, 255, 255, 0.1); }
  .navbar-header.navbar-light {
    border-bottom-color: rgba(0, 0, 0, 0.125); }
  .navbar-header .navbar-brand {
    margin-left: 1.25rem;
    margin-right: 0; }
  .navbar-header .navbar-brand-xs {
    display: none; }
  .sidebar-xs .navbar-header .navbar-brand-md {
    display: none; }
  .sidebar-xs .navbar-header .navbar-brand-xs {
    display: inline-block;
    min-width: 3.5rem;
    margin-left: 0;
    text-align: center; }

.navbar-static {
  z-index: 100; }

/* ------------------------------------------------------------------------------
 *
 *  # Card component
 *
 *  Overrides for card bootstrap component
 *
 * ---------------------------------------------------------------------------- */
.card {
  margin-bottom: 1.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
  .card.fixed-top {
    overflow: auto;
    max-height: 100%; }

.card-body:not(.card) + .card-body:not(.card) {
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-title {
  position: relative; }

.card-header .card-title {
  margin-bottom: 0; }

.card[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .card-header {
  border-bottom-color: rgba(255, 255, 255, 0.1); }

.card-header:not([class*=bg-]):not([class*=alpha-]) {
  background-color: transparent;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom-width: 0; }
  .card-header:not([class*=bg-]):not([class*=alpha-]) + .card-body,
  .card-header:not([class*=bg-]):not([class*=alpha-]) + * > .card-body:first-child {
    padding-top: 0; }

.card-footer:first-child,
.card-header + .card-footer,
.card-header + * > .card-footer:first-child {
  border-top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.card-footer.border-bottom-1 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.card-header-tabs {
  margin-bottom: 0; }
  .card-header-tabs .nav-link {
    border-top-width: 0; }

.card-body .card-img {
  border-radius: 0.1875rem; }

.card-img-actions {
  position: relative; }
  .card-img-actions:hover .card-img-actions-overlay, .card-img-actions:focus .card-img-actions-overlay {
    opacity: 1;
    visibility: visible; }

.card-img-actions-overlay {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .card-img-actions-overlay {
      transition: none; } }

[data-action] {
  cursor: pointer; }
  [data-action]:after {
    font-family: "icomoon";
    font-size: 1rem;
    min-width: 1rem;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

[data-action="collapse"]:after {
  content: '\e9c1'; }

[data-action="reload"]:after {
  content: '\e9fb'; }

[data-action="remove"]:after {
  content: '\ed6a';
  font-size: 0.8125rem; }

[data-action="move"] {
  cursor: default; }
  [data-action="move"]:after {
    content: '\e986'; }

[data-action="fullscreen"]:after {
  content: '\e9f9'; }

[data-action="fullscreen"][data-fullscreen="active"]:after {
  content: '\e9fa'; }

[data-action="modal"]:after {
  content: '\e9eb'; }

.card-group-control .card-title > a {
  display: inline-block; }
  .card-group-control .card-title > a:before {
    content: '\ed5b';
    font-family: "icomoon";
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .card-group-control .card-title > a.collapsed:before {
    content: '\ed5a'; }

.card-group-control-left .card-title > a {
  padding-left: 1.625rem; }
  .card-group-control-left .card-title > a:before {
    left: 0; }

.card-group-control-right .card-title > a {
  padding-right: 1.625rem; }
  .card-group-control-right .card-title > a:before {
    right: 0; }

/* ------------------------------------------------------------------------------
 *
 *  # Breadcrumb component
 *
 *  Overrides for breadcrumb bootstrap component
 *
 * ---------------------------------------------------------------------------- */
.breadcrumb {
  padding: 0; }

.breadcrumb-item {
  padding: 0.625rem 0;
  color: inherit; }
  .breadcrumb-item i {
    font-size: 0.75rem; }

a.breadcrumb-item, a.breadcrumb-elements-item {
  display: inline-block;
  color: inherit;
  transition: opacity ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    a.breadcrumb-item, a.breadcrumb-elements-item {
      transition: none; } }
  a.breadcrumb-item:hover, a.breadcrumb-elements-item:hover, a.breadcrumb-item:focus, a.breadcrumb-elements-item:focus,
  .show > a.breadcrumb-item,
  .show > a.breadcrumb-elements-item {
    opacity: 0.75; }

.page-header-content > .breadcrumb {
  padding-top: 0;
  padding-bottom: 1.25rem; }
  .page-header-content > .breadcrumb:first-child {
    padding-bottom: 0;
    padding-top: 1.25rem; }

.breadcrumb-dash > .breadcrumb-item + .breadcrumb-item:before {
  content: '\2013\00a0'; }

.breadcrumb-arrow > .breadcrumb-item + .breadcrumb-item:before {
  content: '\2192\00a0'; }

.breadcrumb-arrows > .breadcrumb-item + .breadcrumb-item:before {
  content: '\00bb\00a0'; }

.breadcrumb-caret > .breadcrumb-item + .breadcrumb-item:before {
  content: '\203A\00a0'; }

.breadcrumb-line {
  position: relative;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent; }
  .breadcrumb-line:not(.breadcrumb-line-component):first-child {
    border-top-width: 0; }
  .breadcrumb-line:last-child {
    margin-bottom: 1.25rem; }
    .page-header-light .breadcrumb-line:last-child,
    .page-header-dark .breadcrumb-line:last-child {
      margin-bottom: 0; }

.breadcrumb-line-light {
  background-color: #fff;
  border-color: #ddd;
  color: #333; }
  .page-header-light .breadcrumb-line-light:not(.breadcrumb-line-component):last-child {
    border-bottom-width: 0; }
  .page-header-dark .breadcrumb-line-light:not(.breadcrumb-line-component) {
    border-top-width: 0; }
    .page-header-dark .breadcrumb-line-light:not(.breadcrumb-line-component):first-child {
      border-bottom-width: 0; }
  .breadcrumb-line-light.breadcrumb-line-component {
    border-color: #ddd; }

.breadcrumb-line-dark {
  background-color: #273246;
  border-color: transparent;
  color: #fff; }
  .page-header-dark .breadcrumb-line-dark:not(.breadcrumb-line-component):last-child {
    border-bottom-width: 0; }
  .breadcrumb-line-dark .breadcrumb-item.active {
    color: inherit; }
  .page-header-dark .breadcrumb-line-dark {
    border-color: rgba(255, 255, 255, 0.25); }

.breadcrumb-line-component {
  border: 1px solid transparent;
  border-radius: 0.1875rem; }
  .page-header .breadcrumb-line-component {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }

.breadcrumb-elements-item {
  padding: 0.625rem 0; }
  .breadcrumb-elements-item + .breadcrumb-elements-item {
    margin-left: 1.25rem; }

/* ------------------------------------------------------------------------------
 *
 *  # Pagination component
 *
 *  Overrides for pagination component
 *
 * ---------------------------------------------------------------------------- */
.pagination {
  margin-bottom: 0; }

.page-link {
  text-align: center;
  min-width: 2.25003rem;
  transition: all ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }

.pagination-lg .page-link {
  min-width: 2.50002rem; }

.pagination-sm .page-link {
  min-width: 2.00002rem; }

.pagination-rounded .page-item:first-child .page-link {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px; }

.pagination-rounded .page-item:last-child .page-link {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px; }

.pagination-flat .page-item.active .page-link {
  background-color: #2196F3; }

.pagination-flat .page-item.disabled .page-link {
  border-color: transparent; }

.pagination-flat .page-link {
  background-color: transparent;
  border-color: transparent;
  margin-left: 2px;
  border-radius: 0.1875rem; }
  .pagination-flat .page-link:hover, .pagination-flat .page-link:focus {
    background-color: #f5f5f5; }

.pagination-flat.pagination-rounded .page-link {
  border-radius: 100px; }

.pagination-separated .page-link {
  margin-left: 0.625rem;
  border-radius: 0.1875rem; }

.pagination-separated.pagination-rounded .page-link {
  border-radius: 100px; }

.pagination-pager .page-item {
  border-radius: 0.1875rem; }

.pagination-pager .page-link {
  margin-left: 1.25rem;
  border-radius: 0.1875rem; }

.pagination-pager.pagination-rounded .page-link {
  border-radius: 100px; }

.pagination-pager-linked .page-item.disabled .page-link {
  background-color: transparent;
  border-color: transparent; }

.pagination-pager-linked .page-link {
  color: #2196F3;
  background-color: transparent;
  border-color: transparent; }
  .pagination-pager-linked .page-link:hover, .pagination-pager-linked .page-link:focus {
    color: #fff;
    background-color: #2196F3;
    border-color: #2196F3; }

.pagination-pager:not(.pagination-rounded).pagination-lg .page-link {
  border-radius: 0.25rem; }

.pagination-pager:not(.pagination-rounded).pagination-sm .page-link {
  border-radius: 0.125rem; }

/* ------------------------------------------------------------------------------
 *
 *  # Badge component
 *
 *  Overrides for badge bootstrap component
 *
 * ---------------------------------------------------------------------------- */
.badge.dropdown-toggle:after {
  font-size: 85%;
  vertical-align: baseline; }

.badge-light {
  background-color: #f5f5f5; }

a.badge {
  transition: all ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    a.badge {
      transition: none; } }
  a.badge[class*=bg-]:not(.bg-transparent):not(.bg-white):not(.bg-light):hover, a.badge[class*=bg-]:not(.bg-transparent):not(.bg-white):not(.bg-light):focus,
  .show > a.badge[class*=bg-]:not(.bg-transparent):not(.bg-white):not(.bg-light).dropdown-toggle {
    box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.075) inset; }

.btn .badge {
  top: 0; }

.badge-flat {
  background-color: transparent;
  border: 1px solid transparent;
  padding: calc(0.3125rem - 1px) calc(0.375rem - 1px); }
  .badge-flat[href]:hover, .badge-flat[href]:focus {
    background-color: transparent;
    box-shadow: none; }
  .badge-flat.badge-pill {
    padding-left: calc(0.4375rem - 1px);
    padding-right: calc(0.4375rem - 1px); }

.badge-striped {
  padding: 0.4375rem 0.5rem;
  position: relative; }
  .badge-striped:before {
    content: '';
    width: 0;
    position: absolute;
    top: 0;
    bottom: 0; }
  .badge-striped-left {
    padding-left: calc(0.375rem + 2px);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
    .badge-striped-left:before {
      left: 0;
      border-left: 2px solid;
      border-left-color: inherit; }
  .badge-striped-right {
    padding-right: calc(0.375rem + 2px);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
    .badge-striped-right:before {
      right: 0;
      border-right: 2px solid;
      border-right-color: inherit; }

.badge-icon {
  padding: 0.375rem;
  line-height: 1; }
  .badge-icon > i {
    top: 0; }

.badge-float {
  position: absolute;
  right: -0.5rem;
  top: -0.5rem; }

.badge-mark {
  padding: 0;
  border: 2px solid;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100px; }
  .badge-mark:empty {
    display: inline-block; }

/* ------------------------------------------------------------------------------
 *
 *  # Alert component
 *
 *  Overrides for alert bootstrap component
 *
 * ---------------------------------------------------------------------------- */
.alert-dismissible .close:hover, .alert-dismissible .close:focus {
  color: inherit; }

.alert[class*=bg-]:not(.bg-transparent):not(.bg-white):not(.bg-light) .alert-link {
  color: inherit;
  border-bottom-color: rgba(255, 255, 255, 0.85); }

.alert-rounded {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  border-radius: 100px; }
  .alert-rounded.alert-dismissible .close {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px; }

.alert[class*=alert-styled-]:after {
  content: '\e9a2';
  font-family: "icomoon";
  color: #fff;
  width: 2.875rem;
  left: -2.875rem;
  text-align: center;
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.alert[class*=alert-styled-].alert-danger:after, .alert[class*=alert-styled-][class*=bg-danger]:after {
  content: '\ed64'; }

.alert[class*=alert-styled-].alert-success:after, .alert[class*=alert-styled-][class*=bg-success]:after {
  content: '\ed6e'; }

.alert[class*=alert-styled-].alert-warning:after, .alert[class*=alert-styled-][class*=bg-warning]:after {
  content: '\e9bd'; }

.alert[class*=alert-styled-].alert-info:after, .alert[class*=alert-styled-][class*=bg-info]:after {
  content: '\e9b9'; }

.alert.alert-styled-left {
  border-left-width: 2.875rem; }
  .alert.alert-styled-left[class*=bg-]:not(.bg-white):not(.bg-light) {
    border-left-color: rgba(0, 0, 0, 0.15) !important; }

.alert.alert-styled-right {
  border-right-width: 2.875rem; }
  .alert.alert-styled-right:after {
    left: auto;
    right: -2.875rem; }
  .alert.alert-styled-right[class*=bg-]:not(.bg-white):not(.bg-light) {
    border-right-color: rgba(0, 0, 0, 0.15) !important; }

.alert.alert-styled-custom:after {
  content: "\e81b"; }

.alert:not(.ui-pnotify)[class*=alert-arrow-]:before,
.alert.ui-pnotify[class*=alert-arrow-] > .brighttheme:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  border-left: 5px solid;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left-color: inherit;
  margin-top: -5px; }

.alert:not(.ui-pnotify).alert-arrow-right:before,
.alert.ui-pnotify.alert-arrow-right > .brighttheme:before {
  left: auto;
  right: 0;
  border-left: 0;
  border-right: 5px solid;
  border-right-color: inherit; }

/* ------------------------------------------------------------------------------
 *
 *  # Progress bar component
 *
 *  Overrides for progress bar component
 *
 * ---------------------------------------------------------------------------- */
.progress {
  position: relative; }

.progress-bar {
  width: 0;
  overflow: hidden;
  white-space: nowrap; }

.progress .progressbar-back-text,
.progress .progressbar-front-text {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
  font-size: 0.60938rem; }

.progress .progressbar-back-text {
  -ms-flex-item-align: center;
      align-self: center; }

.progress.right {
  -ms-flex-pack: end;
      justify-content: flex-end; }

.progress.vertical {
  width: 3rem;
  height: 100%;
  display: inline-block; }
  .progress.vertical + .progress.vertical {
    margin-left: 0.625rem; }
  .progress.vertical .progress-bar {
    width: 100%;
    height: 0;
    transition: height 0.6s ease; }
    @media screen and (prefers-reduced-motion: reduce) {
      .progress.vertical .progress-bar {
        transition: none; } }
  .progress.vertical.bottom .progressbar-back-text,
  .progress.vertical.bottom .progressbar-front-text {
    top: auto;
    bottom: 0; }
  .progress.vertical.bottom .progress-bar {
    position: absolute;
    bottom: 0; }

/* ------------------------------------------------------------------------------
 *
 *  # Media object component
 *
 *  Overrides for media object component
 *
 * ---------------------------------------------------------------------------- */
.media {
  margin-top: 1.25rem; }
  .media.card-body, .media:first-child {
    margin-top: 0; }

.media-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0; }

.media-title {
  margin-bottom: 0.125rem; }

.img-preview {
  max-height: 5rem; }

.media-list-bordered .media {
  margin-top: 0;
  padding: 1rem 1.25rem;
  border-top: 1px solid #ddd; }

.media-list-linked .media {
  padding: 1rem 1.25rem; }

.media-list-linked li.media {
  margin-top: 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd; }

.media-list-linked.media-list-bordered li.media {
  border-bottom: 0; }

.media-list-linked a.media {
  color: inherit;
  transition: all ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .media-list-linked a.media {
      transition: none; } }
  .media-list-linked a.media:hover {
    background-color: rgba(0, 0, 0, 0.04); }

/* ------------------------------------------------------------------------------
 *
 *  # List group component
 *
 *  Overrides for list group component
 *
 * ---------------------------------------------------------------------------- */
.list-group {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.5rem 0;
  background-clip: padding-box;
  border-radius: 0.1875rem; }

.list-group-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 0;
  border-width: 0;
  background-color: transparent;
  transition: all ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .list-group-item {
      transition: none; } }
  .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-item.active .badge:not(.badge-mark) {
    background-color: #fff !important;
    color: #333 !important; }
  .list-group-item.active .badge-mark[class*=bg-] {
    background-color: #fff !important;
    border-color: #fff !important; }
  .list-group-item.active .badge-mark[class*=border-] {
    border-color: #fff !important; }
  .list-group-item.disabled, .list-group-item:disabled {
    cursor: default; }

.list-group-flush {
  border: 0;
  border-radius: 0;
  box-shadow: none; }

.list-group-divider {
  padding: 0;
  margin: 0.5rem 0;
  border-top-width: 1px; }

.list-group-bordered {
  padding: 0; }
  .list-group-bordered .list-group-item {
    border-top-width: 1px;
    border-bottom-width: 1px;
    margin-bottom: -1px; }
    .list-group-bordered .list-group-item:first-child {
      border-top-width: 0;
      border-top-left-radius: 0.1875rem;
      border-top-right-radius: 0.1875rem; }
    .list-group-bordered .list-group-item:last-child {
      border-bottom-right-radius: 0.1875rem;
      border-bottom-left-radius: 0.1875rem; }
  .list-group-bordered[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .list-group-item {
    border-top-color: rgba(255, 255, 255, 0.1);
    border-bottom-color: rgba(255, 255, 255, 0.1); }

.list-group[class*=bg-]:not(.bg-light):not(.bg-transparent):not(.bg-white) {
  border-radius: 0.1875rem; }
  .list-group[class*=bg-]:not(.bg-light):not(.bg-transparent):not(.bg-white) .list-group-item-action:not(.active):not(.disabled) {
    color: rgba(255, 255, 255, 0.9); }
    .list-group[class*=bg-]:not(.bg-light):not(.bg-transparent):not(.bg-white) .list-group-item-action:not(.active):not(.disabled):hover, .list-group[class*=bg-]:not(.bg-light):not(.bg-transparent):not(.bg-white) .list-group-item-action:not(.active):not(.disabled):focus {
      background-color: rgba(0, 0, 0, 0.1);
      color: #fff; }
  .list-group[class*=bg-]:not(.bg-light):not(.bg-transparent):not(.bg-white) .list-group-item {
    color: #fff; }
    .list-group[class*=bg-]:not(.bg-light):not(.bg-transparent):not(.bg-white) .list-group-item.active {
      background-color: rgba(0, 0, 0, 0.2); }
    .list-group[class*=bg-]:not(.bg-light):not(.bg-transparent):not(.bg-white) .list-group-item.disabled, .list-group[class*=bg-]:not(.bg-light):not(.bg-transparent):not(.bg-white) .list-group-item:disabled {
      color: rgba(255, 255, 255, 0.6);
      background-color: transparent; }
  .list-group[class*=bg-]:not(.bg-light):not(.bg-transparent):not(.bg-white) .list-group-divider {
    border-top-color: rgba(255, 255, 255, 0.4); }

.list-group-item-primary {
  color: #114e7e;
  background-color: #E3F2FD; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #114e7e;
    background-color: #cbe7fb; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #114e7e;
    border-color: #114e7e; }

.list-group-item-secondary {
  color: #3e3e3e;
  background-color: #eee; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #3e3e3e;
    background-color: #e1e1e1; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #3e3e3e;
    border-color: #3e3e3e; }

.list-group-item-success {
  color: #285b2a;
  background-color: #E8F5E9; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #285b2a;
    background-color: #d6edd8; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #285b2a;
    border-color: #285b2a; }

.list-group-item-info {
  color: #00626e;
  background-color: #E0F7FA; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #00626e;
    background-color: #caf1f6; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #00626e;
    border-color: #00626e; }

.list-group-item-warning {
  color: #853a23;
  background-color: #FFF3E0; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #853a23;
    background-color: #ffe9c7; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #853a23;
    border-color: #853a23; }

.list-group-item-danger {
  color: #7f231c;
  background-color: #FFEBEE; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7f231c;
    background-color: #ffd2d8; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7f231c;
    border-color: #7f231c; }

.list-group-item-light {
  color: #828282;
  background-color: #f5f5f5; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #828282;
    background-color: #e8e8e8; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #828282;
    border-color: #828282; }

.list-group-item-dark {
  color: #151e2a;
  background-color: #ECEFF1; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #151e2a;
    background-color: #dde3e6; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #151e2a;
    border-color: #151e2a; }

/* ------------------------------------------------------------------------------
 *
 *  # Close button
 *
 *  Overrides of default close button styles
 *
 * ---------------------------------------------------------------------------- */
.close {
  cursor: pointer;
  opacity: .75;
  transition: color ease-in-out 0.15s, opacity ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .close {
      transition: none; } }
  .close:hover, .close:focus {
    outline: 0;
    opacity: 1; }

/* ------------------------------------------------------------------------------
 *
 *  # Modal component
 *
 *  Overrides for modal dialog component
 *
 * ---------------------------------------------------------------------------- */
.modal-header {
  position: relative;
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem; }
  .modal-header .close {
    color: inherit; }
  .modal-header:not([class*=bg-]) {
    padding-bottom: 0;
    border-bottom-width: 0; }

.modal-footer:not([class*=bg-]) {
  padding-top: 0;
  border-top-width: 0; }

[class*=table-responsive] + .modal-footer,
.table + .modal-footer {
  border-top-width: 1px;
  border-top-color: #ddd; }

@media (min-width: 576px) {
  .modal-xs {
    max-width: 300px; }
  .modal-full {
    max-width: 94%;
    margin-left: 3%;
    margin-right: 3%; } }

/* ------------------------------------------------------------------------------
 *
 *  # Tooltip component
 *
 *  Override and extend default tooltip styles.
 *
 * ---------------------------------------------------------------------------- */
.bs-tooltip-top .arrow[class*=border-]:before, .bs-tooltip-auto[x-placement^="top"] .arrow[class*=border-]:before {
  border-top-color: inherit; }

.bs-tooltip-right .arrow[class*=border-]:before, .bs-tooltip-auto[x-placement^="right"] .arrow[class*=border-]:before {
  border-right-color: inherit; }

.bs-tooltip-bottom .arrow[class*=border-]:before, .bs-tooltip-auto[x-placement^="bottom"] .arrow[class*=border-]:before {
  border-bottom-color: inherit; }

.bs-tooltip-left .arrow[class*=border-]:before, .bs-tooltip-auto[x-placement^="left"] .arrow[class*=border-]:before {
  border-left-color: inherit; }

/* ------------------------------------------------------------------------------
 *
 *  # Popover component
 *
 *  Overrides for popover component
 *
 * ---------------------------------------------------------------------------- */
.popover-header {
  font-weight: 500;
  padding-bottom: 0;
  border-bottom-width: 0; }
  .popover-header[class*=bg-]:not(.bg-white):not(.bg-light) {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-bottom-width: 1px; }

.bs-popover-top[class*=bg-]:not(.bg-white):not(.bg-light) .arrow, .bs-popover-auto[class*=bg-]:not(.bg-white):not(.bg-light)[x-placement^="top"] .arrow, .bs-popover-top[class*=bg-]:not(.bg-white):not(.bg-light) .arrow:after, .bs-popover-auto[class*=bg-]:not(.bg-white):not(.bg-light)[x-placement^="top"] .arrow:after, .bs-popover-top[class*=bg-]:not(.bg-white):not(.bg-light) .arrow:before, .bs-popover-auto[class*=bg-]:not(.bg-white):not(.bg-light)[x-placement^="top"] .arrow:before, .bs-popover-top[class*=border-] .arrow, .bs-popover-auto[class*=border-][x-placement^="top"] .arrow, .bs-popover-top[class*=border-] .arrow:after, .bs-popover-auto[class*=border-][x-placement^="top"] .arrow:after, .bs-popover-top[class*=border-] .arrow:before, .bs-popover-auto[class*=border-][x-placement^="top"] .arrow:before {
  border-top-color: inherit; }

.bs-popover-right[class*=bg-]:not(.bg-white):not(.bg-light) .arrow, .bs-popover-auto[class*=bg-]:not(.bg-white):not(.bg-light)[x-placement^="right"] .arrow, .bs-popover-right[class*=bg-]:not(.bg-white):not(.bg-light) .arrow:after, .bs-popover-auto[class*=bg-]:not(.bg-white):not(.bg-light)[x-placement^="right"] .arrow:after, .bs-popover-right[class*=bg-]:not(.bg-white):not(.bg-light) .arrow:before, .bs-popover-auto[class*=bg-]:not(.bg-white):not(.bg-light)[x-placement^="right"] .arrow:before, .bs-popover-right[class*=border-] .arrow, .bs-popover-auto[class*=border-][x-placement^="right"] .arrow, .bs-popover-right[class*=border-] .arrow:after, .bs-popover-auto[class*=border-][x-placement^="right"] .arrow:after, .bs-popover-right[class*=border-] .arrow:before, .bs-popover-auto[class*=border-][x-placement^="right"] .arrow:before {
  border-right-color: inherit; }

.bs-popover-bottom[class*=bg-]:not(.bg-white):not(.bg-light) .arrow, .bs-popover-auto[class*=bg-]:not(.bg-white):not(.bg-light)[x-placement^="bottom"] .arrow, .bs-popover-bottom[class*=bg-]:not(.bg-white):not(.bg-light) .arrow:after, .bs-popover-auto[class*=bg-]:not(.bg-white):not(.bg-light)[x-placement^="bottom"] .arrow:after, .bs-popover-bottom[class*=bg-]:not(.bg-white):not(.bg-light) .arrow:before, .bs-popover-auto[class*=bg-]:not(.bg-white):not(.bg-light)[x-placement^="bottom"] .arrow:before, .bs-popover-bottom[class*=border-] .arrow, .bs-popover-auto[class*=border-][x-placement^="bottom"] .arrow, .bs-popover-bottom[class*=border-] .arrow:after, .bs-popover-auto[class*=border-][x-placement^="bottom"] .arrow:after, .bs-popover-bottom[class*=border-] .arrow:before, .bs-popover-auto[class*=border-][x-placement^="bottom"] .arrow:before {
  border-bottom-color: inherit; }

.bs-popover-left[class*=bg-]:not(.bg-white):not(.bg-light) .arrow, .bs-popover-auto[class*=bg-]:not(.bg-white):not(.bg-light)[x-placement^="left"] .arrow, .bs-popover-left[class*=bg-]:not(.bg-white):not(.bg-light) .arrow:after, .bs-popover-auto[class*=bg-]:not(.bg-white):not(.bg-light)[x-placement^="left"] .arrow:after, .bs-popover-left[class*=bg-]:not(.bg-white):not(.bg-light) .arrow:before, .bs-popover-auto[class*=bg-]:not(.bg-white):not(.bg-light)[x-placement^="left"] .arrow:before, .bs-popover-left[class*=border-] .arrow, .bs-popover-auto[class*=border-][x-placement^="left"] .arrow, .bs-popover-left[class*=border-] .arrow:after, .bs-popover-auto[class*=border-][x-placement^="left"] .arrow:after, .bs-popover-left[class*=border-] .arrow:before, .bs-popover-auto[class*=border-][x-placement^="left"] .arrow:before {
  border-left-color: inherit; }

/* ------------------------------------------------------------------------------
 *
 *  # Utilities
 *
 *  Import Bootstrap utilities with overrides
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Background utility
 *
 *  Override and extend default background utility.
 *
 * ---------------------------------------------------------------------------- */
[class*=bg-]:not(.bg-transparent):not(.bg-light):not(.bg-white):not(.btn-outline):not(body) {
  color: #fff; }

/* ------------------------------------------------------------------------------
 *
 *  # Core layout
 *
 *  Content area, sidebar, page header and boxed layout styles
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Global configuration
 *
 *  Here you can change main theme, enable or disable certain components and
 *  optional styles. This allows you to include only components that you need.
 *
 *  'true'  - enables component and includes it to main CSS file.
 *  'false' - disables component and excludes it from main CSS file.
 *
 *  Layout helper: @if $layout == 'base' {...}
 *  Theme helper: @if $theme == 'material' {...}
 *  Component helper: @if $enable-* {...}
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Custom Limitless functions
 *
 *  Utility mixins and functions for evalutating source code across our variables, maps, and mixins.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Custom template mixins
 *
 *  All custom mixins are prefixed with "ll-" to avoid conflicts
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Main colors
 *
 *  List of the template main color palettes
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Default Bootstrap variable overrides
 *
 *  Variables should follow the `$component-state-property-size` formula for
 *  consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
 *  Also includes custom variables, all marked with "!default" flag.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Additional variables
 *
 *  Mainly 3rd party libraries and additional variables for default
 *  Bootstrap components.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Mixins
 *
 *  Import Bootstrap mixins with overrides
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Badge mixin
 *
 *  Override and extend default badge mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Buttons mixin
 *
 *  Override and extend default buttons mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Caret mixin
 *
 *  Override and extend default cared mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Forms mixin
 *
 *  Override and extend default forms mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Background mixin
 *
 *  Override and extend default background mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Main content layout
 *
 *  Styles for main structure of content area
 *
 * ---------------------------------------------------------------------------- */
html {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

body {
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 1;
      flex: 1; }

.page-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1;
  padding: 1.25rem 0.625rem; }

.content-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 1;
      flex: 1;
  overflow: auto; }

.content {
  -ms-flex-positive: 1;
      flex-grow: 1;
  margin-left: 0.625rem;
  margin-right: 0.625rem; }
  .content::after {
    display: block;
    clear: both;
    content: ""; }

/* ------------------------------------------------------------------------------
 *
 *  # Page header
 *
 *  Page header components and color options
 *
 * ---------------------------------------------------------------------------- */
.page-title {
  padding: 2rem 0;
  position: relative; }
  .page-title small {
    display: inline-block;
    margin-left: 0.625rem; }
    .page-title small:before {
      content: '/';
      margin-right: 0.875rem; }
    .page-title small.d-block {
      margin-left: 0; }
      .page-title small.d-block:before {
        content: none; }
  .page-title small.d-block,
  .page-title .breadcrumb {
    margin-left: 1.875rem; }
  .page-title h1, .page-title h2, .page-title h3, .page-title h4, .page-title h5, .page-title h6 {
    margin: 0; }

.page-header-content {
  position: relative;
  padding: 0 1.25rem; }
  .page-header-content[class*=border-bottom-] + .breadcrumb-line {
    border-top: 0; }

.page-header-light {
  background-color: #fff;
  border-bottom: 1px solid #ddd; }
  .page-header-light.has-cover {
    background: url(../../../../../../global_assets/images/backgrounds/seamless.png); }

.page-header-dark {
  background-color: #273246;
  color: #fff;
  margin-bottom: 1.25rem; }
  .page-header-dark > .breadcrumb > li > a,
  .page-header-dark > .breadcrumb > li + li:before,
  .page-header-dark .page-header-content .breadcrumb > li > a,
  .page-header-dark .page-header-content .breadcrumb > li + li:before {
    color: rgba(255, 255, 255, 0.9); }
  .page-header-dark > .breadcrumb > li > a:hover, .page-header-dark > .breadcrumb > li > a:focus,
  .page-header-dark .page-header-content .breadcrumb > li > a:hover,
  .page-header-dark .page-header-content .breadcrumb > li > a:focus {
    color: #fff;
    opacity: 1; }
  .page-header-dark > .breadcrumb > .active,
  .page-header-dark .page-header-content .breadcrumb > .active {
    color: rgba(255, 255, 255, 0.5); }
  .page-header-dark.has-cover {
    background: url(../../../../../../global_assets/images/login_cover.jpg);
    background-size: cover; }

/* ------------------------------------------------------------------------------
 *
 *  # Sidebar layouts
 *
 *  Sidebar components, main navigation and sidebar itself
 *
 * ---------------------------------------------------------------------------- */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  box-sizing: content-box;
  border: 1px solid transparent;
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
  width: 16.875rem;
  z-index: 1040;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: all ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .sidebar {
      transition: none; } }
  .sidebar-main,
  .sidebar-main .sidebar-content, .sidebar-secondary,
  .sidebar-secondary .sidebar-content {
    left: -18.5625rem; }
  .sidebar-right,
  .sidebar-right .sidebar-content {
    right: -18.5625rem; }
  .sidebar.sidebar-fullscreen {
    width: 100%; }

.sidebar-content {
  position: fixed;
  top: 3.12503rem;
  bottom: 0;
  width: inherit;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  transition: left ease-in-out 0.15s, right ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .sidebar-content {
      transition: none; } }
  .navbar-lg:first-child ~ .page-content .sidebar-content {
    top: 3.37503rem; }
  .navbar-sm:first-child ~ .page-content .sidebar-content {
    top: 2.87503rem; }

.sidebar-mobile-main .sidebar-main {
  box-shadow: 0.25rem 0 1rem rgba(0, 0, 0, 0.35); }
  .sidebar-mobile-main .sidebar-main,
  .sidebar-mobile-main .sidebar-main .sidebar-content {
    left: 0; }

.sidebar-mobile-secondary .sidebar-secondary {
  box-shadow: 0.25rem 0 1rem rgba(0, 0, 0, 0.35); }
  .sidebar-mobile-secondary .sidebar-secondary,
  .sidebar-mobile-secondary .sidebar-secondary .sidebar-content {
    left: 0; }

.sidebar-mobile-right .sidebar-right {
  display: block;
  box-shadow: -0.25rem 0 1rem rgba(0, 0, 0, 0.35); }
  .sidebar-mobile-right .sidebar-right,
  .sidebar-mobile-right .sidebar-right .sidebar-content {
    right: 0; }

.nav-sidebar {
  -ms-flex-direction: column;
      flex-direction: column; }
  .nav-sidebar .nav-item:not(.nav-item-header):first-child {
    padding-top: 0.5rem; }
  .nav-sidebar .nav-item:not(.nav-item-header):last-child {
    padding-bottom: 0.5rem; }
  .nav-sidebar .nav-item:not(.nav-item-divider) {
    margin-bottom: 1px; }
    .nav-sidebar .nav-item:not(.nav-item-divider):last-child {
      margin-bottom: 0; }
  .nav-sidebar > .nav-item > .nav-link {
    font-weight: 500; }
  .nav-sidebar .nav-link {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
        align-items: flex-start;
    padding: 0.75rem 1.25rem;
    transition: background-color ease-in-out 0.15s, color ease-in-out 0.15s; }
    @media screen and (prefers-reduced-motion: reduce) {
      .nav-sidebar .nav-link {
        transition: none; } }
    .nav-sidebar .nav-link i {
      margin-right: 1.25rem;
      margin-top: 0.12502rem;
      margin-bottom: 0.12502rem;
      top: 0; }
    .nav-sidebar .nav-link .badge {
      transition: background-color ease-in-out 0.15s, border-color ease-in-out 0.15s; }
      @media screen and (prefers-reduced-motion: reduce) {
        .nav-sidebar .nav-link .badge {
          transition: none; } }
    .nav-sidebar .nav-link.disabled, .nav-sidebar .nav-link.disabled:hover, .nav-sidebar .nav-link.disabled:focus {
      background-color: transparent;
      opacity: 0.5; }
  .nav-sidebar .nav-item-header {
    padding: 0.75rem 1.25rem;
    margin-top: 0.5rem; }
    .nav-sidebar .nav-item-header > i {
      display: none; }
  .nav-sidebar .nav-item-divider {
    margin: 0.5rem 0;
    height: 1px; }

.nav-item-submenu > .nav-link {
  padding-right: 2.75rem; }
  .nav-item-submenu > .nav-link:after {
    content: '\e9c7';
    font-family: "icomoon";
    display: inline-block;
    font-size: 1rem;
    vertical-align: middle;
    line-height: 1;
    position: absolute;
    top: 0.75rem;
    margin-top: 0.12502rem;
    right: 1.25rem;
    transition: -webkit-transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
    @media screen and (prefers-reduced-motion: reduce) {
      .nav-item-submenu > .nav-link:after {
        transition: none; } }

.nav-item-submenu.nav-item-open > .nav-link:after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.nav-group-sub {
  display: none; }
  .nav-group-sub .nav-link {
    padding: 0.625rem 1.25rem 0.625rem 3.5rem; }
  .nav-group-sub .nav-group-sub .nav-link {
    padding-left: 4.75rem; }
  .nav-group-sub .nav-group-sub .nav-group-sub .nav-link {
    padding-left: 6rem; }
  .nav-group-sub .nav-item-submenu > .nav-link:after {
    top: 0.625rem; }
  .nav-item-expanded > .nav-group-sub {
    display: block; }

.nav-scrollspy .nav-link.active + .nav,
.nav-scrollspy .nav-item-open .nav-link:not(.active) + .nav {
  display: block; }

.nav-scrollspy .nav-link.active:after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.nav-sidebar-bordered > .nav-item + .nav-item:not(.nav-item-divider) {
  margin-bottom: 0; }

.sidebar .card:first-child .nav-sidebar-bordered {
  padding-top: 0; }
  .sidebar .card:first-child .nav-sidebar-bordered > .nav-item:first-child,
  .sidebar .card:first-child .nav-sidebar-bordered > .nav-item-header:first-child {
    border-top: 0; }

.sidebar .card:last-child .nav-sidebar-bordered {
  padding-bottom: 0; }
  .sidebar .card:last-child .nav-sidebar-bordered > .nav-item:last-child,
  .sidebar .card:last-child .nav-sidebar-bordered > .nav-item-header:last-child {
    border-bottom: 0; }

.sidebar-dark .nav-sidebar-bordered > .nav-item {
  border-top: 1px solid rgba(255, 255, 255, 0.1); }
  .sidebar-dark .nav-sidebar-bordered > .nav-item:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }

.sidebar-dark .nav-sidebar-bordered > .nav-item-header {
  background-color: rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin: 0; }

.sidebar-light .nav-sidebar-bordered > .nav-item {
  border-top: 1px solid #eee; }
  .sidebar-light .nav-sidebar-bordered > .nav-item:last-child {
    border-bottom: 1px solid #eee; }

.sidebar-light .nav-sidebar-bordered > .nav-item-header {
  background-color: #fafafa;
  border-top: 1px solid #eee;
  margin: 0; }

.sidebar-mobile-toggler {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent; }
  .sidebar-mobile-toggler a {
    padding: 0.875rem 1.25rem;
    transition: all ease-in-out 0.15s; }
    @media screen and (prefers-reduced-motion: reduce) {
      .sidebar-mobile-toggler a {
        transition: none; } }
    .navbar-lg:first-child ~ .page-content .sidebar-mobile-toggler a {
      padding: 1rem 1.25rem; }
    .navbar-sm:first-child ~ .page-content .sidebar-mobile-toggler a {
      padding: 0.75rem 1.25rem; }

.sidebar-mobile-expand i:last-child:not(:first-child) {
  display: none; }

.sidebar-fullscreen .sidebar-mobile-expand i:first-child {
  display: none; }

.sidebar-fullscreen .sidebar-mobile-expand i:last-child {
  display: inline-block; }

.sidebar-dark {
  background-color: #293a50;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.1); }
  .sidebar-dark .sidebar-mobile-toggler {
    color: rgba(255, 255, 255, 0.9);
    border-bottom-color: rgba(255, 255, 255, 0.1); }
    .sidebar-dark .sidebar-mobile-toggler:not([class*=bg-]) {
      background-color: #223043; }
    .sidebar-dark .sidebar-mobile-toggler a {
      color: rgba(255, 255, 255, 0.9); }
      .sidebar-dark .sidebar-mobile-toggler a:hover {
        color: #fff;
        background-color: transparent; }
  .sidebar-dark .card-header {
    border-color: rgba(255, 255, 255, 0.1); }

.sidebar-dark .nav-sidebar .nav-link,
.sidebar-light .card[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar .nav-link {
  color: rgba(255, 255, 255, 0.9); }
  .sidebar-dark .nav-sidebar .nav-link:not(.disabled):hover,
  .sidebar-light .card[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar .nav-link:not(.disabled):hover {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.15); }

.sidebar-dark .nav-sidebar .nav-item > .nav-link.active,
.sidebar-light .card[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar .nav-item > .nav-link.active {
  background-color: rgba(0, 0, 0, 0.15);
  color: #fff; }

.sidebar-dark .nav-sidebar .nav-item-open > .nav-link:not(.disabled),
.sidebar-dark .nav-sidebar > .nav-item-expanded:not(.nav-item-open) > .nav-link,
.sidebar-light .card[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar .nav-item-open > .nav-link:not(.disabled),
.sidebar-light .card[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar > .nav-item-expanded:not(.nav-item-open) > .nav-link {
  background-color: rgba(0, 0, 0, 0.15);
  color: #fff; }

.sidebar-dark .nav-sidebar > .nav-item-open > .nav-link:not(.disabled),
.sidebar-dark .nav-sidebar > .nav-item > .nav-link.active,
.sidebar-light .card[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar > .nav-item-open > .nav-link:not(.disabled),
.sidebar-light .card[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar > .nav-item > .nav-link.active {
  background-color: #26A69A;
  color: #fff; }

.sidebar-dark .nav-sidebar .nav-item-header,
.sidebar-light .card[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar .nav-item-header {
  color: rgba(255, 255, 255, 0.5); }

.sidebar-dark .nav-sidebar .nav-item-divider,
.sidebar-light .card[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar .nav-item-divider {
  background-color: rgba(255, 255, 255, 0.1); }

.sidebar-dark .nav-sidebar > .nav-item-submenu > .nav-group-sub,
.sidebar-light .card[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar > .nav-item-submenu > .nav-group-sub {
  background-color: rgba(0, 0, 0, 0.15); }

.sidebar-dark[class*=bg-] .nav-sidebar > .nav-item-open > .nav-link:not(.disabled),
.sidebar-dark[class*=bg-] .nav-sidebar > .nav-item > .nav-link.active,
.sidebar .card[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar > .nav-item-open > .nav-link:not(.disabled),
.sidebar .card[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar > .nav-item > .nav-link.active {
  background-color: rgba(0, 0, 0, 0.3); }

.sidebar-light {
  background-color: #fff;
  color: #333;
  border-color: rgba(0, 0, 0, 0.125); }
  .sidebar-light .nav-sidebar .nav-link {
    color: rgba(51, 51, 51, 0.85); }
    .sidebar-light .nav-sidebar .nav-link:not(.disabled):hover {
      color: #333;
      background-color: #f5f5f5; }
  .sidebar-light .nav-sidebar .nav-item > .nav-link.active {
    background-color: #f5f5f5;
    color: #333; }
  .sidebar-light .nav-sidebar .nav-item-open > .nav-link:not(.disabled),
  .sidebar-light .nav-sidebar > .nav-item-expanded:not(.nav-item-open) > .nav-link {
    background-color: #f5f5f5;
    color: #333; }
  .sidebar-light .nav-sidebar > .nav-item-open > .nav-link:not(.disabled),
  .sidebar-light .nav-sidebar > .nav-item > .nav-link.active {
    background-color: #f5f5f5;
    color: #333; }
  .sidebar-light .nav-sidebar .nav-item-header {
    color: rgba(51, 51, 51, 0.5); }
  .sidebar-light .nav-sidebar .nav-item-divider {
    background-color: rgba(0, 0, 0, 0.125); }
  .sidebar-light .nav-sidebar > .nav-item-submenu > .nav-group-sub {
    background-color: transparent; }
  .sidebar-light .sidebar-mobile-toggler {
    color: rgba(51, 51, 51, 0.8);
    border-bottom-color: rgba(0, 0, 0, 0.125); }
    .sidebar-light .sidebar-mobile-toggler:not([class*=bg-]) {
      background-color: whitesmoke; }
    .sidebar-light .sidebar-mobile-toggler a {
      color: rgba(51, 51, 51, 0.8); }
      .sidebar-light .sidebar-mobile-toggler a:hover {
        color: #333;
        background-color: transparent; }
    .sidebar-light .sidebar-mobile-toggler[class*=bg-]:not(.bg-white):not(.bg-light):not(.bg-transparent) {
      color: rgba(255, 255, 255, 0.9);
      border-bottom-color: rgba(255, 255, 255, 0.1); }
      .sidebar-light .sidebar-mobile-toggler[class*=bg-]:not(.bg-white):not(.bg-light):not(.bg-transparent) a {
        color: rgba(255, 255, 255, 0.9); }
        .sidebar-light .sidebar-mobile-toggler[class*=bg-]:not(.bg-white):not(.bg-light):not(.bg-transparent) a:hover {
          color: #fff;
          background-color: transparent; }

@media (min-width: 768px) {
  .sidebar-xs .sidebar-main {
    width: 3.5rem; }
    .sidebar-xs .sidebar-main .sidebar-content::-webkit-scrollbar {
      width: 0 !important; }
    .sidebar-xs .sidebar-main .card:not(.card-sidebar-mobile),
    .sidebar-xs .sidebar-main .card-title {
      display: none; }
    .sidebar-xs .sidebar-main .card-header h6 + .header-elements {
      padding-top: 0.22117rem;
      padding-bottom: 0.22117rem; }
    .sidebar-xs .sidebar-main .card-header h5 + .header-elements {
      padding-top: 0.31733rem;
      padding-bottom: 0.31733rem; }
    .sidebar-xs .sidebar-main .nav-sidebar > .nav-item {
      position: relative;
      margin: 0; }
      .sidebar-xs .sidebar-main .nav-sidebar > .nav-item > .nav-link {
        -ms-flex-pack: center;
            justify-content: center;
        padding-left: 0;
        padding-right: 0; }
        .sidebar-xs .sidebar-main .nav-sidebar > .nav-item > .nav-link > i {
          position: static;
          margin-left: 0;
          margin-right: 0;
          display: block;
          padding-bottom: 1px; }
        .sidebar-xs .sidebar-main .nav-sidebar > .nav-item > .nav-link > span {
          display: none; }
    .sidebar-xs .sidebar-main .nav-sidebar > .nav-item-submenu:hover > .nav-group-sub, .sidebar-xs .sidebar-main .nav-sidebar > .nav-item-submenu:focus > .nav-group-sub {
      display: block !important; }
    .sidebar-xs .sidebar-main .nav-sidebar > .nav-item-submenu > .nav-group-sub {
      position: absolute;
      top: -0.5rem;
      right: -16.875rem;
      width: 16.875rem;
      display: none;
      z-index: 1000;
      box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
      border-top-right-radius: 0.1875rem;
      border-bottom-right-radius: 0.1875rem; }
      .sidebar-xs .sidebar-main .nav-sidebar > .nav-item-submenu > .nav-group-sub[data-submenu-title]:before {
        content: attr(data-submenu-title);
        display: block;
        padding: 0.75rem 1.25rem;
        padding-bottom: 0;
        margin-top: 0.5rem;
        opacity: 0.5; }
    .sidebar-xs .sidebar-main .nav-sidebar > .nav-item-submenu > .nav-link:after {
      content: none; }
    .sidebar-xs .sidebar-main .nav-sidebar .nav-group-sub .nav-link {
      padding-left: 1.25rem; }
    .sidebar-xs .sidebar-main .nav-sidebar .nav-group-sub .nav-group-sub .nav-link {
      padding-left: 2.25rem; }
    .sidebar-xs .sidebar-main .nav-sidebar .nav-group-sub .nav-group-sub .nav-group-sub .nav-link {
      padding-left: 3.5rem; }
    .sidebar-xs .sidebar-main .nav-sidebar > .nav-item-header {
      padding: 0;
      text-align: center; }
      .sidebar-xs .sidebar-main .nav-sidebar > .nav-item-header > i {
        display: block;
        top: 0;
        padding: 0.75rem 1.25rem;
        margin-top: 0.12502rem;
        margin-bottom: 0.12502rem; }
      .sidebar-xs .sidebar-main .nav-sidebar > .nav-item-header > div {
        display: none; }
    .sidebar-xs .sidebar-main .nav-sidebar > .nav-item-open > .nav-group-sub {
      display: none !important; }
    .sidebar-xs .sidebar-main .nav-sidebar > .nav-item:hover > .nav-link.disabled + .nav-group-sub,
    .sidebar-xs .sidebar-main .nav-sidebar > .nav-item:hover > .nav-link.disabled > span, .sidebar-xs .sidebar-main .nav-sidebar > .nav-item:focus > .nav-link.disabled + .nav-group-sub,
    .sidebar-xs .sidebar-main .nav-sidebar > .nav-item:focus > .nav-link.disabled > span {
      display: none !important; }
    .sidebar-xs .sidebar-main .sidebar-user .card-body {
      padding-left: 0;
      padding-right: 0; }
    .sidebar-xs .sidebar-main .sidebar-user .media {
      -ms-flex-pack: center;
          justify-content: center; }
      .sidebar-xs .sidebar-main .sidebar-user .media > div:not(:first-child) {
        display: none !important; }
      .sidebar-xs .sidebar-main .sidebar-user .media > div:first-child {
        margin: 0 !important; }
    .sidebar-xs .sidebar-main .nav-item-submenu-reversed .nav-group-sub {
      top: auto !important;
      bottom: 0; }
    .sidebar-xs .sidebar-main.sidebar-dark .nav-sidebar > .nav-item:not(.nav-item-open):hover > .nav-link:not(.active):not(.disabled) {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.15); }
    .sidebar-xs .sidebar-main.sidebar-dark .nav-sidebar > .nav-item-submenu > .nav-group-sub {
      background-color: #324661;
      border-left: 1px solid rgba(255, 255, 255, 0.1); }
    .sidebar-xs .sidebar-main.sidebar-light .nav-sidebar > .nav-item:not(.nav-item-open):hover > .nav-link:not(.active):not(.disabled) {
      color: #333;
      background-color: #f5f5f5; }
    .sidebar-xs .sidebar-main.sidebar-light .nav-sidebar > .nav-item-submenu > .nav-group-sub {
      background-color: #fcfcfc;
      border: 1px solid rgba(0, 0, 0, 0.125); }
    .sidebar-xs .sidebar-main.sidebar-light .card[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar > .nav-item:not(.nav-item-open):hover > .nav-link:not(.active):not(.disabled) {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.15); }
    .sidebar-xs .sidebar-main.sidebar-light .card[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-sidebar > .nav-item-submenu > .nav-group-sub {
      background-color: #324661;
      border: 1px solid rgba(255, 255, 255, 0.1); }
  .sidebar-xs .sidebar-main.sidebar-fixed {
    z-index: 1029; }
    .sidebar-xs .sidebar-main.sidebar-fixed .nav-sidebar > .nav-item-submenu:hover > .nav-group-sub, .sidebar-xs .sidebar-main.sidebar-fixed .nav-sidebar > .nav-item-submenu:focus > .nav-group-sub {
      position: fixed;
      left: 3.5rem;
      top: 3.12503rem;
      bottom: 0;
      width: 16.875rem;
      overflow-y: auto;
      border-radius: 0; }
  .sidebar-xs .navbar-lg:first-child ~ .page-content .sidebar-fixed.sidebar-main .nav-sidebar > .nav-item-submenu:hover > .nav-group-sub, .sidebar-xs .navbar-lg:first-child ~ .page-content .sidebar-fixed.sidebar-main .nav-sidebar > .nav-item-submenu:focus > .nav-group-sub {
    top: 3.37503rem; }
  .sidebar-xs .navbar-sm:first-child ~ .page-content .sidebar-fixed.sidebar-main .nav-sidebar > .nav-item-submenu:hover > .nav-group-sub, .sidebar-xs .navbar-sm:first-child ~ .page-content .sidebar-fixed.sidebar-main .nav-sidebar > .nav-item-submenu:focus > .nav-group-sub {
    top: 2.87503rem; } }

@media (min-width: 576px) {
  .sidebar-expand-sm {
    position: static;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-bottom: 1.25rem;
    border-radius: 0.1875rem;
    transition: none; } }
  @media screen and (min-width: 576px) and (prefers-reduced-motion: reduce) {
    .sidebar-expand-sm {
      transition: none; } }

@media (min-width: 576px) {
    .sidebar-expand-sm.sidebar-main {
      left: auto;
      z-index: 99;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
      .sidebar-expand-sm.sidebar-main .sidebar-content {
        left: 0; }
    .sidebar-expand-sm.sidebar-secondary {
      left: auto;
      z-index: 98;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
      .sidebar-expand-sm.sidebar-secondary .sidebar-content {
        left: 0; }
    .sidebar-expand-sm.sidebar-right {
      right: auto;
      z-index: 97;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
      .sidebar-expand-sm.sidebar-right .sidebar-content {
        right: 0; }
    .sidebar-expand-sm .sidebar-content {
      position: static;
      overflow: visible;
      width: auto; }
    .sidebar-expand-sm .sidebar-mobile-toggler {
      display: none; }
    .sidebar-expand-sm.sidebar-fullscreen {
      width: 16.875rem; }
    .sidebar-main-hidden .sidebar-expand-sm.sidebar-main,
    .sidebar-secondary-hidden .sidebar-expand-sm.sidebar-secondary,
    .sidebar-mobile-right .sidebar-expand-sm.sidebar-right {
      display: none; }
    .sidebar-expand-sm.sidebar-right {
      display: none; }
      .sidebar-right-visible .sidebar-expand-sm.sidebar-right {
        display: block; }
    .sidebar-expand-sm.sidebar-sections {
      background-color: transparent;
      border: 0;
      box-shadow: none; }
      .sidebar-expand-sm.sidebar-sections .card {
        border-width: 1px;
        margin-bottom: 1.25rem;
        border-radius: 0.1875rem;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); } }

@media (max-width: 575.98px) {
  .sidebar-expand-sm {
    border: 0; } }

@media (min-width: 768px) {
  .sidebar-expand-md {
    position: static;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-bottom: 1.25rem;
    border-radius: 0.1875rem;
    transition: none; } }
  @media screen and (min-width: 768px) and (prefers-reduced-motion: reduce) {
    .sidebar-expand-md {
      transition: none; } }

@media (min-width: 768px) {
    .sidebar-expand-md.sidebar-main {
      left: auto;
      z-index: 99;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
      .sidebar-expand-md.sidebar-main .sidebar-content {
        left: 0; }
    .sidebar-expand-md.sidebar-secondary {
      left: auto;
      z-index: 98;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
      .sidebar-expand-md.sidebar-secondary .sidebar-content {
        left: 0; }
    .sidebar-expand-md.sidebar-right {
      right: auto;
      z-index: 97;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
      .sidebar-expand-md.sidebar-right .sidebar-content {
        right: 0; }
    .sidebar-expand-md .sidebar-content {
      position: static;
      overflow: visible;
      width: auto; }
    .sidebar-expand-md .sidebar-mobile-toggler {
      display: none; }
    .sidebar-expand-md.sidebar-fullscreen {
      width: 16.875rem; }
    .sidebar-main-hidden .sidebar-expand-md.sidebar-main,
    .sidebar-secondary-hidden .sidebar-expand-md.sidebar-secondary,
    .sidebar-mobile-right .sidebar-expand-md.sidebar-right {
      display: none; }
    .sidebar-expand-md.sidebar-right {
      display: none; }
      .sidebar-right-visible .sidebar-expand-md.sidebar-right {
        display: block; }
    .sidebar-expand-md.sidebar-sections {
      background-color: transparent;
      border: 0;
      box-shadow: none; }
      .sidebar-expand-md.sidebar-sections .card {
        border-width: 1px;
        margin-bottom: 1.25rem;
        border-radius: 0.1875rem;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); } }

@media (max-width: 767.98px) {
  .sidebar-expand-md {
    border: 0; } }

@media (min-width: 992px) {
  .sidebar-expand-lg {
    position: static;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-bottom: 1.25rem;
    border-radius: 0.1875rem;
    transition: none; } }
  @media screen and (min-width: 992px) and (prefers-reduced-motion: reduce) {
    .sidebar-expand-lg {
      transition: none; } }

@media (min-width: 992px) {
    .sidebar-expand-lg.sidebar-main {
      left: auto;
      z-index: 99;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
      .sidebar-expand-lg.sidebar-main .sidebar-content {
        left: 0; }
    .sidebar-expand-lg.sidebar-secondary {
      left: auto;
      z-index: 98;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
      .sidebar-expand-lg.sidebar-secondary .sidebar-content {
        left: 0; }
    .sidebar-expand-lg.sidebar-right {
      right: auto;
      z-index: 97;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
      .sidebar-expand-lg.sidebar-right .sidebar-content {
        right: 0; }
    .sidebar-expand-lg .sidebar-content {
      position: static;
      overflow: visible;
      width: auto; }
    .sidebar-expand-lg .sidebar-mobile-toggler {
      display: none; }
    .sidebar-expand-lg.sidebar-fullscreen {
      width: 16.875rem; }
    .sidebar-main-hidden .sidebar-expand-lg.sidebar-main,
    .sidebar-secondary-hidden .sidebar-expand-lg.sidebar-secondary,
    .sidebar-mobile-right .sidebar-expand-lg.sidebar-right {
      display: none; }
    .sidebar-expand-lg.sidebar-right {
      display: none; }
      .sidebar-right-visible .sidebar-expand-lg.sidebar-right {
        display: block; }
    .sidebar-expand-lg.sidebar-sections {
      background-color: transparent;
      border: 0;
      box-shadow: none; }
      .sidebar-expand-lg.sidebar-sections .card {
        border-width: 1px;
        margin-bottom: 1.25rem;
        border-radius: 0.1875rem;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); } }

@media (max-width: 991.98px) {
  .sidebar-expand-lg {
    border: 0; } }

@media (min-width: 1200px) {
  .sidebar-expand-xl {
    position: static;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-bottom: 1.25rem;
    border-radius: 0.1875rem;
    transition: none; } }
  @media screen and (min-width: 1200px) and (prefers-reduced-motion: reduce) {
    .sidebar-expand-xl {
      transition: none; } }

@media (min-width: 1200px) {
    .sidebar-expand-xl.sidebar-main {
      left: auto;
      z-index: 99;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
      .sidebar-expand-xl.sidebar-main .sidebar-content {
        left: 0; }
    .sidebar-expand-xl.sidebar-secondary {
      left: auto;
      z-index: 98;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
      .sidebar-expand-xl.sidebar-secondary .sidebar-content {
        left: 0; }
    .sidebar-expand-xl.sidebar-right {
      right: auto;
      z-index: 97;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
      .sidebar-expand-xl.sidebar-right .sidebar-content {
        right: 0; }
    .sidebar-expand-xl .sidebar-content {
      position: static;
      overflow: visible;
      width: auto; }
    .sidebar-expand-xl .sidebar-mobile-toggler {
      display: none; }
    .sidebar-expand-xl.sidebar-fullscreen {
      width: 16.875rem; }
    .sidebar-main-hidden .sidebar-expand-xl.sidebar-main,
    .sidebar-secondary-hidden .sidebar-expand-xl.sidebar-secondary,
    .sidebar-mobile-right .sidebar-expand-xl.sidebar-right {
      display: none; }
    .sidebar-expand-xl.sidebar-right {
      display: none; }
      .sidebar-right-visible .sidebar-expand-xl.sidebar-right {
        display: block; }
    .sidebar-expand-xl.sidebar-sections {
      background-color: transparent;
      border: 0;
      box-shadow: none; }
      .sidebar-expand-xl.sidebar-sections .card {
        border-width: 1px;
        margin-bottom: 1.25rem;
        border-radius: 0.1875rem;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); } }

@media (max-width: 1199.98px) {
  .sidebar-expand-xl {
    border: 0; } }

.sidebar-expand {
  position: static;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  margin-bottom: 1.25rem;
  border-radius: 0.1875rem;
  transition: none;
  border: 0; }
  @media screen and (prefers-reduced-motion: reduce) {
    .sidebar-expand {
      transition: none; } }
  .sidebar-expand.sidebar-main {
    left: auto;
    z-index: 99;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
    .sidebar-expand.sidebar-main .sidebar-content {
      left: 0; }
  .sidebar-expand.sidebar-secondary {
    left: auto;
    z-index: 98;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
    .sidebar-expand.sidebar-secondary .sidebar-content {
      left: 0; }
  .sidebar-expand.sidebar-right {
    right: auto;
    z-index: 97;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
    .sidebar-expand.sidebar-right .sidebar-content {
      right: 0; }
  .sidebar-expand .sidebar-content {
    position: static;
    overflow: visible;
    width: auto; }
  .sidebar-expand .sidebar-mobile-toggler {
    display: none; }
  .sidebar-expand.sidebar-fullscreen {
    width: 16.875rem; }
  .sidebar-main-hidden .sidebar-expand.sidebar-main,
  .sidebar-secondary-hidden .sidebar-expand.sidebar-secondary,
  .sidebar-mobile-right .sidebar-expand.sidebar-right {
    display: none; }
  .sidebar-expand.sidebar-right {
    display: none; }
    .sidebar-right-visible .sidebar-expand.sidebar-right {
      display: block; }
  .sidebar-expand.sidebar-sections {
    background-color: transparent;
    border: 0;
    box-shadow: none; }
    .sidebar-expand.sidebar-sections .card {
      border-width: 1px;
      margin-bottom: 1.25rem;
      border-radius: 0.1875rem;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }

.sidebar .card {
  border-width: 0;
  margin-bottom: 0;
  border-radius: 0;
  box-shadow: none; }
  .sidebar .card .card {
    border-width: 1px; }

.sidebar:not(.sidebar-sections) .card:not([class*=bg-]):not(.fixed-top) {
  background-color: transparent; }

.sidebar .card-footer {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.sidebar .row:not(.no-gutters) {
  margin-left: -0.3125rem;
  margin-right: -0.3125rem; }
  .sidebar .row:not(.no-gutters) [class*=col] {
    padding-left: 0.3125rem;
    padding-right: 0.3125rem; }

.sidebar .form-group:last-child {
  margin-bottom: 0; }

.sidebar .nav-tabs .nav-item:first-child .nav-link {
  border-left: 0; }

.sidebar .nav-tabs .nav-item:last-child .nav-link {
  border-right: 0; }

.sidebar .nav-tabs .nav-link {
  border-top: 0;
  border-bottom-width: 0; }
  .sidebar .nav-tabs .nav-link.active {
    border-bottom-color: transparent; }

.sidebar-dark .nav-tabs {
  background-color: #223043;
  border-bottom-color: rgba(255, 255, 255, 0.1); }
  .sidebar-dark .nav-tabs .nav-link {
    color: rgba(255, 255, 255, 0.9); }
    .sidebar-dark .nav-tabs .nav-link:hover, .sidebar-dark .nav-tabs .nav-link:focus {
      color: #fff; }
    .sidebar-dark .nav-tabs .nav-link.active {
      color: #fff; }
  .sidebar-dark .nav-tabs:not(.nav-tabs-bottom) .nav-link.active {
    background-color: #293a50;
    border-color: rgba(255, 255, 255, 0.1); }
  .sidebar-dark .nav-tabs .nav-item.show .nav-link:not(.active) {
    color: #fff; }

.sidebar-light .nav-tabs {
  background-color: whitesmoke;
  border-bottom-color: rgba(0, 0, 0, 0.125); }
  .sidebar-light .nav-tabs .nav-link:hover, .sidebar-light .nav-tabs .nav-link:focus {
    color: #333; }
  .sidebar-light .nav-tabs .nav-link.active {
    color: #333; }
  .sidebar-light .nav-tabs:not(.nav-tabs-bottom) .nav-link.active {
    background-color: #fff; }

.row-tile div[class*=col] .btn {
  border-radius: 0; }

.row-tile div[class*=col]:first-child .btn:first-child {
  border-top-left-radius: 0.1875rem; }

.row-tile div[class*=col]:first-child .btn:last-child {
  border-bottom-left-radius: 0.1875rem; }

.row-tile div[class*=col]:last-child .btn:first-child {
  border-top-right-radius: 0.1875rem; }

.row-tile div[class*=col]:last-child .btn:last-child {
  border-bottom-right-radius: 0.1875rem; }

.row-tile div[class*=col] .btn + .btn {
  border-top: 0; }

.row-tile div[class*=col] + div[class*=col] .btn {
  border-left: 0; }

/* ------------------------------------------------------------------------------
 *
 *  # Boxed layout
 *
 *  Styles for main structure of content area in boxed layout
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Components
 *
 *  Components import. Ordering matters. See _config.scss for more options
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Global configuration
 *
 *  Here you can change main theme, enable or disable certain components and
 *  optional styles. This allows you to include only components that you need.
 *
 *  'true'  - enables component and includes it to main CSS file.
 *  'false' - disables component and excludes it from main CSS file.
 *
 *  Layout helper: @if $layout == 'base' {...}
 *  Theme helper: @if $theme == 'material' {...}
 *  Component helper: @if $enable-* {...}
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Custom Limitless functions
 *
 *  Utility mixins and functions for evalutating source code across our variables, maps, and mixins.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Custom template mixins
 *
 *  All custom mixins are prefixed with "ll-" to avoid conflicts
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Main colors
 *
 *  List of the template main color palettes
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Default Bootstrap variable overrides
 *
 *  Variables should follow the `$component-state-property-size` formula for
 *  consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
 *  Also includes custom variables, all marked with "!default" flag.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Additional variables
 *
 *  Mainly 3rd party libraries and additional variables for default
 *  Bootstrap components.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Mixins
 *
 *  Import Bootstrap mixins with overrides
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Badge mixin
 *
 *  Override and extend default badge mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Buttons mixin
 *
 *  Override and extend default buttons mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Caret mixin
 *
 *  Override and extend default cared mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Forms mixin
 *
 *  Override and extend default forms mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Background mixin
 *
 *  Override and extend default background mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Flash of unstyled content (FOUC)
 *
 *  CSS fix for FOUC issue, which pops up in latest jQuery version. These styles
 *  are optional, but enabled by default. Feel free to turn them off in configuration.
 *
 * ---------------------------------------------------------------------------- */
input[type=checkbox][data-fouc],
input[type=radio][data-fouc] {
  visibility: hidden;
  width: 1.25rem;
  height: 1.25rem; }
  .form-check input[type=checkbox][data-fouc], .form-check
  input[type=radio][data-fouc] {
    position: absolute; }
  .form-check-inline:not(.form-check-switchery) input[type=checkbox][data-fouc], .form-check-inline:not(.form-check-switchery)
  input[type=radio][data-fouc] {
    position: relative;
    margin-top: 0.00002rem;
    margin-right: 0.625rem; }
    .form-check-inline.form-check-right .form-check-inline:not(.form-check-switchery) input[type=checkbox][data-fouc], .form-check-inline.form-check-right .form-check-inline:not(.form-check-switchery)
    input[type=radio][data-fouc] {
      margin-right: 0;
      margin-left: 0.625rem; }

.form-check-switchery input[data-fouc] {
  width: 2.375rem;
  height: 1.25rem; }

.form-check-switchery-double.form-check input[data-fouc] {
  position: static; }

.form-check-switch input {
  height: 2.25003rem;
  visibility: hidden;
  display: inline-block;
  vertical-align: middle;
  margin: 0; }
  .form-check-switch input[data-size=large] {
    height: 2.50002rem; }
  .form-check-switch input[data-size=small] {
    height: 2.00002rem; }

input[type=text][data-fouc] {
  height: 2.25003rem;
  opacity: 0; }

input[type=text].form-control-lg[data-fouc], .input-group-lg > input.form-control[type=text][data-fouc],
.input-group-lg > .input-group-prepend > input.input-group-text[type=text][data-fouc],
.input-group-lg > .input-group-append > input.input-group-text[type=text][data-fouc],
.input-group-lg > .input-group-prepend > input.btn[type=text][data-fouc],
.input-group-lg > .input-group-append > input.btn[type=text][data-fouc] {
  height: 2.50002rem; }

input[type=text].form-control-sm[data-fouc], .input-group-sm > input.form-control[type=text][data-fouc],
.input-group-sm > .input-group-prepend > input.input-group-text[type=text][data-fouc],
.input-group-sm > .input-group-append > input.input-group-text[type=text][data-fouc],
.input-group-sm > .input-group-prepend > input.btn[type=text][data-fouc],
.input-group-sm > .input-group-append > input.btn[type=text][data-fouc] {
  height: 2.00002rem; }

select[data-fouc]:not([aria-hidden=false]) {
  height: 2.25003rem;
  opacity: 0; }

select.form-control-lg[data-fouc]:not([aria-hidden=false]), .input-group-lg > select.form-control[data-fouc]:not([aria-hidden=false]),
.input-group-lg > .input-group-prepend > select.input-group-text[data-fouc]:not([aria-hidden=false]),
.input-group-lg > .input-group-append > select.input-group-text[data-fouc]:not([aria-hidden=false]),
.input-group-lg > .input-group-prepend > select.btn[data-fouc]:not([aria-hidden=false]),
.input-group-lg > .input-group-append > select.btn[data-fouc]:not([aria-hidden=false]) {
  height: 2.50002rem; }

select.form-control-sm[data-fouc]:not([aria-hidden=false]), .input-group-sm > select.form-control[data-fouc]:not([aria-hidden=false]),
.input-group-sm > .input-group-prepend > select.input-group-text[data-fouc]:not([aria-hidden=false]),
.input-group-sm > .input-group-append > select.input-group-text[data-fouc]:not([aria-hidden=false]),
.input-group-sm > .input-group-prepend > select.btn[data-fouc]:not([aria-hidden=false]),
.input-group-sm > .input-group-append > select.btn[data-fouc]:not([aria-hidden=false]) {
  height: 2.00002rem; }

input[type=file][data-fouc] {
  height: 2.25003rem;
  opacity: 0; }

input[type=file].form-control-lg[data-fouc], .input-group-lg > input.form-control[type=file][data-fouc],
.input-group-lg > .input-group-prepend > input.input-group-text[type=file][data-fouc],
.input-group-lg > .input-group-append > input.input-group-text[type=file][data-fouc],
.input-group-lg > .input-group-prepend > input.btn[type=file][data-fouc],
.input-group-lg > .input-group-append > input.btn[type=file][data-fouc] {
  height: 2.50002rem; }

input[type=file].form-control-sm[data-fouc], .input-group-sm > input.form-control[type=file][data-fouc],
.input-group-sm > .input-group-prepend > input.input-group-text[type=file][data-fouc],
.input-group-sm > .input-group-append > input.input-group-text[type=file][data-fouc],
.input-group-sm > .input-group-prepend > input.btn[type=file][data-fouc],
.input-group-sm > .input-group-append > input.btn[type=file][data-fouc] {
  height: 2.00002rem; }

.wizard-form[data-fouc] {
  opacity: 0; }
  .wizard-form[data-fouc].wizard {
    opacity: 1; }
  .wizard-form[data-fouc]:not(.wizard) {
    padding-top: 6.75003rem;
    padding-bottom: 3.50003rem; }
    .wizard-form[data-fouc]:not(.wizard) fieldset:not(:first-of-type),
    .wizard-form[data-fouc]:not(.wizard) h6 {
      display: none; }

input[type=text].colorpicker-flat-full[data-fouc] {
  height: 285px; }

pre[data-fouc]:not(.ace_editor) {
  height: 450px; }

pre[data-fouc].ace_editor {
  visibility: visible; }

.noui-pips-height-helper:not(.noUi-target)[data-fouc] {
  height: 2.62503rem; }

.noui-pips-height-helper.noui-slider-lg:not(.noUi-target)[data-fouc] {
  height: 2.75003rem; }

.noui-pips-height-helper.noui-slider-sm:not(.noUi-target)[data-fouc] {
  height: 2.50003rem; }

.noui-vertical-height-helper:not(.noUi-target)[data-fouc] {
  display: inline-block;
  height: 10rem; }

input[type=text].ion-height-helper[data-fouc] {
  height: 3.4375rem; }

input[type=text].ion-pips-height-helper[data-fouc] {
  height: 5.5625rem; }

input[type=text].noui-height-helper:not(.noUi-target)[data-fouc] {
  height: 0.375rem; }

/* ------------------------------------------------------------------------------
 *
 *  # Pace. Default theme
 *
 *  Minimal preloader theme, used as a default theme
 *
 * ---------------------------------------------------------------------------- */
.pace {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .pace .pace-progress {
    background: #4FC3F7;
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    width: 0;
    height: 0.125rem; }

.pace-inactive {
  display: none; }

/* ------------------------------------------------------------------------------
 *
 *  # Uniform.js
 *
 *  Styles for uniform.min.js - form components styling
 *
 * ---------------------------------------------------------------------------- */
.uniform-checker,
.uniform-choice {
  position: relative;
  cursor: pointer;
  vertical-align: middle; }
  .uniform-checker,
  .uniform-checker span,
  .uniform-checker input,
  .uniform-choice,
  .uniform-choice span,
  .uniform-choice input {
    width: 1.25rem;
    height: 1.25rem; }
  .uniform-checker span,
  .uniform-choice span {
    border: 0.125rem solid #455A64;
    display: inline-block;
    text-align: center;
    position: relative; }
    .uniform-checker span.checked:after,
    .uniform-choice span.checked:after {
      opacity: 1; }
  .uniform-checker input[type=checkbox],
  .uniform-checker input[type=radio],
  .uniform-choice input[type=checkbox],
  .uniform-choice input[type=radio] {
    border: 0;
    background: none;
    display: inline-block;
    margin: 0;
    cursor: pointer;
    position: absolute;
    top: -0.125rem;
    left: -0.125rem;
    visibility: visible;
    opacity: 0;
    z-index: 2; }
    .form-check.form-check-inline .uniform-checker input[type=checkbox], .form-check.form-check-inline
    .uniform-checker input[type=radio], .form-check.form-check-inline
    .uniform-choice input[type=checkbox], .form-check.form-check-inline
    .uniform-choice input[type=radio] {
      margin-left: 0;
      margin-right: 0; }
  .form-check .uniform-checker, .form-check
  .uniform-choice {
    position: absolute;
    top: 0.00002rem;
    left: 0; }
  .form-check-right .uniform-checker, .form-check-right
  .uniform-choice {
    left: auto;
    right: 0; }
  .form-check-inline .uniform-checker, .form-check-inline
  .uniform-choice {
    position: static;
    margin-right: 0.625rem;
    margin-top: 0.00002rem; }
  .form-check-inline.form-check-right .uniform-checker, .form-check-inline.form-check-right
  .uniform-choice {
    margin-right: 0;
    margin-left: 0.625rem; }
  .uniform-checker.disabled,
  .uniform-choice.disabled {
    opacity: 0.5; }
    .uniform-checker.disabled,
    .uniform-checker.disabled input,
    .uniform-choice.disabled,
    .uniform-choice.disabled input {
      cursor: default; }

.uniform-checker span {
  color: #455A64;
  border-radius: 0.125rem;
  transition: border-color ease-in-out 0.15s, color ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .uniform-checker span {
      transition: none; } }
  .uniform-checker span:after {
    content: "\e600";
    font-family: "icomoon";
    font-size: 1rem;
    position: absolute;
    top: 0rem;
    left: 0rem;
    line-height: 1;
    opacity: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: opacity ease-in-out 0.15s; }
    @media screen and (prefers-reduced-motion: reduce) {
      .uniform-checker span:after {
        transition: none; } }
  .form-check-light .uniform-checker span {
    border-color: #fff;
    color: #fff; }

.uniform-checker[class*=border-] span {
  border-color: inherit;
  color: inherit; }

.uniform-choice {
  border-radius: 100px; }
  .uniform-choice span {
    border-radius: 100px;
    transition: border-color ease-in-out 0.15s; }
    @media screen and (prefers-reduced-motion: reduce) {
      .uniform-choice span {
        transition: none; } }
    .uniform-choice span:after {
      content: "";
      position: absolute;
      top: 0.1875rem;
      left: 0.1875rem;
      border: 0.3125rem solid;
      border-color: inherit;
      width: 0;
      height: 0;
      border-radius: 100px;
      opacity: 0;
      transition: all ease-in-out 0.15s; }
      @media screen and (prefers-reduced-motion: reduce) {
        .uniform-choice span:after {
          transition: none; } }
    .form-check-light .uniform-choice span {
      border-color: #fff; }
  .uniform-choice[class*=border-] span {
    border-color: inherit; }
    .uniform-choice[class*=border-] span:after {
      border-color: inherit; }

.dropdown-menu:not([class*=bg-]) .dropdown-item.active:not(.disabled) .uniform-checker span {
  border-color: #fff;
  color: #fff; }

.dropdown-menu:not([class*=bg-]) .dropdown-item.active:not(.disabled) .uniform-choice span {
  border-color: #fff; }

.dropdown-item.form-check .form-check-label {
  padding-left: 3.25rem; }

.dropdown-item.form-check .uniform-checker,
.dropdown-item.form-check .uniform-choice {
  margin-top: 0.50002rem;
  left: 1rem; }

.dropdown-item.form-check.form-check-right .form-check-label {
  padding-right: 3.25rem; }

.dropdown-item.form-check.form-check-right .uniform-checker,
.dropdown-item.form-check.form-check-right .uniform-choice {
  left: auto;
  right: 1rem; }

.dropdown-menu[class*=bg-]:not(.bg-white):not(.bg-transparent):not(.bg-light) .uniform-checker span {
  border-color: #fff;
  color: #fff; }

.dropdown-menu[class*=bg-]:not(.bg-white):not(.bg-transparent):not(.bg-light) .uniform-choice span {
  border-color: #fff; }

.uniform-uploader {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
      align-items: stretch; }
  .uniform-uploader .filename {
    color: #999;
    padding: 0.4375rem 0.875rem;
    -ms-flex: 1;
        flex: 1;
    border: 1px solid #ddd;
    border-right: 0;
    background-color: #fff;
    text-align: left;
    word-break: break-word;
    border-top-left-radius: 0.1875rem;
    border-bottom-left-radius: 0.1875rem; }
  .uniform-uploader .action {
    z-index: 1;
    border-radius: 0;
    border-top-right-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem; }
  .uniform-uploader input[type=file] {
    width: 100%;
    margin-top: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    min-height: 2.25003rem;
    border: 0;
    cursor: pointer;
    z-index: 10;
    opacity: 0; }
  .uniform-uploader.disabled .filename {
    background-color: #fafafa; }
  .uniform-uploader.disabled .filename,
  .uniform-uploader.disabled input[type=file] {
    cursor: default; }

.form-control-styled-lg input[type=file] {
  min-height: 2.50002rem; }

.form-control-styled-lg .filename {
  padding: 0.5625rem 1rem;
  font-size: 0.875rem;
  line-height: 1.4286; }

.form-control-styled-sm input[type=file] {
  min-height: 2.00002rem; }

.form-control-styled-sm .filename {
  padding: 0.3125rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.6667; }

.uniform-select {
  position: relative;
  display: block;
  width: 100%;
  height: 2.25003rem;
  padding: 0.4375rem 0.875rem;
  font-size: 0.8125rem;
  line-height: 1.5385;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .uniform-select {
      transition: none; } }
  .uniform-select:hover, .uniform-select:focus {
    box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.01) inset; }
  .uniform-select[class*=bg-]:not(.bg-white):not(.bg-light):not(.bg-transparent) {
    color: #fff; }
    .uniform-select[class*=bg-]:not(.bg-white):not(.bg-light):not(.bg-transparent):hover, .uniform-select[class*=bg-]:not(.bg-white):not(.bg-light):not(.bg-transparent):focus {
      box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.04) inset; }
  .uniform-select span {
    display: block;
    position: relative;
    text-align: left;
    padding-right: 1.875rem;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .uniform-select span:after {
      content: '\e9c5';
      font-family: "icomoon";
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 1rem;
      margin-top: -0.12502rem;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
  .uniform-select select {
    width: 100%;
    margin-top: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 2.25003rem;
    border: 0;
    cursor: pointer;
    z-index: 10;
    opacity: 0; }
  .uniform-select.disabled:not([class*=bg-]) {
    color: #999;
    background-color: #fafafa; }
  .uniform-select.disabled:hover, .uniform-select.disabled:focus {
    box-shadow: none !important; }
  .uniform-select.disabled select {
    cursor: default; }
  .uniform-select.disabled[class*=bg-]:not(.bg-white):not(.bg-light):not(.bg-transparent) {
    opacity: 0.75; }

.form-control-styled-lg select:not([size]):not([multiple]) {
  height: 2.50002rem; }

.form-control-styled-lg .uniform-select {
  height: 2.50002rem;
  padding: 0.5625rem 1rem;
  font-size: 0.875rem;
  line-height: 1.4286; }

.form-control-styled-sm select:not([size]):not([multiple]) {
  height: 2.00002rem; }

.form-control-styled-sm .uniform-select {
  height: 2.00002rem;
  padding: 0.3125rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.6667; }

/* ------------------------------------------------------------------------------
 *
 *  # Switchery
 *
 *  Styles for switchery.min.js - toggle switches
 *
 * ---------------------------------------------------------------------------- */
.switchery {
  background-color: #fff;
  border: 1px solid #ddd;
  cursor: pointer;
  display: block;
  line-height: 1;
  width: 2.25rem;
  height: 1.125rem;
  position: relative;
  box-sizing: content-box;
  border-radius: 100px; }
  .form-check-switchery .switchery {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0.00002rem; }
  .form-check-switchery.disabled .switchery {
    cursor: default; }
  .switchery > small {
    background-color: #fff;
    width: 1.125rem;
    height: 1.125rem;
    position: absolute;
    top: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    border-radius: 100px; }

.form-check-switchery:not(.dropdown-item) {
  padding-left: 3rem; }

.form-check-switchery:not(.dropdown-item).form-check-right {
  padding-left: 0;
  padding-right: 3rem; }
  .form-check-switchery:not(.dropdown-item).form-check-right .switchery {
    left: auto;
    right: 0; }

.form-check-switchery-double.form-check-switchery {
  padding-left: 0;
  padding-right: 0; }
  .form-check-switchery-double.form-check-switchery .switchery {
    margin-top: 0; }

.form-check-switchery-double .form-check-label {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center; }

.form-check-switchery-double .switchery {
  position: relative;
  margin-left: 0.625rem;
  margin-right: 0.625rem; }

.form-check-switchery-double input {
  margin-left: 0.625rem;
  margin-right: 0.625rem; }

.dropdown-item.form-check-switchery .form-check-label {
  padding-left: 4.375rem; }

.dropdown-item.form-check-switchery .switchery {
  top: 50%;
  left: 1rem;
  margin-top: -0.625rem; }

.dropdown-item.form-check-right.form-check-switchery .form-check-label {
  padding-right: 4.375rem; }

.dropdown-item.form-check-right.form-check-switchery .switchery {
  left: auto;
  right: 1rem; }

/* ------------------------------------------------------------------------------
*
*  # Bootstrap switches
*
*  Styles for switch.min.js - checkbox/radio toggle switches
*
* ---------------------------------------------------------------------------- */
.bootstrap-switch {
  display: inline-block;
  border: 1px solid transparent;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  /*rtl:begin:ignore*/
  direction: ltr;
  text-align: left;
  /*rtl:end:ignore*/
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .bootstrap-switch {
      transition: none; } }
  .bootstrap-switch .bootstrap-switch-container {
    display: inline-block;
    top: 0;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    border-radius: 0.1875rem; }
  .bootstrap-switch .bootstrap-switch-handle-on,
  .bootstrap-switch .bootstrap-switch-handle-off,
  .bootstrap-switch .bootstrap-switch-label {
    cursor: pointer;
    display: inline-block;
    height: 100%;
    padding: 0.4375rem 0.875rem; }
  .bootstrap-switch input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0; }

.form-check-switch {
  padding: 0; }
  .form-group.row .form-check-switch {
    margin-top: 0; }

.form-check-switch-left .bootstrap-switch {
  margin-right: 0.625rem; }

.form-check-switch-right .bootstrap-switch {
  margin-left: 0.625rem; }

.bootstrap-switch-focused {
  outline: 0; }

.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch-disabled .bootstrap-switch-label {
  cursor: default;
  opacity: 0.5; }

.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch-indeterminate .bootstrap-switch-label {
  cursor: default !important;
  opacity: 0.5; }

.bootstrap-switch-animate .bootstrap-switch-container {
  transition: margin 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .bootstrap-switch-animate .bootstrap-switch-container {
      transition: none; } }

/*rtl:begin:ignore*/
.bootstrap-switch-inverse .bootstrap-switch-handle-on {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: calc(0.1875rem - 1px);
  border-bottom-right-radius: calc(0.1875rem - 1px); }

.bootstrap-switch-inverse .bootstrap-switch-handle-off {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: calc(0.1875rem - 1px);
  border-bottom-left-radius: calc(0.1875rem - 1px); }

.bootstrap-switch-on .bootstrap-switch-label,
.bootstrap-switch-inverse.bootstrap-switch-off .bootstrap-switch-label {
  border-top-right-radius: calc(0.1875rem - 1px);
  border-bottom-right-radius: calc(0.1875rem - 1px); }

.bootstrap-switch-off .bootstrap-switch-label,
.bootstrap-switch-inverse.bootstrap-switch-on .bootstrap-switch-label {
  border-top-left-radius: calc(0.1875rem - 1px);
  border-bottom-left-radius: calc(0.1875rem - 1px); }

/*rtl:end:ignore*/
.bootstrap-switch-handle-on,
.bootstrap-switch-handle-off {
  text-align: center;
  white-space: nowrap;
  z-index: 1; }

.bootstrap-switch-default {
  color: #333;
  background-color: #eee; }

.bootstrap-switch-primary {
  color: #fff;
  background-color: #2196F3; }

.bootstrap-switch-danger {
  color: #fff;
  background-color: #F44336; }

.bootstrap-switch-success {
  color: #fff;
  background-color: #4CAF50; }

.bootstrap-switch-warning {
  color: #fff;
  background-color: #FF7043; }

.bootstrap-switch-info {
  color: #fff;
  background-color: #00BCD4; }

/*rtl:begin:ignore*/
.bootstrap-switch-handle-on {
  border-top-left-radius: calc(0.1875rem - 1px);
  border-bottom-left-radius: calc(0.1875rem - 1px); }

.bootstrap-switch-handle-off {
  border-top-right-radius: calc(0.1875rem - 1px);
  border-bottom-right-radius: calc(0.1875rem - 1px); }

/*rtl:end:ignore*/
.bootstrap-switch-label {
  text-align: center;
  z-index: 100;
  background-color: #fff;
  position: relative;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset;
  transition: background-color ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .bootstrap-switch-label {
      transition: none; } }
  .bootstrap-switch-label:hover, .bootstrap-switch-label:active {
    background-color: #fafafa; }
  .bootstrap-switch-label:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.25rem;
    height: 0.625rem;
    margin-top: -0.3125rem;
    margin-left: -0.125rem;
    display: inline-block;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    border-right: 1px solid rgba(0, 0, 0, 0.15); }

.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch-large .bootstrap-switch-label {
  padding: 0.5625rem 1rem; }

.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch-small .bootstrap-switch-label {
  padding: 0.3125rem 0.75rem; }

.form-check-right .bootstrap-switch {
  margin-right: 0;
  margin-left: 0.625rem; }

/* ------------------------------------------------------------------------------
*
*  # Select2 selects
*
*  Styles for select2.js - custom select plugin
*
* ---------------------------------------------------------------------------- */
.select2-container {
  outline: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: left; }

.select2-selection--single {
  cursor: pointer;
  outline: 0;
  display: block;
  padding: 0.4375rem 0;
  line-height: 1.5385;
  color: #333;
  position: relative;
  border: 1px solid transparent;
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .select2-selection--single {
      transition: none; } }
  .select2-selection--single:hover, .select2-selection--single:focus,
  .select2-container--open .select2-selection--single {
    box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.01) inset; }
  .select2-selection--single:not([class*=bg-]) {
    background-color: #fff; }
    .select2-selection--single:not([class*=bg-]):not([class*=border-]) {
      border-color: #ddd; }
  .select2-selection--single[class*=bg-]:hover, .select2-selection--single[class*=bg-]:focus,
  .select2-container--open .select2-selection--single[class*=bg-] {
    box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.04) inset; }
  .select2-selection--single[class*=bg-] .select2-selection__placeholder {
    color: #fff; }
    .select2-container--disabled .select2-selection--single[class*=bg-] .select2-selection__placeholder {
      color: rgba(255, 255, 255, 0.75); }
  .select2-selection--single .select2-selection__rendered {
    display: block;
    padding-left: 0.875rem;
    padding-right: 2.375rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .select2-selection--single .select2-selection__rendered > i {
      margin-right: 0.625rem; }
  .select2-selection--single .select2-selection__clear {
    position: relative;
    cursor: pointer;
    float: right;
    font-size: 0;
    line-height: 1;
    margin-top: 0.12502rem;
    margin-left: 0.625rem;
    opacity: 0.75;
    transition: opacity ease-in-out 0.15s; }
    @media screen and (prefers-reduced-motion: reduce) {
      .select2-selection--single .select2-selection__clear {
        transition: none; } }
    .select2-selection--single .select2-selection__clear:hover {
      opacity: 1; }
    .select2-selection--single .select2-selection__clear:after {
      content: '\ed6b';
      font-family: "icomoon";
      display: inline-block;
      font-size: 1rem;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
  .select2-selection--single .select2-selection__placeholder {
    color: #999; }
  .select2-selection--single .select2-selection__arrow:after {
    content: '\e9c5';
    font-family: "icomoon";
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0.875rem;
    margin-top: -0.5rem;
    font-size: 1rem;
    line-height: 1;
    color: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .select2-selection--single .select2-selection__arrow b {
    display: none; }
  .select2-container--disabled .select2-selection--single {
    cursor: default;
    box-shadow: none; }
    .select2-container--disabled .select2-selection--single:not([class*=bg-]) {
      color: #999;
      background-color: #fafafa; }
    .select2-container--disabled .select2-selection--single[class*=bg-] {
      opacity: 0.75;
      box-shadow: none; }
    .select2-container--disabled .select2-selection--single .select2-selection__clear {
      display: none; }

.select2-selection--multiple {
  display: block;
  border: 1px solid transparent;
  cursor: text;
  outline: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 0.1875rem; }
  .select2-selection--multiple:not([class*=bg-]) {
    background-color: #fff; }
    .select2-selection--multiple:not([class*=bg-]):not([class*=border-]) {
      border-color: #ddd; }
  .select2-selection--multiple .select2-selection__rendered {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    list-style: none;
    margin: 0;
    padding: 0 0.125rem 0.125rem 0.125rem;
    width: 100%; }
  .select2-selection--multiple .select2-selection__placeholder {
    color: #999; }
  .select2-container--disabled .select2-selection--multiple:not([class*=bg-]) {
    background-color: #fafafa; }
  .select2-container--disabled .select2-selection--multiple[class*=bg-] {
    opacity: 0.75;
    box-shadow: none; }
    .select2-container--disabled .select2-selection--multiple[class*=bg-] .select2-selection__choice {
      opacity: 1; }
  .select2-container--disabled .select2-selection--multiple,
  .select2-container--disabled .select2-selection--multiple .select2-selection__choice,
  .select2-container--disabled .select2-selection--multiple .select2-search__field {
    cursor: default; }
  .select2-selection--multiple .select2-selection__choice {
    background-color: #455A64;
    color: #fff;
    cursor: default;
    float: left;
    margin-right: 0.125rem;
    margin-top: 0.125rem;
    padding: 0.3125rem 0.875rem;
    border-radius: 0.1875rem;
    transition: color ease-in-out 0.15s, background-color ease-in-out 0.15s; }
    @media screen and (prefers-reduced-motion: reduce) {
      .select2-selection--multiple .select2-selection__choice {
        transition: none; } }
    .select2-selection--multiple .select2-selection__choice:hover, .select2-selection--multiple .select2-selection__choice:focus {
      background-color: #455A64;
      color: #fff; }
    .select2-selection--multiple .select2-selection__choice > i {
      margin-right: 0.625rem; }
    .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
      cursor: pointer;
      float: right;
      font-size: 0.875rem;
      margin-top: 0.18752rem;
      line-height: 1;
      margin-left: 0.625rem;
      opacity: 0.75;
      transition: opacity ease-in-out 0.15s; }
      @media screen and (prefers-reduced-motion: reduce) {
        .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
          transition: none; } }
      .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:hover {
        opacity: 1; }
    .select2-container--disabled .select2-selection--multiple .select2-selection__choice {
      opacity: 0.6; }
      .select2-container--disabled .select2-selection--multiple .select2-selection__choice:hover, .select2-container--disabled .select2-selection--multiple .select2-selection__choice:focus {
        background-color: #455A64;
        color: #fff; }
      .select2-container--disabled .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
        display: none; }
  .select2-selection--multiple .select2-search--inline {
    float: left; }
    .select2-selection--multiple .select2-search--inline .select2-search__field {
      font-size: 100%;
      margin-top: 0.125rem;
      padding: 0.3125rem 0;
      background-color: transparent;
      border-width: 0;
      outline: 0;
      color: inherit;
      margin-left: 0.25rem;
      -webkit-appearance: textfield; }
      .select2-selection--multiple .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
        -webkit-appearance: none; }
    .select2-selection--multiple .select2-search--inline:first-child .select2-search__field {
      margin-left: 0;
      padding-left: 0.75rem; }

.select2-dropdown {
  background-color: #fff;
  color: #333;
  border: 1px solid rgba(0, 0, 0, 0.15);
  display: block;
  position: absolute;
  /*rtl:ignore*/
  left: -100000px;
  width: 100%;
  z-index: 1070;
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }
  .select2-results > .select2-results__options {
    padding-bottom: 0.5rem;
    max-height: 280px;
    overflow-y: auto; }
    .select2-search--hide + .select2-results > .select2-results__options {
      padding-top: 0.5rem; }
  .select2-results:first-child > .select2-results__options {
    padding-top: 0.5rem; }

.select2-results__option {
  padding: 0.5rem 1rem;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: all ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .select2-results__option {
      transition: none; } }
  .select2-results__option + .select2-results__option {
    margin-top: 1px; }
  .select2-results__option i {
    margin-right: 0.625rem; }
    .select2-results__option i.icon-undefined {
      display: none; }
  .select2-results__option[role=group] {
    padding: 0; }
  .select2-results__option.select2-results__option--highlighted {
    background-color: #f5f5f5;
    color: #333; }
  .select2-results__option[aria-disabled=true] {
    color: #999;
    cursor: default; }
    .select2-dropdown[class*=bg-] .select2-results__option[aria-disabled=true] {
      color: rgba(255, 255, 255, 0.6); }
  .select2-results__option[aria-selected=true] {
    color: #fff;
    background-color: #2196F3; }
  .select2-results__options--nested > .select2-results__option {
    padding-left: 2rem;
    padding-right: 2rem; }

.select2-results__group {
  display: block;
  padding: 0.5rem 1rem;
  cursor: default;
  font-weight: 500;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }
  .select2-results__option:first-child > .select2-results__group {
    margin-top: 0; }

.select2-results__message {
  color: #999;
  cursor: default; }
  .select2-dropdown[class*=bg-] .select2-results__message {
    color: rgba(255, 255, 255, 0.75); }

.select2-results__option.loading-results {
  padding-top: 0; }
  .select2-results__option.loading-results + .select2-results__option {
    margin-top: 0.5rem; }

.select2-results__option--load-more {
  text-align: center;
  margin-top: 0.5rem;
  cursor: default; }

.select2-container--open .select2-dropdown {
  /*rtl:ignore*/
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }
  .select2-container--open .select2-dropdown--above[class*=bg-] {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); }

.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  .select2-container--open .select2-dropdown--below[class*=bg-] {
    border-top: 1px solid rgba(255, 255, 255, 0.2); }

.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.select2-search--dropdown {
  display: block;
  position: relative;
  padding: 1rem; }
  .select2-search--dropdown:after {
    content: '\e98e';
    font-family: "icomoon";
    position: absolute;
    top: 50%;
    left: 1.875rem;
    color: inherit;
    display: block;
    font-size: 0.8125rem;
    margin-top: -0.40625rem;
    line-height: 1;
    opacity: 0.6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .select2-search--dropdown + .select2-results .select2-results__message:first-child {
    padding-top: 0; }
  .select2-search--dropdown .select2-search__field {
    padding: 0.4375rem 0.875rem;
    padding-left: 2.5625rem;
    border: 1px solid #ddd;
    outline: 0;
    width: 100%;
    border-radius: 0.1875rem; }
    .select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
      -webkit-appearance: none; }
  .select2-search--dropdown.select2-search--hide {
    display: none; }

.select-lg.select2-selection--single {
  padding: 0.5625rem 0;
  font-size: 0.875rem;
  line-height: 1.4286; }
  .select-lg.select2-selection--single .select2-selection__rendered {
    padding-left: 1rem;
    padding-right: 2.5rem; }
  .select-lg.select2-selection--single .select2-selection__arrow:after {
    right: 1rem; }

.select-lg.select2-selection--multiple .select2-selection__choice {
  padding: 0.4375rem 1rem;
  font-size: 0.875rem;
  line-height: 1.4286; }

.select-lg.select2-selection--multiple .select2-search--inline .select2-search__field {
  padding: 0.4375rem 0;
  font-size: 0.875rem;
  line-height: 1.4286; }

.select-sm.select2-selection--single {
  padding: 0.3125rem 0;
  font-size: 0.75rem;
  line-height: 1.6667; }
  .select-sm.select2-selection--single .select2-selection__rendered {
    padding-left: 0.75rem;
    padding-right: 2.375rem; }
  .select-sm.select2-selection--single .select2-selection__arrow:after {
    right: 0.75rem; }

.select-sm.select2-selection--multiple .select2-selection__choice {
  padding: 0.1875rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.6667; }

.select-sm.select2-selection--multiple .select2-search--inline .select2-search__field {
  padding: 0.1875rem 0;
  font-size: 0.75rem;
  line-height: 1.6667; }

.select2-selection--multiple[class*=bg-] .select2-selection__choice {
  background-color: rgba(0, 0, 0, 0.2); }

.select2-dropdown[class*=bg-] .select2-search--dropdown .select2-search__field {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: transparent;
  color: #fff; }

.select2-dropdown[class*=bg-] .select2-results__option[aria-selected=true] {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff; }

.select2-dropdown[class*=bg-] .select2-results__option--highlighted {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  z-index: 99;
  background-color: #fff;
  opacity: 0; }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: fixed !important;
  width: 1px !important; }

.select2-result-repository {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem; }

.select2-result-repository__avatar {
  float: left;
  width: 60px;
  margin-right: 0.9375rem; }
  .select2-result-repository__avatar img {
    width: 100%;
    height: auto;
    border-radius: 100px; }

.select2-result-repository__meta {
  margin-left: 70px; }

.select2-result-repository__title {
  font-weight: 500;
  word-wrap: break-word;
  margin-bottom: 2px; }

.select2-result-repository__forks,
.select2-result-repository__stargazers,
.select2-result-repository__watchers {
  display: inline-block;
  font-size: 0.75rem; }

.select2-result-repository__description {
  font-size: 0.75rem; }

.select2-result-repository__forks,
.select2-result-repository__stargazers {
  margin-right: 0.9375rem; }

/* ------------------------------------------------------------------------------
*
*  # Bootstrap multiselect
*
*  Styles for multiselect.js - custom multiple select plugin
*
* ---------------------------------------------------------------------------- */
.multiselect-native-select {
  position: relative; }
  .multiselect-native-select select {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 0 !important;
    margin: 0 !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 0 !important;
    left: 0;
    top: 0; }

.multiselect {
  width: 100%;
  text-align: left;
  padding-left: 0.875rem;
  padding-right: 2.375rem;
  text-transform: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
  .multiselect:after {
    position: absolute;
    top: 50%;
    right: 0.875rem;
    margin-top: -0.34375rem;
    text-align: right; }
  .multiselect:not([class*=font-weight-]) {
    font-weight: 400; }
  .multiselect.btn-light {
    background-color: #fff;
    border-color: #ddd; }
    .multiselect.btn-light:hover, .multiselect.btn-light:focus, .multiselect.btn-light:not([disabled]):not(.disabled):active,
    .btn-group.show .multiselect.btn-light {
      background-color: #fff;
      box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.01) inset; }
    .multiselect.btn-light.disabled {
      background-color: #fafafa; }
  .multiselect.btn-lg, .btn-group-lg > .multiselect.btn {
    padding-left: 1rem;
    padding-right: 2.5rem; }
    .multiselect.btn-lg:after, .btn-group-lg > .multiselect.btn:after {
      right: 1rem; }
  .multiselect.btn-sm, .btn-group-sm > .multiselect.btn {
    padding-left: 0.75rem;
    padding-right: 2.25rem; }
    .multiselect.btn-sm:after, .btn-group-sm > .multiselect.btn:after {
      right: 0.75rem; }

.multiselect-container {
  max-height: 280px;
  overflow-y: auto;
  width: 100%; }

.multiselect-item input[type=checkbox],
.multiselect-item input[type=radio] {
  border: 0;
  background: none;
  display: block;
  margin: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 1rem;
  margin-top: -0.625rem;
  opacity: 0;
  z-index: 2;
  width: 1.25rem;
  height: 1.25rem; }
  .multiselect-item input[type=checkbox]:disabled,
  .multiselect-item input[type=radio]:disabled {
    cursor: default; }

.multiselect-item input[type=checkbox] ~ .form-check-control-indicator,
.multiselect-item input[type=radio] ~ .form-check-control-indicator {
  position: absolute;
  left: 1rem;
  top: 50%;
  margin-top: -0.625rem;
  border: 0.125rem solid #455A64;
  width: 1.25rem;
  height: 1.25rem; }

.multiselect-item input[type=checkbox] ~ .form-check-control-indicator {
  color: #455A64;
  border-radius: 0.125rem;
  transition: border-color ease-in-out 0.15s, color ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .multiselect-item input[type=checkbox] ~ .form-check-control-indicator {
      transition: none; } }
  .multiselect-item input[type=checkbox] ~ .form-check-control-indicator:after {
    content: "\e600";
    font-family: "icomoon";
    font-size: 1rem;
    position: absolute;
    top: 0rem;
    left: 0rem;
    line-height: 1;
    opacity: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: opacity ease-in-out 0.15s; }
    @media screen and (prefers-reduced-motion: reduce) {
      .multiselect-item input[type=checkbox] ~ .form-check-control-indicator:after {
        transition: none; } }

.multiselect-item input[type=radio] ~ .form-check-control-indicator {
  border-radius: 100px;
  transition: border-color ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .multiselect-item input[type=radio] ~ .form-check-control-indicator {
      transition: none; } }
  .multiselect-item input[type=radio] ~ .form-check-control-indicator:after {
    content: "";
    position: absolute;
    top: 0.1875rem;
    left: 0.1875rem;
    border: 0.3125rem solid;
    border-color: inherit;
    width: 0;
    height: 0;
    border-radius: 100px;
    opacity: 0;
    transition: all ease-in-out 0.15s; }
    @media screen and (prefers-reduced-motion: reduce) {
      .multiselect-item input[type=radio] ~ .form-check-control-indicator:after {
        transition: none; } }

.multiselect-item input[type=checkbox]:checked ~ .form-check-control-indicator:after,
.multiselect-item input[type=radio]:checked ~ .form-check-control-indicator:after {
  opacity: 1; }

.multiselect-item.active:not(.disabled) input[type=checkbox] ~ .form-check-control-indicator {
  border-color: #fff;
  color: #fff; }

.multiselect-item.active:not(.disabled) input[type=radio] ~ .form-check-control-indicator {
  border-color: #fff; }

.multiselect-item.disabled .form-check-control-indicator {
  opacity: 0.5; }

.dropdown-menu[class*=bg-] .multiselect-item .form-check-control-indicator {
  border-color: #fff;
  color: #fff; }

.multiselect-group {
  padding: 0.5rem 1rem;
  font-weight: 500;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }
  .multiselect-group:first-child {
    margin-top: 0; }
  .multiselect-group label {
    margin-bottom: 0; }
  .multiselect-group.disabled {
    color: #999;
    cursor: default; }

.multiselect-all {
  font-weight: 500; }

.multiselect-group-clickable label {
  cursor: pointer; }

.multiselect-filter {
  position: relative;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem; }
  .multiselect-filter .input-group > i {
    font-size: 0.8125rem;
    position: absolute;
    left: 0.9375rem;
    top: 50%;
    margin-top: -0.40625rem;
    opacity: 0.5;
    z-index: 4; }
  .multiselect-filter .form-control {
    padding-left: 2.5625rem; }

.input-group .multiselect-native-select {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto; }
  .input-group .multiselect-native-select:not(:first-child) .multiselect {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group .multiselect-native-select:not(:last-child) .multiselect {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

/* ------------------------------------------------------------------------------
*
*  # Passy
*
*  Styles for passy.min.js - password length checker and password generator
*
* ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
*
*  # Twiter Typeahead
*
*  Styles for typeahead.bundle.min.js - input suggestion engine
*
* ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
*
*  # Form Validation
*
*  Styles for validate.min.js - jQuery plugin for simple clientside form validation
*
* ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
*
*  # Floating labels
*
*  Styles for custom Floating Labels extensions
*
* ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
*
*  # Alpaca forms
*
*  Styles for alpaca.min.js - the easiest way to generate interactive HTML5 forms for web applications
*
* ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
*
*  # Tokenfield for Bootstrap
*
*  Styles for tokenfield.js - Advanced tagging/tokenizing plugin for Bootstrap
*
* ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
*
*  # Bootstrap Duallistbox
*
*  Styles for listbox.js - A responsive dual listbox widget optimized for Bootstrap
*
* ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
*
*  # Steps wizard
*
*  Styles for steps.min.js - An all-in-one wizard plugin that is extremely flexible, compact and feature-rich
*
* ---------------------------------------------------------------------------- */
.wizard {
  width: 100%; }
  .wizard > .steps .current-info,
  .wizard > .content > .title {
    display: none; }
  .wizard > .content {
    position: relative;
    width: auto;
    padding: 0; }
    .wizard > .content > .body {
      padding: 0 1.25rem; }
    .wizard > .content > iframe {
      border: 0;
      width: 100%;
      height: 100%; }

.wizard > .steps {
  position: relative;
  display: block;
  width: 100%; }
  .wizard > .steps > ul {
    display: table;
    width: 100%;
    table-layout: fixed;
    margin: 0;
    padding: 0;
    list-style: none; }
    .wizard > .steps > ul > li {
      display: table-cell;
      width: auto;
      vertical-align: top;
      text-align: center;
      position: relative; }
      .wizard > .steps > ul > li a {
        position: relative;
        padding-top: 3rem;
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
        display: block;
        outline: 0;
        color: #999; }
      .wizard > .steps > ul > li:before, .wizard > .steps > ul > li:after {
        content: '';
        display: block;
        position: absolute;
        top: 2.375rem;
        width: 50%;
        height: 2px;
        background-color: #00BCD4;
        z-index: 9; }
      .wizard > .steps > ul > li:before {
        left: 0; }
      .wizard > .steps > ul > li:after {
        right: 0; }
      .wizard > .steps > ul > li:first-child:before, .wizard > .steps > ul > li:last-child:after {
        content: none; }
      .wizard > .steps > ul > li.current:after,
      .wizard > .steps > ul > li.current ~ li:before,
      .wizard > .steps > ul > li.current ~ li:after {
        background-color: #eee; }
      .wizard > .steps > ul > li.current > a {
        color: #333;
        cursor: default; }
      .wizard > .steps > ul > li.current .number {
        font-size: 0;
        border-color: #00BCD4;
        background-color: #fff;
        color: #00BCD4; }
        .wizard > .steps > ul > li.current .number:after {
          content: '\e913';
          font-family: "icomoon";
          display: inline-block;
          font-size: 1rem;
          line-height: 2.125rem;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          transition: all ease-in-out 0.15s; }
          @media screen and (prefers-reduced-motion: reduce) {
            .wizard > .steps > ul > li.current .number:after {
              transition: none; } }
      .wizard > .steps > ul > li.disabled a {
        cursor: default; }
      .wizard > .steps > ul > li.done a, .wizard > .steps > ul > li.done a:hover, .wizard > .steps > ul > li.done a:focus {
        color: #999; }
      .wizard > .steps > ul > li.done .number {
        font-size: 0;
        background-color: #00BCD4;
        border-color: #00BCD4;
        color: #fff; }
        .wizard > .steps > ul > li.done .number:after {
          content: '\ed6f';
          font-family: "icomoon";
          display: inline-block;
          font-size: 1rem;
          line-height: 2.125rem;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          transition: all ease-in-out 0.15s; }
          @media screen and (prefers-reduced-motion: reduce) {
            .wizard > .steps > ul > li.done .number:after {
              transition: none; } }
      .wizard > .steps > ul > li.error .number {
        border-color: #F44336;
        color: #F44336; }
    .card > .card-header:not([class*=bg-]) > .wizard > .steps > ul {
      border-top: 1px solid rgba(0, 0, 0, 0.125); }
    @media (max-width: 991.98px) {
      .wizard > .steps > ul {
        margin-bottom: 1.25rem; }
        .wizard > .steps > ul > li {
          display: block;
          float: left;
          width: 50%; }
          .wizard > .steps > ul > li > a {
            margin-bottom: 0; }
          .wizard > .steps > ul > li:first-child:before, .wizard > .steps > ul > li:last-child:after {
            content: ''; }
          .wizard > .steps > ul > li:last-child:after {
            background-color: #00BCD4; } }
    @media (max-width: 767.98px) {
      .wizard > .steps > ul > li {
        width: 100%; }
        .wizard > .steps > ul > li.current:after {
          background-color: #00BCD4; } }
  .wizard > .steps .number {
    background-color: #fff;
    color: #ccc;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1.1875rem;
    border: 2px solid #eee;
    font-size: 0.875rem;
    z-index: 10;
    line-height: 2.125rem;
    text-align: center;
    width: 2.375rem;
    height: 2.375rem;
    border-radius: 50%; }

.wizard > .actions {
  position: relative;
  text-align: right;
  padding: 1.25rem;
  padding-top: 0; }
  .wizard > .actions > ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .wizard > .actions > ul::after {
      display: block;
      clear: both;
      content: ""; }
    .wizard > .actions > ul > li {
      display: inline-block; }
      .wizard > .actions > ul > li + li {
        margin-left: 1.25rem; }

/* ------------------------------------------------------------------------------
*
*  # Summernote editor
*
*  Styles for summernote.min.js - super simple WYSIWYG Editor for Bootstrap framework
*
* ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
*
*  # Trumbowyg editor
*
*  Styles for trumbowyg.min.js - a lightweight WYSIWYG editor
*
* ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
*
*  # Ace code editor
*
*  Styles Ace - an embeddable code editor written in JavaScript
*
* ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
*
*  # Spectrum color picker
*
*  Flexible and powerful jQuery colorpicker library
*
* ---------------------------------------------------------------------------- */
.sp-sat,
.sp-val,
.sp-top-inner,
.sp-color,
.sp-hue,
.sp-clear-enabled .sp-clear,
.sp-preview-inner,
.sp-alpha-inner,
.sp-thumb-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.sp-container.sp-input-disabled .sp-input-container,
.sp-container.sp-buttons-disabled .sp-button-container,
.sp-container.sp-palette-buttons-disabled .sp-palette-button-container,
.sp-palette-only .sp-picker-container,
.sp-palette-disabled .sp-palette-container,
.sp-initial-disabled .sp-initial {
  display: none; }

.sp-hidden {
  display: none !important; }

.sp-cf::after {
  display: block;
  clear: both;
  content: ""; }

.sp-preview,
.sp-alpha,
.sp-thumb-el {
  position: relative;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==); }

.sp-preview-inner,
.sp-alpha-inner,
.sp-thumb-inner {
  display: block; }

.sp-container {
  position: absolute;
  top: 0;
  /*rtl:ignore*/
  left: 0;
  display: inline-block;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  overflow: hidden;
  box-sizing: content-box;
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); }
  .sp-container.sp-flat {
    position: relative;
    overflow-x: auto;
    max-width: 100%;
    white-space: nowrap;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }

.sp-picker-container,
.sp-palette-container {
  display: block;
  white-space: nowrap;
  vertical-align: top;
  position: relative;
  padding: 0.625rem; }
  @media (min-width: 576px) {
    .sp-picker-container,
    .sp-palette-container {
      display: inline-block; } }

.sp-picker-container {
  width: 13.75rem; }

.sp-container,
.sp-replacer,
.sp-preview,
.sp-dragger,
.sp-slider,
.sp-alpha,
.sp-clear,
.sp-alpha-handle,
.sp-container.sp-dragging .sp-input,
.sp-container button {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.sp-top {
  position: relative;
  width: 100%;
  display: block; }

.sp-color {
  right: 20%; }

.sp-hue {
  left: 85%;
  height: 100%; }

.sp-clear-enabled .sp-hue {
  top: 2.5rem;
  height: 75%; }

.sp-fill {
  padding-top: 80%; }

.sp-alpha-enabled .sp-top {
  margin-bottom: 1.625rem; }

.sp-alpha-enabled .sp-alpha {
  display: block; }

.sp-alpha-handle {
  position: absolute;
  top: -0.25rem;
  bottom: -0.25rem;
  width: 0.3125rem;
  /*rtl:ignore*/
  left: 50%;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 0.25rem; }

.sp-alpha {
  display: none;
  position: absolute;
  bottom: -1rem;
  right: 0;
  left: 0;
  height: 0.375rem; }

.sp-clear {
  display: none; }

.sp-clear-display {
  cursor: pointer; }
  .sp-clear-display:after {
    content: '\ee6e';
    display: block;
    font-family: "icomoon";
    font-size: 1rem;
    line-height: 1.875rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .sp-preview .sp-clear-display:after,
  .sp-initial .sp-clear-display:after {
    content: none; }

.sp-clear-enabled .sp-clear {
  display: block;
  left: 85%;
  height: 1.875rem;
  text-align: center;
  color: #999;
  box-shadow: 0 0 0 1px #ddd inset; }

.sp-input-container {
  margin-top: 0.625rem; }
  .sp-initial-disabled .sp-input-container {
    width: 100%; }

.sp-input {
  border: 1px solid #ddd;
  padding: 0.4375rem 0.875rem;
  width: 100%;
  background-color: #fff;
  outline: 0;
  color: #333;
  border-radius: 0.1875rem; }

.sp-initial {
  margin-top: 0.625rem; }
  .sp-initial span {
    width: 50%;
    height: 1.563rem;
    display: block;
    float: left; }
    .sp-initial span .sp-thumb-inner {
      height: 1.563rem;
      width: 100%;
      display: block; }

.sp-dragger {
  border: 1px solid #fff;
  background-color: #333;
  cursor: pointer;
  position: absolute;
  top: 0;
  /*rtl:ignore*/
  left: 0;
  border-radius: 50%;
  width: 0.375rem;
  height: 0.375rem; }

.sp-slider {
  position: absolute;
  top: 0;
  cursor: pointer;
  height: 0.25rem;
  left: -0.125rem;
  right: -0.125rem;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 0.1875rem; }

.sp-replacer {
  overflow: hidden;
  cursor: pointer;
  padding: 0.3125rem;
  display: inline-block;
  border: 1px solid #ddd;
  background-color: #fafafa;
  color: #999;
  vertical-align: middle;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .sp-replacer {
      transition: none; } }
  .sp-replacer:not([class*=bg-]):hover, .sp-replacer:not([class*=bg-]).sp-active {
    background-color: #f5f5f5;
    color: #333; }
  .sp-replacer[class*=bg-], .sp-replacer[class*=bg-]:hover, .sp-replacer[class*=bg-]:focus {
    border-color: transparent; }

.sp-replacer.sp-disabled {
  cursor: default;
  opacity: 0.8; }
  .sp-replacer.sp-disabled:hover, .sp-replacer.sp-disabled:focus {
    background-color: #fafafa;
    color: #999; }

.sp-preview {
  position: relative;
  width: 1.625rem;
  height: 1.5rem;
  margin-right: 0.3125rem;
  float: left;
  z-index: 0; }
  .sp-preview,
  .sp-preview .sp-preview-inner {
    border-radius: 0.1875rem; }
  .sp-preview .sp-preview-inner,
  .sp-preview .sp-clear-display {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05); }
  .sp-replacer[class*=bg-] .sp-preview .sp-preview-inner {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5); }

.sp-dd {
  float: left;
  font-size: 0;
  position: relative;
  margin: 0.25rem; }
  .sp-dd:after {
    content: '\e9c5';
    display: block;
    font-family: "icomoon";
    font-size: 1rem;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

/*rtl:begin:ignore*/
.sp-sat {
  background-image: linear-gradient(to right, #fff, rgba(204, 154, 129, 0));
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType = 1, startColorstr=#FFFFFFFF, endColorstr=#00CC9A81)";
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType = 1, startColorstr='#FFFFFFFF', endColorstr='#00CC9A81');
  box-shadow: 0 0 0 1px #ccc inset; }

.sp-val {
  background-image: linear-gradient(to top, #000, rgba(204, 154, 129, 0));
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00CC9A81, endColorstr=#FF000000)";
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00CC9A81', endColorstr='#FF000000'); }

.sp-hue {
  background: linear-gradient(to bottom, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%); }

/*rtl:end:ignore*/
/*rtl:begin:ignore*/
.sp-1 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#ffff00'); }

.sp-2 {
  height: 16%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffff00', endColorstr='#00ff00'); }

.sp-3 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ff00', endColorstr='#00ffff'); }

.sp-4 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffff', endColorstr='#0000ff'); }

.sp-5 {
  height: 16%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0000ff', endColorstr='#ff00ff'); }

.sp-6 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff00ff', endColorstr='#ff0000'); }

/*rtl:end:ignore*/
.sp-palette {
  max-width: 13.75rem; }

.sp-thumb-el {
  position: relative; }
  .sp-palette .sp-thumb-el {
    display: inline-block;
    position: relative;
    cursor: pointer; }
  .sp-thumb-el .sp-thumb-inner {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset; }
    .sp-thumb-el .sp-thumb-inner:hover {
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25) inset; }
  .sp-palette .sp-thumb-el {
    width: 1rem;
    height: 1rem; }
    .sp-palette .sp-thumb-el + .sp-thumb-el {
      margin-left: 0.3125rem; }
    .sp-palette .sp-thumb-el.sp-thumb-active {
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1) inset; }
      .sp-palette .sp-thumb-el.sp-thumb-active .sp-thumb-inner {
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25) inset; }

.sp-palette .sp-thumb-active.sp-thumb-dark .sp-thumb-inner:after, .sp-palette .sp-thumb-active.sp-thumb-light .sp-thumb-inner:after {
  content: '\e600';
  display: block;
  font-family: "icomoon";
  font-size: 1rem;
  line-height: 1;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.sp-palette .sp-thumb-active.sp-thumb-light .sp-thumb-inner:after {
  color: #333; }

.sp-palette-row {
  font-size: 0; }
  .sp-palette-row + .sp-palette-row {
    margin-top: 0.3125rem; }
  .sp-palette-row:empty {
    margin-top: 0; }

.sp-cancel,
.sp-choose,
.sp-palette-toggle {
  border: 0;
  padding: 0.4375rem 0.875rem;
  float: left;
  width: 48%;
  text-align: center;
  cursor: pointer;
  border-radius: 0.1875rem;
  transition: all ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .sp-cancel,
    .sp-choose,
    .sp-palette-toggle {
      transition: none; } }
  .sp-cancel:focus,
  .sp-choose:focus,
  .sp-palette-toggle:focus {
    outline: 0; }

.sp-palette-button-container,
.sp-button-container {
  margin-top: 0.625rem;
  text-align: center; }
  .sp-container[class*=bg-] .sp-palette-button-container a,
  .sp-container[class*=bg-] .sp-palette-button-container button, .sp-container[class*=bg-]
  .sp-button-container a,
  .sp-container[class*=bg-]
  .sp-button-container button {
    background-color: rgba(0, 0, 0, 0.25);
    color: #fff;
    border-color: transparent; }
  .sp-container[class*=bg-] .sp-palette-button-container .sp-cancel:hover, .sp-container[class*=bg-] .sp-palette-button-container .sp-cancel:focus,
  .sp-container[class*=bg-] .sp-palette-button-container .sp-palette-toggle:hover,
  .sp-container[class*=bg-] .sp-palette-button-container .sp-palette-toggle:focus, .sp-container[class*=bg-]
  .sp-button-container .sp-cancel:hover, .sp-container[class*=bg-]
  .sp-button-container .sp-cancel:focus,
  .sp-container[class*=bg-]
  .sp-button-container .sp-palette-toggle:hover,
  .sp-container[class*=bg-]
  .sp-button-container .sp-palette-toggle:focus {
    box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.075) inset; }

.sp-cancel,
.sp-palette-toggle {
  border: 1px solid transparent; }
  .sp-cancel:hover, .sp-cancel:focus,
  .sp-palette-toggle:hover,
  .sp-palette-toggle:focus {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd; }

.sp-choose {
  border: 1px solid transparent; }
  .sp-choose:hover, .sp-choose:focus {
    box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.075) inset; }

.sp-cancel,
.sp-palette-toggle {
  color: #333;
  margin-right: 0.3125rem;
  background-color: #fafafa;
  margin-right: 4%;
  border-color: #ddd; }
  .sp-cancel:hover, .sp-cancel:focus,
  .sp-palette-toggle:hover,
  .sp-palette-toggle:focus {
    color: #333; }

.sp-palette-toggle {
  margin-right: 0;
  width: auto;
  float: none; }

.sp-choose {
  color: #fff;
  background-color: #607D8B; }

/* ------------------------------------------------------------------------------
 *
 *  # Bootstrap file input
 *
 *  Styles for fileinput.min.js - an enhanced HTML 5 file input for Bootstrap
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
*
*  # PNotify notifications
*
*  Styles for pnotify.min.js - a flexible JavaScript notification plugin
*
* ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # jQuery UI Interactions
 *
 *  Separate styles for jQuery UI library. Component's interactions
 *
 * ---------------------------------------------------------------------------- */
.ui-draggable-handle,
.ui-sortable-handle {
  -ms-touch-action: none;
      touch-action: none; }

.ui-sortable .ui-state-disabled {
  color: #999;
  cursor: default;
  opacity: 0.75; }

.sortable-placeholder {
  position: relative; }
  .sortable-placeholder:before {
    content: '';
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    top: 0;
    border: 1px dashed rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%; }

.card + .sortable-placeholder {
  margin-bottom: 1.25rem; }
  .sidebar .card + .sortable-placeholder {
    margin-bottom: 0; }

.sortable-placeholder + .card {
  margin-top: 1.25rem; }
  .sidebar .sortable-placeholder + .card {
    margin-top: 0; }

.card-group > .sortable-placeholder:before {
  border-radius: 0.1875rem; }

.sidebar .sortable-placeholder:before {
  border-left: 0;
  border-right: 0; }

.sidebar-dark .sortable-placeholder:before {
  background-color: rgba(0, 0, 0, 0.5); }

.sidebar-dark .card:not([class*=bg-]):not(.fixed-top).ui-sortable-helper {
  background-color: rgba(0, 0, 0, 0.5); }

.sidebar-light .sortable-placeholder:before {
  background-color: #fafafa; }

.sidebar-light .card:not([class*=bg-]):not(.fixed-top).ui-sortable-helper {
  background-color: #fff; }

.table .ui-sortable-helper {
  width: 100%;
  background-color: transparent;
  display: table; }

.table .sortable-placeholder {
  margin: 0; }
  .table .sortable-placeholder:before {
    content: none; }

.table.ui-sortable {
  position: relative; }

.ui-resizable-handle {
  position: absolute;
  font-size: 0;
  display: block;
  -ms-touch-action: none;
      touch-action: none; }
  .ui-resizable-handle.ui-icon {
    display: inline-block;
    border-style: solid;
    border-width: 0 0 0.375rem 0.375rem;
    border-color: transparent transparent #333 transparent; }
  .ui-resizable-disabled .ui-resizable-handle,
  .ui-resizable-autohide .ui-resizable-handle {
    display: none; }

.ui-resizable-n {
  cursor: n-resize;
  height: 0.4375rem;
  width: 100%;
  top: -0.3125rem;
  left: 0; }

.ui-resizable-s {
  cursor: s-resize;
  height: 0.4375rem;
  width: 100%;
  bottom: -0.3125rem;
  left: 0; }

.ui-resizable-e {
  cursor: e-resize;
  width: 0.4375rem;
  right: -0.3125rem;
  top: 0;
  height: 100%; }

.ui-resizable-w {
  cursor: w-resize;
  width: 0.4375rem;
  left: -0.3125rem;
  top: 0;
  height: 100%; }

.ui-resizable-se {
  cursor: se-resize;
  right: 0.0625rem;
  bottom: 0.0625rem; }

.ui-resizable-sw {
  cursor: sw-resize;
  width: 0.5625rem;
  height: 0.5625rem;
  left: -0.3125rem;
  bottom: -0.3125rem; }

.ui-resizable-nw {
  cursor: nw-resize;
  width: 0.5625rem;
  height: 0.5625rem;
  left: -0.3125rem;
  top: -0.3125rem; }

.ui-resizable-ne {
  cursor: ne-resize;
  width: 0.5625rem;
  height: 0.5625rem;
  right: -0.3125rem;
  top: -0.3125rem; }

.ui-selectable {
  -ms-touch-action: none;
      touch-action: none; }

.ui-selectable-helper {
  position: absolute;
  z-index: 100;
  border: 1px dashed #333; }

/* ------------------------------------------------------------------------------
 *
 *  # jQuery UI Widgets
 *
 *  Styles for jQuery UI widgets
 *
 * ---------------------------------------------------------------------------- */
.ui-accordion .ui-accordion-header {
  display: block;
  cursor: pointer;
  margin: 0;
  outline: 0;
  position: relative;
  background-color: #fff;
  padding: 0.9375rem 1.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-top: 0.5rem;
  border-radius: 0.1875rem; }
  .ui-accordion .ui-accordion-header:first-child {
    margin-top: 0; }
  .ui-accordion .ui-accordion-header.ui-accordion-header-active {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .ui-accordion .ui-accordion-header .ui-accordion-header-icon {
    position: absolute;
    top: 50%;
    right: 1.25rem;
    margin-top: -0.5rem; }
    .ui-accordion .ui-accordion-header .ui-accordion-header-icon:before {
      content: '\e9b8';
      font-family: "icomoon";
      display: block;
      width: 1rem;
      font-size: 1rem;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }

.ui-accordion .ui-accordion-header-active .ui-accordion-header-icon:before {
  content: '\e9b7'; }

.ui-accordion .ui-accordion-icons {
  padding-right: 3.5rem; }

.ui-accordion .ui-accordion-content {
  padding: 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top: 0;
  overflow: auto;
  border-bottom-right-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem; }

.accordion-sortable-group + .accordion-sortable-group {
  margin-top: 0.5rem; }

.ui-autocomplete {
  position: absolute;
  display: none;
  padding: 0.5rem 0;
  z-index: 1000;
  max-height: 250px;
  overflow: auto; }
  .ui-autocomplete .ui-autocomplete-category {
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    line-height: 1.6667;
    text-transform: uppercase;
    font-weight: 700; }
    .ui-autocomplete .ui-autocomplete-category ~ .ui-menu-item .ui-menu-item-wrapper {
      padding-left: 2rem; }

.ui-autocomplete-processing:after {
  content: '\eb51';
  font-family: "icomoon";
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0.875rem;
  width: 1rem;
  text-align: right;
  margin-top: -0.5rem;
  font-size: 1rem;
  line-height: 1;
  color: inherit;
  -webkit-animation: rotation 1s linear infinite;
          animation: rotation 1s linear infinite;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.input-group .ui-autocomplete-input + .input-group-append > .btn,
.input-group .ui-autocomplete-input + .input-group-append > .ui-button,
.input-group .ui-datepicker-buttonpane .ui-autocomplete-input + .input-group-append > button,
.ui-datepicker-buttonpane .input-group .ui-autocomplete-input + .input-group-append > button {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.ui-button, .ui-datepicker-buttonpane button {
  position: relative;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  background-color: #fafafa;
  border: 1px solid #ddd;
  color: #333;
  padding: 0.4375rem 0.875rem;
  font-size: 0.8125rem;
  line-height: 1.5385;
  border-radius: 0.1875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .ui-button, .ui-datepicker-buttonpane button {
      transition: none; } }
  .ui-button:focus, .ui-datepicker-buttonpane button:focus {
    outline: 0; }
  .ui-button:not([class*=bg-]):hover, .ui-datepicker-buttonpane button:not([class*=bg-]):hover, .ui-button:not([class*=bg-]):focus, .ui-datepicker-buttonpane button:not([class*=bg-]):focus, .ui-button:not([class*=bg-]):active, .ui-datepicker-buttonpane button:not([class*=bg-]):active {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd; }
  .ui-button.ui-state-disabled, .ui-datepicker-buttonpane button.ui-state-disabled {
    cursor: default;
    opacity: 0.65;
    box-shadow: none; }
  .ui-button[class*=bg-]:not(.bg-transparent):not(.bg-white):not(.bg-light):hover, .ui-datepicker-buttonpane button[class*=bg-]:not(.bg-transparent):not(.bg-white):not(.bg-light):hover, .ui-button[class*=bg-]:not(.bg-transparent):not(.bg-white):not(.bg-light):focus, .ui-datepicker-buttonpane button[class*=bg-]:not(.bg-transparent):not(.bg-white):not(.bg-light):focus {
    color: #fff;
    box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.075) inset; }
  .ui-button[class*=bg-]:not(.bg-transparent):not(.bg-white):not(.bg-light):active, .ui-datepicker-buttonpane button[class*=bg-]:not(.bg-transparent):not(.bg-white):not(.bg-light):active {
    box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.075) inset; }
  .ui-button.ui-button-link, .ui-datepicker-buttonpane button.ui-button-link {
    background-color: transparent;
    border-color: transparent; }
    .ui-button.ui-button-link:hover, .ui-datepicker-buttonpane button.ui-button-link:hover, .ui-button.ui-button-link:focus, .ui-datepicker-buttonpane button.ui-button-link:focus {
      color: #0a6ebd; }
    .ui-button.ui-button-link:hover, .ui-datepicker-buttonpane button.ui-button-link:hover, .ui-button.ui-button-link:focus, .ui-datepicker-buttonpane button.ui-button-link:focus, .ui-button.ui-button-link:active, .ui-datepicker-buttonpane button.ui-button-link:active {
      box-shadow: none; }
  .ui-button::-moz-focus-inner, .ui-datepicker-buttonpane button::-moz-focus-inner {
    border: 0;
    padding: 0; }
  .ui-button .ui-button-icon-space, .ui-datepicker-buttonpane button .ui-button-icon-space {
    display: inline-block;
    width: 0.625rem; }

.ui-button-icon-only {
  text-indent: -9999px;
  padding-left: 1.06252rem;
  padding-right: 1.06252rem;
  overflow: hidden; }
  .ui-button-icon-only .ui-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    text-indent: 0;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .ui-button-icon-only .ui-button-icon-space,
  .ui-button-icon-only .ui-button-text {
    display: none; }

.ui-controlgroup {
  display: inline-block;
  vertical-align: middle;
  position: relative; }
  .ui-controlgroup .ui-button, .ui-controlgroup .ui-datepicker-buttonpane button, .ui-datepicker-buttonpane .ui-controlgroup button {
    border-radius: 0;
    margin-left: -1px;
    float: left; }
  .ui-controlgroup .ui-button:first-child, .ui-controlgroup .ui-datepicker-buttonpane button:first-child, .ui-datepicker-buttonpane .ui-controlgroup button:first-child,
  .ui-controlgroup .ui-helper-hidden-accessible:first-child + .ui-button,
  .ui-controlgroup .ui-datepicker-buttonpane .ui-helper-hidden-accessible:first-child + button,
  .ui-datepicker-buttonpane .ui-controlgroup .ui-helper-hidden-accessible:first-child + button {
    margin-left: 0; }
  .ui-controlgroup .ui-button:first-child, .ui-controlgroup .ui-datepicker-buttonpane button:first-child, .ui-datepicker-buttonpane .ui-controlgroup button:first-child,
  .ui-controlgroup .ui-helper-hidden-accessible:first-child + .ui-button:not(:last-child),
  .ui-controlgroup .ui-datepicker-buttonpane .ui-helper-hidden-accessible:first-child + button:not(:last-child),
  .ui-datepicker-buttonpane .ui-controlgroup .ui-helper-hidden-accessible:first-child + button:not(:last-child) {
    border-top-left-radius: 0.1875rem;
    border-bottom-left-radius: 0.1875rem; }
  .ui-controlgroup .ui-button:last-of-type, .ui-controlgroup .ui-datepicker-buttonpane button:last-of-type, .ui-datepicker-buttonpane .ui-controlgroup button:last-of-type {
    border-top-right-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem; }

.ui-checkboxradio-label.ui-checkboxradio-checked {
  background-color: #f5f5f5;
  color: #333;
  border-color: #ddd; }
  .ui-checkboxradio-label.ui-checkboxradio-checked[class*=bg-]:not(.bg-white):not(.bg-light):not(.bg-transparent) {
    box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.125) inset; }

.ui-checkboxradio-label.ui-checkboxradio-disabled {
  pointer-events: none; }

.ui-datepicker {
  min-width: 270px;
  padding: 0.9375rem; }
  .ui-datepicker:not(.ui-datepicker-inline) {
    display: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    z-index: 1070 !important;
    border-radius: 0.1875rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); }
  .sidebar .ui-datepicker {
    min-width: 0; }
  .ui-datepicker a {
    transition: all ease-in-out 0.15s; }
    @media screen and (prefers-reduced-motion: reduce) {
      .ui-datepicker a {
        transition: none; } }
  .ui-datepicker .ui-datepicker-header {
    position: relative; }
  .ui-datepicker .ui-datepicker-title {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    padding-top: 0.46875rem;
    padding-bottom: 0.46875rem;
    font-size: 0.9375rem;
    margin-left: 2.8125rem;
    margin-right: 2.8125rem; }
    .ui-datepicker .ui-datepicker-title .ui-datepicker-month {
      font-weight: 500; }
    .ui-datepicker .ui-datepicker-title .ui-datepicker-year {
      font-size: 85%;
      color: #999;
      margin-left: 0.46875rem; }
    .ui-datepicker .ui-datepicker-title select {
      outline: 0;
      height: 2.00002rem;
      border-color: #ddd;
      -ms-flex: 1;
          flex: 1; }
      .ui-datepicker .ui-datepicker-title select.ui-datepicker-month, .ui-datepicker .ui-datepicker-title select.ui-datepicker-year {
        font-size: 0.8125rem;
        font-weight: 400;
        color: #333; }
  .ui-datepicker .ui-datepicker-prev,
  .ui-datepicker .ui-datepicker-next {
    position: absolute;
    top: 50%;
    margin-top: -0.9375rem;
    line-height: 1;
    color: #333;
    padding: 0.4375rem;
    cursor: pointer;
    border-radius: 0.1875rem; }
    .ui-datepicker .ui-datepicker-prev:after,
    .ui-datepicker .ui-datepicker-next:after {
      font-family: "icomoon";
      display: block;
      font-size: 1rem;
      width: 1rem;
      text-align: center;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
    .ui-datepicker .ui-datepicker-prev span,
    .ui-datepicker .ui-datepicker-next span {
      display: none; }
  .ui-datepicker .ui-datepicker-prev {
    left: 0; }
    .ui-datepicker .ui-datepicker-prev:after {
      content: '\e9c8'; }
  .ui-datepicker .ui-datepicker-next {
    right: 0; }
    .ui-datepicker .ui-datepicker-next:after {
      content: '\e9cb'; }
  .ui-datepicker .ui-datepicker-prev-hover,
  .ui-datepicker .ui-datepicker-next-hover {
    color: #333;
    background-color: #f5f5f5; }
  .ui-datepicker .ui-datepicker-calendar {
    width: 100%;
    border-collapse: collapse;
    margin: 0; }
    .ui-datepicker .ui-datepicker-calendar th {
      text-align: center;
      font-weight: 400;
      padding-top: 0.9375rem;
      padding-bottom: 0.4375rem;
      font-size: 0.75rem;
      color: #999; }
    .ui-datepicker .ui-datepicker-calendar td {
      text-align: center; }
      .ui-datepicker .ui-datepicker-calendar td span,
      .ui-datepicker .ui-datepicker-calendar td a {
        display: block;
        position: relative;
        text-decoration: none;
        min-width: 2.12503rem;
        color: #333;
        padding: 0.4375rem;
        border-radius: 0.1875rem; }
      .ui-datepicker .ui-datepicker-calendar td .ui-state-hover {
        color: #333;
        background-color: #f5f5f5; }
      .ui-datepicker .ui-datepicker-calendar td.ui-state-disabled a,
      .ui-datepicker .ui-datepicker-calendar td.ui-state-disabled span {
        color: #999;
        background-color: transparent; }
      .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-week-col {
        min-width: 2.12503rem;
        padding: 0.4375rem;
        color: #999; }
      .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-current-day .ui-state-active {
        color: #fff;
        background-color: #26A69A; }
      .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-today .ui-state-highlight {
        color: #333;
        background-color: #f5f5f5; }
        .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-today .ui-state-highlight:after {
          content: "";
          position: absolute;
          top: 0.14583rem;
          right: 0.14583rem;
          border-top: 0.4375rem solid #767676;
          border-left: 0.4375rem solid transparent;
          width: 0;
          height: 0; }
    .ui-datepicker .ui-datepicker-calendar .ui-datepicker-other-month .ui-priority-secondary {
      color: #999; }
    .ui-datepicker .ui-datepicker-calendar .ui-datepicker-week-col {
      text-align: left; }
  .ui-datepicker.ui-datepicker-multi {
    width: auto !important; }

.ui-datepicker-buttonpane {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding-top: 0.9375rem; }

.ui-datepicker-inline {
  max-width: 100%;
  overflow-x: auto; }

.ui-datepicker-trigger {
  position: absolute;
  top: 4px;
  right: 5px;
  z-index: 4;
  padding: 6px;
  cursor: pointer; }

.ui-datepicker-row-break {
  clear: both;
  width: 100%; }

.ui-datepicker-multi .ui-datepicker-group {
  float: left; }
  .ui-datepicker-multi .ui-datepicker-group + .ui-datepicker-group {
    padding-left: 0.9375rem; }

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 46%; }

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%; }

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%; }

.ui-dialog {
  position: fixed;
  top: 0;
  /*rtl:ignore*/
  left: 0;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); }
  @media (max-width: 767.98px) {
    .ui-dialog {
      width: 90% !important; } }

.ui-dialog-titlebar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 1.25rem;
  padding-bottom: 0; }
  .ui-draggable .ui-dialog-titlebar {
    cursor: move; }

.ui-dialog-title {
  display: block;
  font-size: 0.9375rem;
  line-height: 1.5385; }

.ui-dialog-titlebar-close {
  margin-left: auto;
  background-color: transparent;
  padding: 0;
  border: 0;
  font-size: 0;
  text-indent: 0; }
  .ui-dialog-titlebar-close:after {
    content: '\ed6a';
    font-family: "icomoon";
    display: block;
    width: 0.8125rem;
    font-size: 0.8125rem;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .ui-dialog-titlebar-close:hover, .ui-dialog-titlebar-close:focus {
    background-color: transparent !important;
    box-shadow: none; }

.ui-dialog-content {
  position: relative;
  overflow: auto;
  padding: 1.25rem; }
  .ui-dialog-content .form-group:last-child,
  .ui-dialog-content p:last-child {
    margin-bottom: 0; }

.ui-dialog-buttonpane {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding: 1.25rem;
  padding-top: 0; }
  .ui-dialog-buttonpane button + button {
    margin-left: 0.625rem; }

.ui-menu {
  list-style: none;
  padding: 0.5rem 0;
  margin: 0;
  display: block;
  outline: none;
  min-width: 11.25rem;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  z-index: 1000;
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); }
  .ui-menu .ui-menu {
    position: absolute;
    top: -0.4375rem !important; }
  .ui-menu .ui-menu-item {
    margin: 0;
    position: relative; }
    .ui-menu .ui-menu-item i {
      margin-right: 1rem; }
  .ui-menu .ui-menu-item-wrapper {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
        align-items: flex-start;
    cursor: pointer;
    padding: 0.5rem 1rem;
    outline: 0;
    color: #333;
    overflow: hidden;
    transition: all ease-in-out 0.15s; }
    @media screen and (prefers-reduced-motion: reduce) {
      .ui-menu .ui-menu-item-wrapper {
        transition: none; } }
    .ui-menu .ui-menu-item-wrapper > .ui-menu-icon {
      position: absolute;
      top: 50%;
      margin-top: -0.5rem;
      right: 1rem; }
      .ui-menu .ui-menu-item-wrapper > .ui-menu-icon:after {
        content: '\e9c7';
        font-family: "icomoon";
        font-size: 1rem;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
  .ui-menu .ui-menu-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
        align-items: flex-start;
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    line-height: 1.6667;
    text-transform: uppercase;
    color: #999;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
    .ui-menu .ui-menu-header.highlight {
      margin-top: 0;
      background-color: #f8f8f8; }
  .ui-menu .ui-menu-item + .highlight,
  .ui-menu .highlight + .ui-menu-item {
    margin-top: 0.5rem; }
  .ui-menu .ui-menu-item-wrapper > i,
  .ui-menu .ui-menu-header > i {
    top: 0;
    margin-top: 0.12502rem;
    margin-right: 1rem; }
  .ui-menu .ui-menu-divider {
    margin: 0.5rem 0;
    height: 0;
    font-size: 0;
    overflow: hidden;
    border-top: 1px solid #ddd; }
  .ui-menu .ui-state-disabled .ui-menu-item-wrapper {
    color: #999;
    background-color: transparent;
    cursor: default; }
  .ui-menu .ui-state-focus,
  .ui-menu .ui-state-active {
    color: #333;
    background-color: #f5f5f5; }

.ui-progressbar {
  display: -ms-flexbox;
  display: flex;
  height: 1.125rem;
  overflow: hidden;
  font-size: 0.60938rem;
  background-color: #eee;
  border-radius: 0.1875rem;
  box-shadow: inset 0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.1); }

.ui-progressbar-value {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #2196F3;
  background-size: 1.125rem 1.125rem;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .ui-progressbar-value {
      transition: none; } }
  .ui-progressbar-striped .ui-progressbar-value {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); }
  .ui-progressbar-active .ui-progressbar-value {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
            animation: progress-bar-stripes 1s linear infinite; }
  .ui-progressbar-indeterminate .ui-progressbar-value {
    background-image: none; }

.ui-selectmenu-menu {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  /*rtl:ignore*/
  left: 0;
  display: none; }
  .ui-selectmenu-menu .ui-menu {
    max-height: 340px;
    overflow-y: auto;
    overflow-x: hidden; }
    .ui-selectmenu-menu .ui-menu .ui-selectmenu-optgroup {
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1.6667;
      padding: 0.5rem 1rem;
      text-transform: uppercase;
      height: auto;
      border: 0; }
      .ui-selectmenu-menu .ui-menu .ui-selectmenu-optgroup ~ .ui-menu-item .ui-menu-item-wrapper {
        padding-left: 2rem; }

.ui-selectmenu-open {
  display: block; }

.ui-selectmenu-button {
  text-decoration: none;
  text-align: left;
  outline: 0; }
  .ui-selectmenu-button:not(.ui-controlgroup-item) {
    display: inline-block;
    position: relative;
    background-color: #fff;
    border: 1px solid #ddd;
    cursor: pointer;
    border-radius: 0.1875rem; }
  .ui-selectmenu-button:not(.ui-button-icon-only) {
    padding: 0.4375rem 0.875rem;
    padding-right: 2.75rem; }
  .ui-selectmenu-button:hover, .ui-selectmenu-button:focus {
    box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.01) inset; }
  .ui-selectmenu-button:not([class*=bg-]):hover, .ui-selectmenu-button:not([class*=bg-]):focus {
    background-color: #fff; }
  .ui-selectmenu-button:after {
    content: '\e9c5';
    font-family: "icomoon";
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0.875rem;
    text-align: right;
    margin-top: -0.5rem;
    font-size: 1rem;
    line-height: 1;
    color: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .ui-selectmenu-button .ui-selectmenu-text {
    display: block;
    text-transform: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .ui-selectmenu-button.ui-selectmenu-button-open {
    box-shadow: 0 0 0 62.5rem rgba(0, 0, 0, 0.01) inset; }

.ui-selectmenu-disabled {
  cursor: default;
  background-color: #fafafa; }
  .ui-selectmenu-disabled:hover, .ui-selectmenu-disabled:focus {
    box-shadow: none; }

.ui-selectmenu-lg .ui-selectmenu-button .ui-selectmenu-text {
  padding: 0.5625rem 1rem;
  padding-right: 3rem;
  font-size: 0.875rem;
  line-height: 1.4286; }

.ui-selectmenu-sm .ui-selectmenu-button .ui-selectmenu-text {
  padding: 0.3125rem 0.75rem;
  padding-right: 2.5rem;
  font-size: 0.75rem;
  line-height: 1.6667; }

.ui-selectmenu-images .ui-menu-item span {
  display: inline-block;
  margin-right: 1rem;
  background-size: 1.25003rem 1.25003rem;
  float: left;
  width: 1.25003rem;
  height: 1.25003rem;
  border-radius: 0.1875rem; }

.ui-selectmenu-images .demo-img-amazon {
  background: url("../../../../../../global_assets/images/brands/amazon.png") no-repeat; }

.ui-selectmenu-images .demo-img-youtube {
  background: url("../../../../../../global_assets/images/brands/youtube.png") no-repeat; }

.ui-selectmenu-images .demo-img-twitter {
  background: url("../../../../../../global_assets/images/brands/twitter.png") no-repeat; }

.ui-selectmenu-images .demo-img-bing {
  background: url("../../../../../../global_assets/images/brands/bing.png") no-repeat; }

.ui-selectmenu-images .demo-img-spotify {
  background: url("../../../../../../global_assets/images/brands/spotify.png") no-repeat; }

.ui-slider {
  position: relative;
  text-align: left;
  background-color: #eee;
  border-radius: 100px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); }
  .ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #ccc;
    outline: 0;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 100px;
    transition: background-color ease-in-out 0.15s; }
    @media screen and (prefers-reduced-motion: reduce) {
      .ui-slider .ui-slider-handle {
        transition: none; } }
    .ui-slider .ui-slider-handle.ui-state-hover, .ui-slider .ui-slider-handle.ui-state-focus {
      background-color: #fafafa; }
      .ui-slider .ui-slider-handle.ui-state-hover:after, .ui-slider .ui-slider-handle.ui-state-focus:after {
        background-color: #555; }
    .ui-slider .ui-slider-handle:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -0.1875rem;
      margin-left: -0.1875rem;
      background-color: #333;
      width: 0.375rem;
      height: 0.375rem;
      border-radius: 100px;
      transition: background-color ease-in-out 0.15s; }
      @media screen and (prefers-reduced-motion: reduce) {
        .ui-slider .ui-slider-handle:after {
          transition: none; } }
  .ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    display: block;
    background-color: #607D8B;
    border-radius: 100px; }
  .ui-slider.ui-slider-disabled {
    opacity: 0.75; }
    .ui-slider.ui-slider-disabled .ui-slider-handle {
      cursor: default; }

.ui-slider-horizontal {
  height: 0.375rem; }
  .ui-slider-horizontal .ui-slider-handle {
    top: -0.375rem;
    /*rtl:ignore*/
    margin-left: -0.5625rem; }
  .ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 100%; }
  .ui-slider-horizontal .ui-slider-range-min {
    left: 0; }
  .ui-slider-horizontal .ui-slider-range-max {
    right: 0; }

.ui-slider-vertical {
  width: 0.375rem;
  height: 150px;
  display: inline-block;
  margin: 0 0.625rem; }
  .ui-slider-vertical .ui-slider-handle {
    left: -0.375rem;
    margin-bottom: -0.5625rem; }
  .ui-slider-vertical .ui-slider-range {
    left: 0;
    width: 100%; }
  .ui-slider-vertical .ui-slider-range-min {
    bottom: 0; }
  .ui-slider-vertical .ui-slider-range-max {
    top: 0; }

.ui-slider-solid .ui-slider-handle:after {
  background-color: #fff;
  transition: opacity ease-in-out 0.15s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .ui-slider-solid .ui-slider-handle:after {
      transition: none; } }

.ui-slider-solid .ui-slider-handle.ui-state-hover:after, .ui-slider-solid .ui-slider-handle.ui-state-active:after {
  background-color: #fff;
  opacity: 0.75; }

.ui-handle-white .ui-slider-handle:after {
  content: none; }

.ui-slider-lg .ui-slider-handle {
  width: 1.5rem;
  height: 1.5rem; }
  .ui-slider-lg .ui-slider-handle:after {
    margin-top: -0.25rem;
    margin-left: -0.25rem;
    width: 0.5rem;
    height: 0.5rem; }

.ui-slider-lg.ui-slider-horizontal {
  height: 0.5rem; }
  .ui-slider-lg.ui-slider-horizontal .ui-slider-handle {
    top: -0.5rem;
    /*rtl:ignore*/
    margin-left: -0.75rem; }

.ui-slider-lg.ui-slider-vertical {
  width: 0.5rem; }
  .ui-slider-lg.ui-slider-vertical .ui-slider-handle {
    left: -0.5rem;
    margin-bottom: -0.75rem; }

.ui-slider-sm .ui-slider-handle {
  width: 0.75rem;
  height: 0.75rem; }
  .ui-slider-sm .ui-slider-handle:after {
    margin-top: -0.125rem;
    margin-left: -0.125rem;
    width: 0.25rem;
    height: 0.25rem; }

.ui-slider-sm.ui-slider-horizontal {
  height: 0.25rem; }
  .ui-slider-sm.ui-slider-horizontal .ui-slider-handle {
    top: -0.25rem;
    /*rtl:ignore*/
    margin-left: -0.375rem; }

.ui-slider-sm.ui-slider-vertical {
  width: 0.25rem; }
  .ui-slider-sm.ui-slider-vertical .ui-slider-handle {
    left: -0.25rem;
    margin-bottom: -0.375rem; }

.ui-slider-primary .ui-slider-range,
.ui-slider-solid.ui-slider-primary .ui-slider-handle {
  background-color: #2196F3;
  border-color: #2196F3; }

.ui-slider-danger .ui-slider-range,
.ui-slider-solid.ui-slider-danger .ui-slider-handle {
  background-color: #F44336;
  border-color: #F44336; }

.ui-slider-success .ui-slider-range,
.ui-slider-solid.ui-slider-success .ui-slider-handle {
  background-color: #4CAF50;
  border-color: #4CAF50; }

.ui-slider-warning .ui-slider-range,
.ui-slider-solid.ui-slider-warning .ui-slider-handle {
  background-color: #FF7043;
  border-color: #FF7043; }

.ui-slider-info .ui-slider-range,
.ui-slider-solid.ui-slider-info .ui-slider-handle {
  background-color: #00BCD4;
  border-color: #00BCD4; }

.ui-spinner {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%; }

.ui-spinner-input {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.ui-spinner-button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  border-radius: 0; }
  .ui-spinner-button:after {
    font-family: "icomoon";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1rem;
    font-size: 1rem;
    line-height: 1;
    text-indent: 0;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .ui-spinner-button:last-child {
    border-top-right-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem; }
  .ui-spinner-button > span {
    display: none; }
  .ui-spinner-button + .ui-spinner-button {
    border-left: 0; }

.ui-spinner-up:after {
  content: '\e9f7'; }

.ui-spinner-down:after {
  content: '\e9e2'; }

.ui-tabs {
  position: relative; }
  .ui-tabs .ui-tabs-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-bottom: 1.25rem;
    border-bottom: 1px solid #ddd; }
  .ui-tabs .ui-tabs-tab {
    list-style: none;
    position: relative;
    padding: 0;
    margin-bottom: -1px; }
  .ui-tabs .ui-tabs-anchor {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: start;
        align-items: flex-start;
    color: #777;
    padding: 0.625rem 1.25rem;
    border: 1px solid transparent;
    transition: all ease-in-out 0.15s; }
    @media screen and (prefers-reduced-motion: reduce) {
      .ui-tabs .ui-tabs-anchor {
        transition: none; } }
    .ui-tabs .ui-tabs-anchor:before {
      content: '';
      position: absolute;
      height: 2px;
      top: -1px;
      left: -1px;
      right: -1px;
      transition: background-color ease-in-out 0.15s; }
      @media screen and (prefers-reduced-motion: reduce) {
        .ui-tabs .ui-tabs-anchor:before {
          transition: none; } }
    .ui-tabs .ui-tabs-anchor i {
      top: 0;
      margin-top: 0.12502rem; }
  .ui-tabs .ui-state-hover .ui-tabs-anchor:not(.ui-state-disabled),
  .ui-tabs .ui-state-focus .ui-tabs-anchor:not(.ui-state-disabled) {
    color: #333; }
  .ui-tabs .ui-tabs-active .ui-tabs-anchor {
    color: #333;
    background-color: #fff;
    border-color: #ddd #ddd #fff; }
    .ui-tabs .ui-tabs-active .ui-tabs-anchor:before {
      background-color: #2196F3; }
  .ui-tabs .ui-state-disabled {
    color: #999;
    background-color: transparent;
    border-color: transparent;
    cursor: default; }
  .ui-tabs .ui-sortable-helper:not(.ui-tabs-active) .ui-tabs-anchor {
    border-color: #ddd; }
  .ui-tabs .ui-tabs-panel {
    display: block; }

.ui-tooltip {
  position: absolute;
  z-index: 1070;
  max-width: 200px;
  padding: 0.5rem 0.75rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.1875rem; }

.ui-widget-overlay {
  position: fixed;
  top: 0;
  /*rtl:ignore*/
  left: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0.5;
  width: 100%;
  height: 100%; }

.ui-helper-hidden {
  display: none; }

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none; }

.ui-helper-clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.ui-helper-zfix {
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%; }

.ui-front {
  z-index: 1040; }

/* ------------------------------------------------------------------------------
 *
 *  # Prism
 *
 *  Styles for prism.min.js - lightweight, extensible syntax highlighter
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Sticky kit
 *
 *  Styles for sticky.min.js - extension that makes elements sticky
 *
 * ---------------------------------------------------------------------------- */
.is_stuck {
  z-index: 1030 !important; }

.navbar-top .navbar.is_stuck {
  margin-top: 3.12503rem; }

/* ------------------------------------------------------------------------------
 *
 *  # Headroom
 *
 *  Styles for headroom.min.js - hides BS navbar component on page scroll
 *
 * ---------------------------------------------------------------------------- */
.headroom {
  transition: box-shadow ease-in-out 0.2s, -webkit-transform ease-in-out 0.2s;
  transition: transform ease-in-out 0.2s, box-shadow ease-in-out 0.2s;
  transition: transform ease-in-out 0.2s, box-shadow ease-in-out 0.2s, -webkit-transform ease-in-out 0.2s;
  will-change: transform; }

.navbar-slide-top.headroom--pinned {
  -webkit-transform: translateY(0);
          transform: translateY(0); }

.navbar-slide-top.headroom--unpinned {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  box-shadow: none; }

.navbar-slide-bottom.headroom--pinned {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  box-shadow: none; }

.navbar-slide-bottom.headroom--unpinned {
  -webkit-transform: translateY(0);
          transform: translateY(0); }

/* ------------------------------------------------------------------------------
*
*  # Floating action buttons
*
*  Styles for fab.min.js - material design floating action button with menu
*
* ---------------------------------------------------------------------------- */
.fab-menu {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  list-style: none;
  z-index: 1029;
  -webkit-transform: scale(0.85);
          transform: scale(0.85); }
  .page-header > .fab-menu {
    position: absolute;
    bottom: -1.5rem; }
  @media (min-width: 768px) {
    .fab-menu {
      -webkit-transform: scale(1);
              transform: scale(1); } }

.fab-menu-absolute {
  position: absolute; }

.fab-menu-fixed {
  position: fixed;
  z-index: 1030; }

.content-wrapper > .fab-menu-top-left, .content-wrapper >
.fab-menu-top-right {
  top: -1.5rem; }

.fab-menu-bottom-left,
.fab-menu-bottom-right {
  bottom: 1.25rem;
  transition: bottom ease-in-out 0.15s; }
  .fab-menu-bottom-left.reached-bottom,
  .fab-menu-bottom-right.reached-bottom {
    bottom: 5.62503rem; }

.fab-menu-top-right,
.fab-menu-bottom-right {
  right: 1.25rem; }

.fab-menu-inner {
  list-style: none;
  margin: 0;
  padding: 0; }
  .fab-menu-inner > li {
    display: block;
    position: absolute;
    top: 0.125rem;
    left: 0;
    right: 0;
    text-align: center;
    padding-top: 1.25rem;
    margin-top: -1.25rem; }
    .fab-menu-inner > li .btn {
      border-width: 0;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
      .fab-menu-inner > li .btn:hover, .fab-menu-inner > li .btn:focus, .fab-menu-inner > li .btn.focus, .fab-menu-inner > li .btn:not(:disabled):not(.disabled):active, .fab-menu-inner > li .btn:not(:disabled):not(.disabled).active {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important; }
    .fab-menu-inner > li .btn-float {
      padding: 0;
      width: 2.75rem;
      height: 2.75rem; }
      .fab-menu-inner > li .btn-float > i {
        margin: 0.875rem; }
    .fab-menu-inner > li .show > .btn-float.dropdown-toggle {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
    .fab-menu-inner > li .dropleft .btn:before,
    .fab-menu-inner > li .dropleft .btn:after,
    .fab-menu-inner > li .dropright .btn:before,
    .fab-menu-inner > li .dropright .btn:after {
      content: none; }
    .fab-menu-inner > li .badge {
      position: absolute;
      top: 0.75rem;
      right: -0.25rem; }
    .fab-menu-inner > li .badge-mark {
      top: 1.25rem;
      right: 0rem; }
    .fab-menu-bottom-left .fab-menu-inner > li,
    .fab-menu-bottom-right .fab-menu-inner > li {
      padding-top: 0;
      margin-top: 0;
      padding-bottom: 1.25rem;
      margin-bottom: -1.25rem; }
      .fab-menu-bottom-left .fab-menu-inner > li .badge,
      .fab-menu-bottom-right .fab-menu-inner > li .badge {
        top: -0.5rem; }
      .fab-menu-bottom-left .fab-menu-inner > li .badge-mark,
      .fab-menu-bottom-right .fab-menu-inner > li .badge-mark {
        top: 0rem; }

.fab-menu-btn {
  z-index: 1031;
  border-width: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  .fab-menu-btn.btn-float {
    padding: 1.5rem; }
    .fab-menu-btn.btn-float > i {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -0.5rem;
      margin-left: -0.5rem; }
  .fab-menu-btn:hover, .fab-menu-btn:focus, .fab-menu-btn:not(:disabled):not(.disabled):active, .fab-menu-btn:not(:disabled):not(.disabled).active, .fab-menu-btn:not(:disabled):not(.disabled):active:focus,
  .fab-menu[data-fab-toggle="hover"]:hover .fab-menu-btn,
  .fab-menu[data-fab-state="open"] .fab-menu-btn {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
  .fab-menu-btn:disabled, .fab-menu-btn.disabled {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

.fab-icon-close,
.fab-icon-open {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  transition: all ease-in-out 0.3s; }
  .fab-menu[data-fab-toggle="hover"]:hover .fab-icon-close,
  .fab-menu[data-fab-state="open"] .fab-icon-close, .fab-menu[data-fab-toggle="hover"]:hover
  .fab-icon-open,
  .fab-menu[data-fab-state="open"]
  .fab-icon-open {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }

.fab-menu[data-fab-toggle="hover"]:hover .fab-icon-open,
.fab-menu[data-fab-state="open"] .fab-icon-open {
  opacity: 0; }

.fab-icon-close {
  opacity: 0; }
  .fab-menu[data-fab-toggle="hover"]:hover .fab-icon-close,
  .fab-menu[data-fab-state="open"] .fab-icon-close {
    opacity: 1; }

.fab-menu .fab-menu-inner > li {
  visibility: hidden;
  opacity: 0;
  transition: all ease-in-out 0.3s; }
  .fab-menu .fab-menu-inner > li:nth-child(1) {
    transition-delay: 0.05s; }
  .fab-menu .fab-menu-inner > li:nth-child(2) {
    transition-delay: 0.1s; }
  .fab-menu .fab-menu-inner > li:nth-child(3) {
    transition-delay: 0.15s; }
  .fab-menu .fab-menu-inner > li:nth-child(4) {
    transition-delay: 0.2s; }
  .fab-menu .fab-menu-inner > li:nth-child(5) {
    transition-delay: 0.25s; }

.fab-menu[data-fab-toggle="hover"]:hover .fab-menu-inner > li, .fab-menu[data-fab-state="open"] .fab-menu-inner > li {
  visibility: visible;
  opacity: 1; }

.fab-menu-top[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(1), .fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(1),
.fab-menu-top-left[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(1),
.fab-menu-top-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(1),
.fab-menu-top-right[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(1),
.fab-menu-top-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(1) {
  top: 4.25rem; }

.fab-menu-top[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(2), .fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(2),
.fab-menu-top-left[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(2),
.fab-menu-top-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(2),
.fab-menu-top-right[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(2),
.fab-menu-top-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(2) {
  top: 8.25rem; }

.fab-menu-top[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(3), .fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(3),
.fab-menu-top-left[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(3),
.fab-menu-top-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(3),
.fab-menu-top-right[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(3),
.fab-menu-top-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(3) {
  top: 12.25rem; }

.fab-menu-top[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(4), .fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(4),
.fab-menu-top-left[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(4),
.fab-menu-top-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(4),
.fab-menu-top-right[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(4),
.fab-menu-top-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(4) {
  top: 16.25rem; }

.fab-menu-top[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(5), .fab-menu-top[data-fab-state="open"] .fab-menu-inner > li:nth-child(5),
.fab-menu-top-left[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(5),
.fab-menu-top-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(5),
.fab-menu-top-right[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(5),
.fab-menu-top-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(5) {
  top: 20.25rem; }

.fab-menu-bottom[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(1), .fab-menu-bottom[data-fab-state="open"] .fab-menu-inner > li:nth-child(1),
.fab-menu-bottom-left[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(1),
.fab-menu-bottom-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(1),
.fab-menu-bottom-right[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(1),
.fab-menu-bottom-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(1) {
  top: -3.75rem; }

.fab-menu-bottom[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(2), .fab-menu-bottom[data-fab-state="open"] .fab-menu-inner > li:nth-child(2),
.fab-menu-bottom-left[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(2),
.fab-menu-bottom-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(2),
.fab-menu-bottom-right[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(2),
.fab-menu-bottom-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(2) {
  top: -7.75rem; }

.fab-menu-bottom[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(3), .fab-menu-bottom[data-fab-state="open"] .fab-menu-inner > li:nth-child(3),
.fab-menu-bottom-left[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(3),
.fab-menu-bottom-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(3),
.fab-menu-bottom-right[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(3),
.fab-menu-bottom-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(3) {
  top: -11.75rem; }

.fab-menu-bottom[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(4), .fab-menu-bottom[data-fab-state="open"] .fab-menu-inner > li:nth-child(4),
.fab-menu-bottom-left[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(4),
.fab-menu-bottom-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(4),
.fab-menu-bottom-right[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(4),
.fab-menu-bottom-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(4) {
  top: -15.75rem; }

.fab-menu-bottom[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(5), .fab-menu-bottom[data-fab-state="open"] .fab-menu-inner > li:nth-child(5),
.fab-menu-bottom-left[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(5),
.fab-menu-bottom-left[data-fab-state="open"] .fab-menu-inner > li:nth-child(5),
.fab-menu-bottom-right[data-fab-toggle="hover"]:hover .fab-menu-inner > li:nth-child(5),
.fab-menu-bottom-right[data-fab-state="open"] .fab-menu-inner > li:nth-child(5) {
  top: -19.75rem; }

.fab-menu-inner div[data-fab-label]:after {
  content: attr(data-fab-label);
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  right: 4.125rem;
  color: #fff;
  background-color: #000;
  padding: 0.5rem 0.75rem;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all ease-in-out 0.3s;
  border-radius: 0.1875rem; }
  .fab-menu-bottom-left .fab-menu-inner div[data-fab-label]:after,
  .fab-menu-bottom-right .fab-menu-inner div[data-fab-label]:after {
    margin-top: -1.75rem; }

.fab-menu-top-left .fab-menu-inner div[data-fab-label]:after,
.fab-menu-bottom-left .fab-menu-inner div[data-fab-label]:after, .fab-menu-inner div[data-fab-label].fab-label-right:after {
  right: auto;
  left: 4.125rem; }

.fab-menu[data-fab-toggle="hover"] .fab-menu-inner div[data-fab-label]:hover:after,
.fab-menu[data-fab-state="open"] .fab-menu-inner div[data-fab-label]:hover:after {
  visibility: visible;
  opacity: 1; }

.fab-menu-inner div[data-fab-label].fab-label-light:after {
  background-color: #fff;
  color: #333; }

.fab-menu-inner div[data-fab-label].fab-label-visible:after {
  visibility: visible;
  opacity: 1; }

/* ------------------------------------------------------------------------------
 *
 *  # Ladda progress buttons
 *
 *  Styles for ladda.min.js - buttons with built-in loading indicators
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Footable
 *
 *  jQuery plugin that aims to make HTML tables on smaller devices look awesome.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Handsontable
 *
 *  Styles for handsontable.min.js - JavaScript Spreadsheet library
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Datatables library
 *
 *  Add advanced interaction controls to any HTML table
 *
 * ---------------------------------------------------------------------------- */
.dataTable {
  margin: 0;
  max-width: none; }
  .dataTable thead th,
  .dataTable thead td {
    outline: 0;
    position: relative; }
  .dataTable thead .sorting_asc,
  .dataTable thead .sorting_desc,
  .dataTable thead .sorting {
    cursor: pointer; }
  .dataTable thead .sorting,
  .dataTable thead .sorting_asc,
  .dataTable thead .sorting_desc,
  .dataTable thead .sorting_asc_disabled,
  .dataTable thead .sorting_desc_disabled {
    padding-right: 2.5rem; }
  .dataTable thead .sorting:before,
  .dataTable thead .sorting:after,
  .dataTable thead .sorting_asc:after,
  .dataTable thead .sorting_desc:after,
  .dataTable thead .sorting_asc_disabled:after,
  .dataTable thead .sorting_desc_disabled:after {
    content: '';
    font-family: "icomoon";
    position: absolute;
    top: 50%;
    right: 1.25rem;
    font-size: 0.75rem;
    margin-top: -0.375rem;
    display: inline-block;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .dataTable thead .sorting:before {
    content: '\e9c1';
    margin-top: -0.125rem;
    opacity: 0.5; }
  .dataTable thead .sorting:after {
    content: '\e9c2';
    margin-top: -0.625rem;
    opacity: 0.5; }
  .dataTable thead .sorting_asc:after {
    content: '\e9c2';
    opacity: 1; }
  .dataTable thead .sorting_desc:after {
    content: '\e9c1';
    opacity: 1; }
  .dataTable thead .sorting_asc_disabled:after {
    content: '\e9c2';
    opacity: 0.25; }
  .dataTable thead .sorting_desc_disabled:after {
    content: '\e9c1';
    opacity: 0.25; }
  .dataTable tbody + thead > tr:first-child > th,
  .dataTable tbody + thead > tr:first-child > td,
  .dataTable tbody + tfoot + thead > tr:first-child > th,
  .dataTable tbody + tfoot + thead > tr:first-child > td {
    border-top: 0; }
  .dataTable tbody th,
  .dataTable tbody td {
    outline: 0; }
    .dataTable tbody th.active,
    .dataTable tbody td.active {
      background-color: rgba(0, 0, 0, 0.03); }
  .dataTable .dataTables_empty {
    text-align: center; }
  .dataTable.table-bordered {
    border-collapse: collapse; }

.dataTables_wrapper {
  position: relative;
  clear: both; }
  .dataTables_wrapper::after {
    display: block;
    clear: both;
    content: ""; }
  .dataTables_wrapper .table-bordered {
    border-top: 0; }
  .dataTables_wrapper + .card-body {
    border-top: 1px solid #ddd; }

.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  background-color: #fff;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%); }

.datatable-header,
.datatable-footer {
  padding: 1.25rem 1.25rem 0 1.25rem; }
  .datatable-header::after,
  .datatable-footer::after {
    display: block;
    clear: both;
    content: ""; }
  .datatable-header > div:first-child,
  .datatable-footer > div:first-child {
    margin-left: 0; }
  .card-body .datatable-header, .card-body
  .datatable-footer {
    padding-left: 0;
    padding-right: 0; }
  .datatable-header-accent,
  .datatable-footer-accent {
    background-color: rgba(0, 0, 0, 0.02); }

.datatable-header {
  border-bottom: 1px solid #ddd; }

.datatable-footer {
  border-top: 1px solid #b7b7b7; }

.dataTables_length {
  float: right;
  display: inline-block;
  margin: 0 0 1.25rem 1.25rem; }
  .dataTables_length > label {
    margin-bottom: 0; }
    .dataTables_length > label > span:first-child {
      float: left;
      margin: 0.5rem 0.83333rem;
      margin-left: 0; }
  .dataTables_length select {
    height: 2.25003rem;
    padding: 0.4375rem 0.875rem;
    font-size: 0.8125rem;
    line-height: 1.5385;
    color: #333;
    background-color: #fff;
    border: 1px solid #ddd;
    outline: 0; }
  .length-left .dataTables_length {
    float: left; }
  .dataTables_length .select2-container {
    width: auto; }
  .dataTables_length .select2-choice {
    min-width: 3.75rem; }

.dataTables_filter {
  position: relative;
  display: block;
  float: left;
  margin: 0 0 1.25rem 1.25rem; }
  .dataTables_filter > label {
    margin-bottom: 0;
    position: relative; }
    .dataTables_filter > label:after {
      content: "\e98e";
      font-family: "icomoon";
      font-size: 0.75rem;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 0.875rem;
      margin-top: -0.375rem;
      line-height: 1;
      opacity: 0.5;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
    .dataTables_filter > label > span {
      float: left;
      margin: 0.5rem 0.83333rem;
      margin-left: 0; }
  .dataTables_filter input {
    outline: 0;
    width: 12.5rem;
    padding: 0.4375rem 0.875rem;
    padding-right: 2rem;
    font-size: 0.8125rem;
    line-height: 1.5385;
    color: #333;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0.1875rem; }
    .dataTables_filter input::-webkit-input-placeholder {
      color: #999;
      opacity: 1; }
    .dataTables_filter input::-moz-placeholder {
      color: #999;
      opacity: 1; }
    .dataTables_filter input:-ms-input-placeholder {
      color: #999;
      opacity: 1; }
    .dataTables_filter input::-ms-input-placeholder {
      color: #999;
      opacity: 1; }
    .dataTables_filter input::placeholder {
      color: #999;
      opacity: 1; }
  .filter-right .dataTables_filter {
    float: right; }

.dataTables_info {
  float: left;
  padding: 0.4375rem 0;
  margin-bottom: 1.25rem; }
  .info-right .dataTables_info {
    float: right; }

.dataTables_paginate {
  float: right;
  text-align: right;
  margin: 0 0 1.25rem 1.25rem; }
  .dataTables_paginate .paginate_button {
    display: inline-block;
    padding: 0.4375rem;
    min-width: 2.25003rem;
    margin-left: 0.125rem;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    color: #333;
    outline: 0;
    border: 1px solid transparent;
    border-radius: 0.1875rem;
    transition: all ease-in-out 0.15s; }
    @media screen and (prefers-reduced-motion: reduce) {
      .dataTables_paginate .paginate_button {
        transition: none; } }
    .dataTables_paginate .paginate_button:first-child {
      margin-left: 0; }
    .dataTables_paginate .paginate_button:hover, .dataTables_paginate .paginate_button:focus {
      background-color: #f5f5f5; }
    .dataTables_paginate .paginate_button.current, .dataTables_paginate .paginate_button.current:hover, .dataTables_paginate .paginate_button.current:focus {
      color: #fff;
      background-color: #37474F; }
    .dataTables_paginate .paginate_button.disabled, .dataTables_paginate .paginate_button.disabled:hover, .dataTables_paginate .paginate_button.disabled:focus {
      cursor: default;
      background-color: #fff;
      color: #999; }
  .dataTables_paginate.paging_simple .paginate_button {
    padding-left: 0.875rem;
    padding-right: 0.875rem; }
    .dataTables_paginate.paging_simple .paginate_button:not(.disabled):hover, .dataTables_paginate.paging_simple .paginate_button:not(.disabled):focus {
      color: #fff;
      background-color: #37474F; }
  .paginate-left .dataTables_paginate {
    float: left; }

.paging_simple .paginate_button:hover, .paging_simple .paginate_button:focus {
  color: #fff;
  background-color: #2196F3; }

.dataTables_scroll {
  clear: both; }
  .dataTables_scroll .dataTables_scrollHead table {
    border-bottom: 0; }
  .dataTables_scroll .dataTables_scrollHead th,
  .dataTables_scroll .dataTables_scrollHead td {
    white-space: nowrap; }
  .dataTables_scroll .dataTables_scrollBody {
    -webkit-overflow-scrolling: touch; }
    .dataTables_scroll .dataTables_scrollBody table {
      border-bottom: 0; }
      .dataTables_scroll .dataTables_scrollBody table thead th[class*=sorting]:before, .dataTables_scroll .dataTables_scrollBody table thead th[class*=sorting]:after {
        content: none; }
      .dataTables_scroll .dataTables_scrollBody table tbody tr:first-child > td {
        border-top: 0; }
    .dataTables_scroll .dataTables_scrollBody th,
    .dataTables_scroll .dataTables_scrollBody td {
      white-space: nowrap; }
      .dataTables_scroll .dataTables_scrollBody th > .dataTables_sizing,
      .dataTables_scroll .dataTables_scrollBody td > .dataTables_sizing {
        height: 0;
        overflow: hidden;
        margin: 0;
        padding: 0; }

.card-body + .dataTables_wrapper,
.card-body + * > .dataTables_wrapper {
  border-top: 1px solid #ddd; }

.card-body > .dataTables_wrapper .datatable-footer {
  border-top: 0; }
  .card-body > .dataTables_wrapper .datatable-footer .dataTables_length,
  .card-body > .dataTables_wrapper .datatable-footer .dataTables_filter,
  .card-body > .dataTables_wrapper .datatable-footer .dataTables_info,
  .card-body > .dataTables_wrapper .datatable-footer .dataTables_paginate {
    margin-bottom: 0; }

.card-header:not([class*=bg-]) + .dataTables_wrapper > .datatable-header {
  padding-top: 0; }

.card > .dataTables_wrapper .table-bordered {
  border: 0; }
  .card > .dataTables_wrapper .table-bordered > thead > tr > td:first-child,
  .card > .dataTables_wrapper .table-bordered > thead > tr > th:first-child,
  .card > .dataTables_wrapper .table-bordered > tbody > tr > td:first-child,
  .card > .dataTables_wrapper .table-bordered > tbody > tr > th:first-child,
  .card > .dataTables_wrapper .table-bordered > tfoot > tr > td:first-child,
  .card > .dataTables_wrapper .table-bordered > tfoot > tr > th:first-child {
    border-left: 0; }
  .card > .dataTables_wrapper .table-bordered > thead > tr > td:last-child,
  .card > .dataTables_wrapper .table-bordered > thead > tr > th:last-child,
  .card > .dataTables_wrapper .table-bordered > tbody > tr > td:last-child,
  .card > .dataTables_wrapper .table-bordered > tbody > tr > th:last-child,
  .card > .dataTables_wrapper .table-bordered > tfoot > tr > td:last-child,
  .card > .dataTables_wrapper .table-bordered > tfoot > tr > th:last-child {
    border-right: 0; }
  .card > .dataTables_wrapper .table-bordered > tbody > tr:last-child > th,
  .card > .dataTables_wrapper .table-bordered > tbody > tr:last-child > td {
    border-bottom: 0; }

.datatable-scroll-lg,
.datatable-scroll,
.datatable-scroll-sm {
  min-height: .01%; }

.datatable-scroll-wrap {
  width: 100%;
  min-height: .01%;
  overflow-x: auto; }

@media (max-width: 767.98px) {
  .datatable-scroll-sm {
    width: 100%;
    overflow-x: scroll; }
    .datatable-scroll-sm th,
    .datatable-scroll-sm td {
      white-space: nowrap; } }

@media (max-width: 991.98px) {
  .datatable-scroll {
    width: 100%;
    overflow-x: scroll; }
    .datatable-scroll th,
    .datatable-scroll td {
      white-space: nowrap; } }

@media (max-width: 1199.98px) {
  .datatable-scroll-lg {
    width: 100%;
    overflow-x: scroll; }
    .datatable-scroll-lg th,
    .datatable-scroll-lg td {
      white-space: nowrap; } }

@media (max-width: 767.98px) {
  .dataTables_info,
  .dataTables_paginate,
  .dataTables_length,
  .dataTables_filter,
  .DTTT_container,
  .ColVis {
    float: none !important;
    text-align: center;
    margin-left: 0; }
  .dataTables_info,
  .dataTables_paginate {
    margin-top: 0; }
  .datatable-header {
    text-align: center; } }

/* ------------------------------------------------------------------------------
 *
 *  # Fixed columns
 *
 *  Extension that "freezes" in place the left most columns in a scrolling DataTable
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Select extension
 *
 *  Adds item selection capabilities to a DataTable
 *
 * ---------------------------------------------------------------------------- */
.dataTable tbody > tr.selected,
.dataTable tbody > tr > .selected {
  background-color: #E8F5E9; }

.dataTables_wrapper .select-info,
.dataTables_wrapper .select-item {
  margin-left: 0.625rem; }
  @media (max-width: 767.98px) {
    .dataTables_wrapper .select-info,
    .dataTables_wrapper .select-item {
      margin-left: 0;
      display: block; } }

.dataTable tbody .select-checkbox {
  position: relative; }
  .dataTable tbody .select-checkbox:before, .dataTable tbody .select-checkbox:after {
    display: inline-block;
    color: #455A64;
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer; }
  .dataTable tbody .select-checkbox:before {
    content: '';
    background-color: #fff;
    width: 1.25rem;
    height: 1.25rem;
    margin-top: -0.625rem;
    margin-left: -0.625rem;
    border: 0.125rem solid #455A64;
    border-radius: 0.125rem; }

.dataTable tbody .selected .select-checkbox:after {
  content: "\e600";
  font-family: "icomoon";
  font-size: 1rem;
  line-height: 1;
  margin-top: -0.5rem;
  margin-left: -0.5rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* ------------------------------------------------------------------------------
 *
 *  # Buttons extension
 *
 *  The Buttons extension for DataTables provides a common set of options, API
 *  methods and styling to display buttons that will interact with a DataTable
 *
 * ---------------------------------------------------------------------------- */
.dt-buttons-full .dt-buttons {
  float: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  margin: 0;
  border-bottom: 1px solid #ddd;
  padding-top: 1.25rem;
  padding-bottom: 0.625rem;
  background-color: rgba(0, 0, 0, 0.02); }
  .dt-buttons-full .dt-buttons > .btn {
    margin-bottom: 0.625rem;
    float: none; }

.dt-buttons {
  position: relative;
  float: right;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 0 1.25rem 1.25rem; }
  .dt-buttons-left .dt-buttons {
    float: left; }
  .dt-buttons > .btn {
    border-radius: 0; }
    .dt-buttons > .btn:first-child {
      border-top-left-radius: 0.1875rem;
      border-bottom-left-radius: 0.1875rem; }
    .dt-buttons > .btn:last-of-type {
      border-top-right-radius: 0.1875rem;
      border-bottom-right-radius: 0.1875rem; }
    .dt-buttons > .btn + .btn {
      margin-left: -1px; }
  @media (max-width: 767.98px) {
    .dt-buttons {
      float: none;
      text-align: center;
      display: block; }
      .dt-buttons .btn {
        float: none; } }

.dt-button-background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  z-index: 1040;
  opacity: 0.5; }

.dt-button-collection {
  position: absolute;
  background-color: #fff;
  display: block;
  z-index: 1050;
  padding: 0.5rem 0;
  overflow: hidden;
  min-width: 11.25rem;
  -webkit-column-gap: 0.125rem;
     -moz-column-gap: 0.125rem;
          column-gap: 0.125rem;
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1); }
  .dt-button-collection:not(.fixed) {
    top: 100% !important;
    left: auto !important;
    right: 0 !important; }
  .dt-button-collection > .dt-button {
    padding: 0.5rem 1rem;
    color: #333;
    background-color: transparent;
    border: 0;
    display: block;
    outline: 0;
    width: 100%;
    text-align: left;
    cursor: pointer;
    transition: all ease-in-out 0.15s; }
    @media screen and (prefers-reduced-motion: reduce) {
      .dt-button-collection > .dt-button {
        transition: none; } }
    .dt-button-collection > .dt-button + .dt-button {
      margin-top: 1px; }
    .dt-button-collection > .dt-button:hover, .dt-button-collection > .dt-button:focus {
      color: #333;
      background-color: #f5f5f5; }
    .dt-button-collection > .dt-button.active {
      color: #fff;
      background-color: #2196F3; }
  .dt-button-collection.fixed {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -4.6875rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    .dt-button-collection.fixed.two-column {
      margin-left: -9.375rem; }
    .dt-button-collection.fixed.three-column {
      margin-left: -14.065rem; }
    .dt-button-collection.fixed.four-column {
      margin-left: -18.75rem; }
  .dt-button-collection > * {
    -webkit-column-break-inside: avoid;
    break-inside: avoid; }
  .dt-button-collection.two-column {
    width: 18.75rem;
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2; }
  .dt-button-collection.three-column {
    width: 28.13rem;
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3; }
  .dt-button-collection.four-column {
    width: 37.5rem;
    -webkit-column-count: 4;
       -moz-column-count: 4;
            column-count: 4; }

/* ------------------------------------------------------------------------------
 *
 *  # Responsive extension
 *
 *  Optimise the table's layout for different screen sizes through the dynamic 
 *  insertion and removal of columns from the table
 *
 * ---------------------------------------------------------------------------- */
.dtr-inline.collapsed tbody tr td:before,
.dtr-inline.collapsed tbody tr th:before {
  margin-right: 0.625rem; }

.dtr-inline.collapsed tbody tr td:first-child,
.dtr-inline.collapsed tbody tr th:first-child {
  position: relative;
  cursor: pointer;
  white-space: nowrap; }
  .dtr-inline.collapsed tbody tr td:first-child.dataTables_empty:before,
  .dtr-inline.collapsed tbody tr th:first-child.dataTables_empty:before {
    display: none; }

.dtr-column tbody td.control,
.dtr-column tbody th.control {
  position: relative;
  cursor: pointer; }

.dtr-inline.collapsed tbody tr td:first-child:before,
.dtr-inline.collapsed tbody tr th:first-child:before,
.dtr-column tbody tr td.control:before,
.dtr-column tbody tr th.control:before {
  content: '\e9e4';
  font-family: "icomoon";
  display: inline-block;
  font-size: 1rem;
  width: 1rem;
  line-height: 1;
  position: relative;
  top: -0.09375rem;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.dataTable.dtr-inline.collapsed tbody tr.parent td:first-child:before,
.dataTable.dtr-inline.collapsed tbody tr.parent th:first-child:before,
.dataTable.dtr-column tbody tr.parent td.control:before,
.dataTable.dtr-column tbody tr.parent th.control:before {
  content: '\e9e7'; }

.dtr-inline.collapsed tbody tr.child td:before {
  display: none; }

.dataTable tr.child:hover {
  background-color: transparent; }

.dataTable tr.child .dtr-title {
  display: table-cell;
  font-weight: 500;
  padding-right: 2.5rem; }

.dataTable tr.child .dtr-data {
  display: table-cell;
  padding: 0.5rem 0; }

.dataTable tr td.child {
  white-space: normal;
  position: relative; }
  .dataTable tr td.child > ul {
    display: table;
    table-layout: fixed;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0; }
    .dataTable tr td.child > ul > li {
      display: table-row; }

/* ------------------------------------------------------------------------------
*
*  # Chart styling
*
*  Charts base - container and sizing setup
*
* ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
*
*  # C3 charts
*
*  Styles for C3.js visualization library
*
* ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
*
*  # D3.js library
*
*  Basic styles for D3.js visualization library
*
* ---------------------------------------------------------------------------- */
.d3-axis path {
  fill: none;
  stroke: #999;
  shape-rendering: crispEdges; }

.d3-axis line {
  stroke: #f5f5f5;
  shape-rendering: crispEdges; }

.d3-axis .tick,
.d3-axis .tick text {
  font-size: 0.75rem;
  fill: #333; }

.d3-axis-solid path,
.d3-axis-solid line {
  stroke: #ccc; }

.d3-axis-solid .tick {
  fill: #999; }
  .d3-axis-solid .tick text {
    fill: #999; }

.d3-axis-strong path,
.d3-axis-strong line {
  stroke: #ccc; }

.d3-axis-strong .tick {
  fill: #333; }
  .d3-axis-strong .tick text {
    fill: #333; }

.d3-axis-transparent path {
  stroke: none; }

.d3-axis-transparent line {
  stroke: #eee; }

.d3-axis-transparent .tick {
  fill: #999; }
  .d3-axis-transparent .tick text {
    fill: #999; }

.d3-tip {
  position: absolute;
  padding: 0.5rem 0.75rem;
  background-color: #000;
  color: #fff;
  margin-bottom: -0.25rem;
  font-size: 0.8125rem;
  z-index: 1070;
  border-radius: 0.1875rem; }
  .d3-tip .d3-tip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid; }

.d3-tip.n .d3-tip-arrow {
  bottom: -0.25rem;
  left: 50%;
  margin-left: -0.25rem;
  border-width: 0.25rem 0.25rem 0;
  border-top-color: #000; }

.d3-tip.e .d3-tip-arrow {
  top: 50%;
  margin-top: -0.25rem;
  /*rtl:begin:ignore*/
  left: -0.25rem;
  border-width: 0.25rem 0.25rem 0.25rem 0;
  border-right-color: #000;
  /*rtl:end:ignore*/ }

.d3-tip.w .d3-tip-arrow {
  top: 50%;
  margin-top: -0.25rem;
  /*rtl:begin:ignore*/
  right: -0.25rem;
  border-width: 0.25rem 0 0.25rem 0.25rem;
  border-left-color: #000;
  /*rtl:end:ignore*/ }

.d3-tip.s .d3-tip-arrow {
  top: 0;
  left: 50%;
  margin-left: -0.25rem;
  border-width: 0 0.25rem 0.25rem;
  border-bottom-color: #000; }

.venntooltip {
  position: absolute;
  text-align: center;
  white-space: nowrap;
  background-color: #000;
  color: #fff;
  padding: 0.5rem 0.75rem;
  display: none;
  border-radius: 0.1875rem; }

.d3-line {
  fill: none; }
  .d3-line-thin {
    stroke-width: 1px; }
  .d3-line-medium {
    stroke-width: 1.5px; }
  .d3-line-strong {
    stroke-width: 2px; }

.d3-line-circle {
  fill: none;
  cursor: pointer; }
  .d3-line-circle-thin {
    stroke-width: 1px; }
  .d3-line-circle-medium {
    stroke-width: 1.5px; }
  .d3-line-circle-strong {
    stroke-width: 2px; }

.d3-grid .tick line {
  stroke-width: 1px;
  stroke: #eee;
  shape-rendering: crispEdges; }

.d3-grid .tick text {
  fill: #333;
  stroke: none; }

.d3-grid-dashed .tick {
  stroke-dasharray: 4,2;
  stroke-width: 1px;
  stroke: #eee;
  shape-rendering: crispEdges; }

.d3-grid-dashed path {
  stroke-width: 0; }

.d3-crosshair-overlay {
  fill: none;
  pointer-events: all; }

.bullet-tick line {
  stroke: #999;
  stroke-width: 1px;
  shape-rendering: crispEdges; }

.bullet-tick text {
  fill: #999;
  font-size: 0.75rem; }

.bullet-marker {
  stroke-width: 2px;
  shape-rendering: crispEdges; }

.bullet-title {
  font-size: 0.8125rem;
  font-weight: 500; }

.bullet-subtitle {
  fill: #999; }

.bullet-1 .bullet-range-1 {
  fill: rgba(96, 125, 139, 0.8); }

.bullet-1 .bullet-range-2 {
  fill: rgba(96, 125, 139, 0.4); }

.bullet-1 .bullet-range-3 {
  fill: #607D8B; }

.bullet-1 .bullet-measure-1 {
  fill: rgba(84, 110, 122, 0.9); }

.bullet-1 .bullet-measure-2 {
  fill: #fff; }

.bullet-1 .bullet-marker {
  stroke: #37474F; }

.bullet-2 .bullet-range-1 {
  fill: rgba(255, 112, 67, 0.6); }

.bullet-2 .bullet-range-2 {
  fill: rgba(255, 112, 67, 0.3); }

.bullet-2 .bullet-range-3 {
  fill: #FF7043; }

.bullet-2 .bullet-measure-1 {
  fill: rgba(244, 81, 30, 0.9); }

.bullet-2 .bullet-measure-2 {
  fill: #fff; }

.bullet-2 .bullet-marker {
  stroke: #D84315; }

.bullet-3 .bullet-range-1 {
  fill: rgba(102, 187, 106, 0.7); }

.bullet-3 .bullet-range-2 {
  fill: rgba(102, 187, 106, 0.35); }

.bullet-3 .bullet-range-3 {
  fill: #66BB6A; }

.bullet-3 .bullet-measure-1 {
  fill: rgba(67, 160, 71, 0.9); }

.bullet-3 .bullet-measure-2 {
  fill: #fff; }

.bullet-3 .bullet-marker {
  stroke: #2E7D32; }

.counter-icon {
  font-size: 2rem;
  position: absolute;
  left: 50%;
  margin-left: -1rem; }

.chart-widget-legend {
  margin: 0.625rem 0 0 0;
  padding: 0;
  font-size: 0.75rem;
  text-align: center; }
  .chart-widget-legend li {
    margin: 0.3125rem 0.625rem 0;
    padding: 0.4375rem 0.5rem 0.3125rem;
    display: inline-block; }

/* ------------------------------------------------------------------------------
*
*  # Header elements
*
*  Display default and custom components in page header, card header and breadcrumbs
*
* ---------------------------------------------------------------------------- */
.card-header .header-elements {
  padding-top: 0.9375rem; }

.page-header-content .header-elements {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding: 1.25rem 1.25rem;
  border-top: 1px solid #cfcfcf;
  border-bottom: 1px solid #cfcfcf;
  background-color: #efefef; }

.page-header-light .header-elements {
  background-color: #fafafa;
  border-color: #ddd;
  border-bottom: 0; }

.page-header-dark .header-elements {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
  border-bottom: 0; }

.breadcrumb-line .header-elements {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding: 0 1.25rem;
  border-top: 1px solid transparent; }

.breadcrumb-line-light .header-elements {
  background-color: transparent;
  border-color: #ddd; }

.breadcrumb-line-dark .header-elements {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(255, 255, 255, 0.25); }

.header-elements .form-group:last-child {
  margin-bottom: 0; }

.header-elements-toggle,
.footer-elements-toggle {
  margin-left: auto;
  -ms-flex-item-align: center;
      align-self: center; }

.header-elements-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
  .header-elements-inline .header-elements {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 0;
    background-color: transparent;
    border: 0;
    margin-left: 0;
    margin-right: 0; }
  .header-elements-inline .card-header-tabs .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem; }

@media (min-width: 576px) {
  .header-elements-sm-inline {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
    .header-elements-sm-inline .header-elements {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      padding: 0;
      background-color: transparent;
      border: 0;
      margin-left: 0;
      margin-right: 0; }
    .header-elements-sm-inline .card-header-tabs .nav-link {
      padding-top: 1rem;
      padding-bottom: 1rem; } }

@media (min-width: 768px) {
  .header-elements-md-inline {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
    .header-elements-md-inline .header-elements {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      padding: 0;
      background-color: transparent;
      border: 0;
      margin-left: 0;
      margin-right: 0; }
    .header-elements-md-inline .card-header-tabs .nav-link {
      padding-top: 1rem;
      padding-bottom: 1rem; } }

@media (min-width: 992px) {
  .header-elements-lg-inline {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
    .header-elements-lg-inline .header-elements {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      padding: 0;
      background-color: transparent;
      border: 0;
      margin-left: 0;
      margin-right: 0; }
    .header-elements-lg-inline .card-header-tabs .nav-link {
      padding-top: 1rem;
      padding-bottom: 1rem; } }

@media (min-width: 1200px) {
  .header-elements-xl-inline {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
    .header-elements-xl-inline .header-elements {
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      padding: 0;
      background-color: transparent;
      border: 0;
      margin-left: 0;
      margin-right: 0; }
    .header-elements-xl-inline .card-header-tabs .nav-link {
      padding-top: 1rem;
      padding-bottom: 1rem; } }

/* ------------------------------------------------------------------------------
 *
 *  # Helper classes
 *
 *  Custom helper classes used in the template.
 *
 * ---------------------------------------------------------------------------- */
.font-size-lg {
  font-size: 0.875rem; }

.font-size-base {
  font-size: 0.8125rem; }

.font-size-sm {
  font-size: 0.75rem; }

.font-size-xs {
  font-size: 0.6875rem; }

.line-height-lg {
  line-height: 1.4286; }

.line-height-base {
  line-height: 1.5385; }

.line-height-sm {
  line-height: 1.6667; }

.line-height-xs {
  line-height: 1.82; }

.line-height-1 {
  line-height: 1; }

.opacity-75 {
  opacity: 0.75; }

.opacity-50 {
  opacity: 0.5; }

.opacity-25 {
  opacity: 0.25; }

.opacity-0 {
  opacity: 0; }

.top-0 {
  top: 0; }

.top-auto {
  top: auto; }

.bottom-0 {
  bottom: 0; }

.bottom-auto {
  bottom: auto; }

.left-0 {
  left: 0; }

.left-auto {
  left: auto; }

.right-0 {
  right: 0; }

.right-auto {
  right: auto; }

.cursor-pointer {
  cursor: pointer; }

.cursor-move {
  cursor: move; }

.cursor-default {
  cursor: default; }

.cursor-disabled {
  cursor: default; }

.overflow-hidden {
  overflow: hidden; }

.overflow-visible {
  overflow: visible; }

.overflow-auto {
  overflow: auto; }

.border-width-0 {
  border-width: 0; }

.border-y-0 {
  border-top: 0 !important;
  border-bottom: 0 !important; }

.border-x-0 {
  border-left: 0 !important;
  border-right: 0 !important; }

.border-1 {
  border: 1px solid; }

.border-top-1 {
  border-top: 1px solid; }

.border-bottom-1 {
  border-bottom: 1px solid; }

.border-left-1 {
  border-left: 1px solid; }

.border-right-1 {
  border-right: 1px solid; }

.border-y-1 {
  border-top: 1px solid;
  border-bottom: 1px solid; }

.border-x-1 {
  border-left: 1px solid;
  border-right: 1px solid; }

.border-2 {
  border: 2px solid; }

.border-top-2 {
  border-top: 2px solid; }

.border-bottom-2 {
  border-bottom: 2px solid; }

.border-left-2 {
  border-left: 2px solid; }

.border-right-2 {
  border-right: 2px solid; }

.border-y-2 {
  border-top: 2px solid;
  border-bottom: 2px solid; }

.border-x-2 {
  border-left: 2px solid;
  border-right: 2px solid; }

.border-3 {
  border: 3px solid; }

.border-top-3 {
  border-top: 3px solid; }

.border-bottom-3 {
  border-bottom: 3px solid; }

.border-left-3 {
  border-left: 3px solid; }

.border-right-3 {
  border-right: 3px solid; }

.border-y-3 {
  border-top: 3px solid;
  border-bottom: 3px solid; }

.border-x-3 {
  border-left: 3px solid;
  border-right: 3px solid; }

.border-dashed {
  border-style: dashed; }

.border-top-dashed {
  border-top-style: dashed; }

.border-bottom-dashed {
  border-bottom-style: dashed; }

.border-left-dashed {
  border-left-style: dashed; }

.border-right-dashed {
  border-right-style: dashed; }

.border-dotted {
  border-style: dotted; }

.rounded-round {
  border-radius: 100px !important; }

.rounded-top-round {
  border-top-left-radius: 100px !important;
  border-top-right-radius: 100px !important; }

.rounded-bottom-round {
  border-bottom-right-radius: 100px !important;
  border-bottom-left-radius: 100px !important; }

.rounded-left-round {
  border-top-left-radius: 100px !important;
  border-bottom-left-radius: 100px !important; }

.rounded-right-round {
  border-top-right-radius: 100px !important;
  border-bottom-right-radius: 100px !important; }

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rotate-45 {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.rotate-90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.rotate-180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.rotate-45-inverse {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

.rotate-90-inverse {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }

.rotate-180-inverse {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }

.spinner {
  display: inline-block;
  -webkit-animation: rotation 1s linear infinite;
          animation: rotation 1s linear infinite; }

.spinner-reverse {
  display: inline-block;
  -webkit-animation: rotation 1s linear infinite;
          animation: rotation 1s linear infinite; }

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes rotation_reverse {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(-360deg); } }

@keyframes rotation_reverse {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg); } }

.h-auto {
  height: auto; }

.w-auto {
  width: auto !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.wmin-0 {
  min-width: 0 !important; }

.wmin-200 {
  min-width: 200px !important; }

.wmin-250 {
  min-width: 250px !important; }

.wmin-300 {
  min-width: 300px !important; }

.wmin-350 {
  min-width: 350px !important; }

.wmin-400 {
  min-width: 400px !important; }

.wmin-450 {
  min-width: 450px !important; }

.wmin-500 {
  min-width: 500px !important; }

.wmin-550 {
  min-width: 550px !important; }

.wmin-600 {
  min-width: 600px !important; }

.flex-1 {
  -ms-flex: 1;
      flex: 1; }

@media (min-width: 576px) {
  .w-sm-auto {
    width: auto !important; }
  .w-sm-25 {
    width: 25% !important; }
  .w-sm-50 {
    width: 50% !important; }
  .w-sm-75 {
    width: 75% !important; }
  .w-sm-100 {
    width: 100% !important; }
  .wmin-sm-0 {
    min-width: 0 !important; }
  .wmin-sm-200 {
    min-width: 200px !important; }
  .wmin-sm-250 {
    min-width: 250px !important; }
  .wmin-sm-300 {
    min-width: 300px !important; }
  .wmin-sm-350 {
    min-width: 350px !important; }
  .wmin-sm-400 {
    min-width: 400px !important; }
  .wmin-sm-450 {
    min-width: 450px !important; }
  .wmin-sm-500 {
    min-width: 500px !important; }
  .wmin-sm-550 {
    min-width: 550px !important; }
  .wmin-sm-600 {
    min-width: 600px !important; }
  .flex-sm-1 {
    -ms-flex: 1;
        flex: 1; } }

@media (min-width: 768px) {
  .w-md-auto {
    width: auto !important; }
  .w-md-25 {
    width: 25% !important; }
  .w-md-50 {
    width: 50% !important; }
  .w-md-75 {
    width: 75% !important; }
  .w-md-100 {
    width: 100% !important; }
  .wmin-md-0 {
    min-width: 0 !important; }
  .wmin-md-200 {
    min-width: 200px !important; }
  .wmin-md-250 {
    min-width: 250px !important; }
  .wmin-md-300 {
    min-width: 300px !important; }
  .wmin-md-350 {
    min-width: 350px !important; }
  .wmin-md-400 {
    min-width: 400px !important; }
  .wmin-md-450 {
    min-width: 450px !important; }
  .wmin-md-500 {
    min-width: 500px !important; }
  .wmin-md-550 {
    min-width: 550px !important; }
  .wmin-md-600 {
    min-width: 600px !important; }
  .flex-md-1 {
    -ms-flex: 1;
        flex: 1; } }

@media (min-width: 992px) {
  .w-lg-auto {
    width: auto !important; }
  .w-lg-25 {
    width: 25% !important; }
  .w-lg-50 {
    width: 50% !important; }
  .w-lg-75 {
    width: 75% !important; }
  .w-lg-100 {
    width: 100% !important; }
  .wmin-lg-0 {
    min-width: 0 !important; }
  .wmin-lg-200 {
    min-width: 200px !important; }
  .wmin-lg-250 {
    min-width: 250px !important; }
  .wmin-lg-300 {
    min-width: 300px !important; }
  .wmin-lg-350 {
    min-width: 350px !important; }
  .wmin-lg-400 {
    min-width: 400px !important; }
  .wmin-lg-450 {
    min-width: 450px !important; }
  .wmin-lg-500 {
    min-width: 500px !important; }
  .wmin-lg-550 {
    min-width: 550px !important; }
  .wmin-lg-600 {
    min-width: 600px !important; }
  .flex-lg-1 {
    -ms-flex: 1;
        flex: 1; } }

@media (min-width: 1200px) {
  .w-xl-auto {
    width: auto !important; }
  .w-xl-25 {
    width: 25% !important; }
  .w-xl-50 {
    width: 50% !important; }
  .w-xl-75 {
    width: 75% !important; }
  .w-xl-100 {
    width: 100% !important; }
  .wmin-xl-0 {
    min-width: 0 !important; }
  .wmin-xl-200 {
    min-width: 200px !important; }
  .wmin-xl-250 {
    min-width: 250px !important; }
  .wmin-xl-300 {
    min-width: 300px !important; }
  .wmin-xl-350 {
    min-width: 350px !important; }
  .wmin-xl-400 {
    min-width: 400px !important; }
  .wmin-xl-450 {
    min-width: 450px !important; }
  .wmin-xl-500 {
    min-width: 500px !important; }
  .wmin-xl-550 {
    min-width: 550px !important; }
  .wmin-xl-600 {
    min-width: 600px !important; }
  .flex-xl-1 {
    -ms-flex: 1;
        flex: 1; } }

.no-transitions *, .no-transitions *:before, .no-transitions *:after {
  transition: none !important; }
  @media screen and (prefers-reduced-motion: reduce) {
    .no-transitions *, .no-transitions *:before, .no-transitions *:after {
      transition: none; } }

.caret-0::before, .caret-0::after {
  content: none; }

.shadow-0 {
  box-shadow: none !important; }

.outline-0, .outline-0:hover, .outline-0:focus {
  outline: 0; }

.text-shadow-dark {
  text-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.5); }

.text-shadow-light {
  text-shadow: 0 0 0.1875rem rgba(255, 255, 255, 0.5); }

/* ------------------------------
 *
 *  Theme specific styles
 *
 * ------------------------------ */
/* ------------------------------------------------------------------------------
 *
 *  # Custom color system
 *
 *  Custom color system styles, includes background, border and text colors
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Global configuration
 *
 *  Here you can change main theme, enable or disable certain components and
 *  optional styles. This allows you to include only components that you need.
 *
 *  'true'  - enables component and includes it to main CSS file.
 *  'false' - disables component and excludes it from main CSS file.
 *
 *  Layout helper: @if $layout == 'base' {...}
 *  Theme helper: @if $theme == 'material' {...}
 *  Component helper: @if $enable-* {...}
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Custom Limitless functions
 *
 *  Utility mixins and functions for evalutating source code across our variables, maps, and mixins.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Custom template mixins
 *
 *  All custom mixins are prefixed with "ll-" to avoid conflicts
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Main colors
 *
 *  List of the template main color palettes
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Default Bootstrap variable overrides
 *
 *  Variables should follow the `$component-state-property-size` formula for
 *  consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
 *  Also includes custom variables, all marked with "!default" flag.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Additional variables
 *
 *  Mainly 3rd party libraries and additional variables for default
 *  Bootstrap components.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Mixins
 *
 *  Import Bootstrap mixins with overrides
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Badge mixin
 *
 *  Override and extend default badge mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Buttons mixin
 *
 *  Override and extend default buttons mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Caret mixin
 *
 *  Override and extend default cared mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Forms mixin
 *
 *  Override and extend default forms mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Background mixin
 *
 *  Override and extend default background mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Custom color system
 *
 *  Custom color system styles. Includes background, border and text colors
 *
 * ---------------------------------------------------------------------------- */
.alpha-primary {
  background-color: #E3F2FD; }

.text-primary {
  color: #2196F3; }
  .text-primary[href]:hover, .text-primary[href]:focus {
    color: #0c83e2; }

.bg-primary {
  background-color: #2196F3; }

.border-primary {
  border-color: #2196F3; }

.border-top-primary {
  border-top-color: #2196F3; }

.border-bottom-primary {
  border-bottom-color: #2196F3; }

.border-left-primary {
  border-left-color: #2196F3; }

.border-right-primary {
  border-right-color: #2196F3; }

.text-primary-300 {
  color: #64B5F6; }
  .text-primary-300[href]:hover, .text-primary-300[href]:focus {
    color: #40a4f4; }

.bg-primary-300 {
  background-color: #64B5F6; }

.border-primary-300 {
  border-color: #64B5F6; }

.border-top-primary-300 {
  border-top-color: #64B5F6; }

.border-bottom-primary-300 {
  border-bottom-color: #64B5F6; }

.border-left-primary-300 {
  border-left-color: #64B5F6; }

.border-right-primary-300 {
  border-right-color: #64B5F6; }

.text-primary-400 {
  color: #42A5F5; }
  .text-primary-400[href]:hover, .text-primary-400[href]:focus {
    color: #1e94f3; }

.bg-primary-400 {
  background-color: #42A5F5; }

.border-primary-400 {
  border-color: #42A5F5; }

.border-top-primary-400 {
  border-top-color: #42A5F5; }

.border-bottom-primary-400 {
  border-bottom-color: #42A5F5; }

.border-left-primary-400 {
  border-left-color: #42A5F5; }

.border-right-primary-400 {
  border-right-color: #42A5F5; }

.text-primary-600 {
  color: #1E88E5; }
  .text-primary-600[href]:hover, .text-primary-600[href]:focus {
    color: #1774c6; }

.bg-primary-600 {
  background-color: #1E88E5; }

.border-primary-600 {
  border-color: #1E88E5; }

.border-top-primary-600 {
  border-top-color: #1E88E5; }

.border-bottom-primary-600 {
  border-bottom-color: #1E88E5; }

.border-left-primary-600 {
  border-left-color: #1E88E5; }

.border-right-primary-600 {
  border-right-color: #1E88E5; }

.text-primary-700 {
  color: #1976D2; }
  .text-primary-700[href]:hover, .text-primary-700[href]:focus {
    color: #1563b0; }

.bg-primary-700 {
  background-color: #1976D2; }

.border-primary-700 {
  border-color: #1976D2; }

.border-top-primary-700 {
  border-top-color: #1976D2; }

.border-bottom-primary-700 {
  border-bottom-color: #1976D2; }

.border-left-primary-700 {
  border-left-color: #1976D2; }

.border-right-primary-700 {
  border-right-color: #1976D2; }

.text-primary-800 {
  color: #1565C0; }
  .text-primary-800[href]:hover, .text-primary-800[href]:focus {
    color: #11539e; }

.bg-primary-800 {
  background-color: #1565C0; }

.border-primary-800 {
  border-color: #1565C0; }

.border-top-primary-800 {
  border-top-color: #1565C0; }

.border-bottom-primary-800 {
  border-bottom-color: #1565C0; }

.border-left-primary-800 {
  border-left-color: #1565C0; }

.border-right-primary-800 {
  border-right-color: #1565C0; }

.alpha-danger {
  background-color: #FFEBEE; }

.text-danger {
  color: #F44336; }
  .text-danger[href]:hover, .text-danger[href]:focus {
    color: #f22112; }

.bg-danger {
  background-color: #F44336; }

.border-danger {
  border-color: #F44336; }

.border-top-danger {
  border-top-color: #F44336; }

.border-bottom-danger {
  border-bottom-color: #F44336; }

.border-left-danger {
  border-left-color: #F44336; }

.border-right-danger {
  border-right-color: #F44336; }

.text-danger-300 {
  color: #E57373; }
  .text-danger-300[href]:hover, .text-danger-300[href]:focus {
    color: #df5353; }

.bg-danger-300 {
  background-color: #E57373; }

.border-danger-300 {
  border-color: #E57373; }

.border-top-danger-300 {
  border-top-color: #E57373; }

.border-bottom-danger-300 {
  border-bottom-color: #E57373; }

.border-left-danger-300 {
  border-left-color: #E57373; }

.border-right-danger-300 {
  border-right-color: #E57373; }

.text-danger-400 {
  color: #EF5350; }
  .text-danger-400[href]:hover, .text-danger-400[href]:focus {
    color: #ec312d; }

.bg-danger-400 {
  background-color: #EF5350; }

.border-danger-400 {
  border-color: #EF5350; }

.border-top-danger-400 {
  border-top-color: #EF5350; }

.border-bottom-danger-400 {
  border-bottom-color: #EF5350; }

.border-left-danger-400 {
  border-left-color: #EF5350; }

.border-right-danger-400 {
  border-right-color: #EF5350; }

.text-danger-600 {
  color: #E53935; }
  .text-danger-600[href]:hover, .text-danger-600[href]:focus {
    color: #d8201c; }

.bg-danger-600 {
  background-color: #E53935; }

.border-danger-600 {
  border-color: #E53935; }

.border-top-danger-600 {
  border-top-color: #E53935; }

.border-bottom-danger-600 {
  border-bottom-color: #E53935; }

.border-left-danger-600 {
  border-left-color: #E53935; }

.border-right-danger-600 {
  border-right-color: #E53935; }

.text-danger-700 {
  color: #D32F2F; }
  .text-danger-700[href]:hover, .text-danger-700[href]:focus {
    color: #b52626; }

.bg-danger-700 {
  background-color: #D32F2F; }

.border-danger-700 {
  border-color: #D32F2F; }

.border-top-danger-700 {
  border-top-color: #D32F2F; }

.border-bottom-danger-700 {
  border-bottom-color: #D32F2F; }

.border-left-danger-700 {
  border-left-color: #D32F2F; }

.border-right-danger-700 {
  border-right-color: #D32F2F; }

.text-danger-800 {
  color: #C62828; }
  .text-danger-800[href]:hover, .text-danger-800[href]:focus {
    color: #a62222; }

.bg-danger-800 {
  background-color: #C62828; }

.border-danger-800 {
  border-color: #C62828; }

.border-top-danger-800 {
  border-top-color: #C62828; }

.border-bottom-danger-800 {
  border-bottom-color: #C62828; }

.border-left-danger-800 {
  border-left-color: #C62828; }

.border-right-danger-800 {
  border-right-color: #C62828; }

.alpha-success {
  background-color: #E8F5E9; }

.text-success {
  color: #4CAF50; }
  .text-success[href]:hover, .text-success[href]:focus {
    color: #409444; }

.bg-success {
  background-color: #4CAF50; }

.border-success {
  border-color: #4CAF50; }

.border-top-success {
  border-top-color: #4CAF50; }

.border-bottom-success {
  border-bottom-color: #4CAF50; }

.border-left-success {
  border-left-color: #4CAF50; }

.border-right-success {
  border-right-color: #4CAF50; }

.text-success-300 {
  color: #81C784; }
  .text-success-300[href]:hover, .text-success-300[href]:focus {
    color: #67bb6a; }

.bg-success-300 {
  background-color: #81C784; }

.border-success-300 {
  border-color: #81C784; }

.border-top-success-300 {
  border-top-color: #81C784; }

.border-bottom-success-300 {
  border-bottom-color: #81C784; }

.border-left-success-300 {
  border-left-color: #81C784; }

.border-right-success-300 {
  border-right-color: #81C784; }

.text-success-400 {
  color: #66BB6A; }
  .text-success-400[href]:hover, .text-success-400[href]:focus {
    color: #4dae52; }

.bg-success-400 {
  background-color: #66BB6A; }

.border-success-400 {
  border-color: #66BB6A; }

.border-top-success-400 {
  border-top-color: #66BB6A; }

.border-bottom-success-400 {
  border-bottom-color: #66BB6A; }

.border-left-success-400 {
  border-left-color: #66BB6A; }

.border-right-success-400 {
  border-right-color: #66BB6A; }

.text-success-600 {
  color: #43A047; }
  .text-success-600[href]:hover, .text-success-600[href]:focus {
    color: #38853b; }

.bg-success-600 {
  background-color: #43A047; }

.border-success-600 {
  border-color: #43A047; }

.border-top-success-600 {
  border-top-color: #43A047; }

.border-bottom-success-600 {
  border-bottom-color: #43A047; }

.border-left-success-600 {
  border-left-color: #43A047; }

.border-right-success-600 {
  border-right-color: #43A047; }

.text-success-700 {
  color: #388E3C; }
  .text-success-700[href]:hover, .text-success-700[href]:focus {
    color: #2d7330; }

.bg-success-700 {
  background-color: #388E3C; }

.border-success-700 {
  border-color: #388E3C; }

.border-top-success-700 {
  border-top-color: #388E3C; }

.border-bottom-success-700 {
  border-bottom-color: #388E3C; }

.border-left-success-700 {
  border-left-color: #388E3C; }

.border-right-success-700 {
  border-right-color: #388E3C; }

.text-success-800 {
  color: #2E7D32; }
  .text-success-800[href]:hover, .text-success-800[href]:focus {
    color: #246127; }

.bg-success-800 {
  background-color: #2E7D32; }

.border-success-800 {
  border-color: #2E7D32; }

.border-top-success-800 {
  border-top-color: #2E7D32; }

.border-bottom-success-800 {
  border-bottom-color: #2E7D32; }

.border-left-success-800 {
  border-left-color: #2E7D32; }

.border-right-success-800 {
  border-right-color: #2E7D32; }

.alpha-warning {
  background-color: #FBE9E7; }

.text-warning {
  color: #FF5722; }
  .text-warning[href]:hover, .text-warning[href]:focus {
    color: #fb3c00; }

.bg-warning {
  background-color: #FF5722; }

.border-warning {
  border-color: #FF5722; }

.border-top-warning {
  border-top-color: #FF5722; }

.border-bottom-warning {
  border-bottom-color: #FF5722; }

.border-left-warning {
  border-left-color: #FF5722; }

.border-right-warning {
  border-right-color: #FF5722; }

.text-warning-300 {
  color: #FF8A65; }
  .text-warning-300[href]:hover, .text-warning-300[href]:focus {
    color: #ff6d3f; }

.bg-warning-300 {
  background-color: #FF8A65; }

.border-warning-300 {
  border-color: #FF8A65; }

.border-top-warning-300 {
  border-top-color: #FF8A65; }

.border-bottom-warning-300 {
  border-bottom-color: #FF8A65; }

.border-left-warning-300 {
  border-left-color: #FF8A65; }

.border-right-warning-300 {
  border-right-color: #FF8A65; }

.text-warning-400 {
  color: #FF7043; }
  .text-warning-400[href]:hover, .text-warning-400[href]:focus {
    color: #ff531d; }

.bg-warning-400 {
  background-color: #FF7043; }

.border-warning-400 {
  border-color: #FF7043; }

.border-top-warning-400 {
  border-top-color: #FF7043; }

.border-bottom-warning-400 {
  border-bottom-color: #FF7043; }

.border-left-warning-400 {
  border-left-color: #FF7043; }

.border-right-warning-400 {
  border-right-color: #FF7043; }

.text-warning-600 {
  color: #F4511E; }
  .text-warning-600[href]:hover, .text-warning-600[href]:focus {
    color: #e13e0b; }

.bg-warning-600 {
  background-color: #F4511E; }

.border-warning-600 {
  border-color: #F4511E; }

.border-top-warning-600 {
  border-top-color: #F4511E; }

.border-bottom-warning-600 {
  border-bottom-color: #F4511E; }

.border-left-warning-600 {
  border-left-color: #F4511E; }

.border-right-warning-600 {
  border-right-color: #F4511E; }

.text-warning-700 {
  color: #E64A19; }
  .text-warning-700[href]:hover, .text-warning-700[href]:focus {
    color: #c43f15; }

.bg-warning-700 {
  background-color: #E64A19; }

.border-warning-700 {
  border-color: #E64A19; }

.border-top-warning-700 {
  border-top-color: #E64A19; }

.border-bottom-warning-700 {
  border-bottom-color: #E64A19; }

.border-left-warning-700 {
  border-left-color: #E64A19; }

.border-right-warning-700 {
  border-right-color: #E64A19; }

.text-warning-800 {
  color: #D84315; }
  .text-warning-800[href]:hover, .text-warning-800[href]:focus {
    color: #b53812; }

.bg-warning-800 {
  background-color: #D84315; }

.border-warning-800 {
  border-color: #D84315; }

.border-top-warning-800 {
  border-top-color: #D84315; }

.border-bottom-warning-800 {
  border-bottom-color: #D84315; }

.border-left-warning-800 {
  border-left-color: #D84315; }

.border-right-warning-800 {
  border-right-color: #D84315; }

.alpha-info {
  background-color: #E0F7FA; }

.text-info {
  color: #00BCD4; }
  .text-info[href]:hover, .text-info[href]:focus {
    color: #009aae; }

.bg-info {
  background-color: #00BCD4; }

.border-info {
  border-color: #00BCD4; }

.border-top-info {
  border-top-color: #00BCD4; }

.border-bottom-info {
  border-bottom-color: #00BCD4; }

.border-left-info {
  border-left-color: #00BCD4; }

.border-right-info {
  border-right-color: #00BCD4; }

.text-info-300 {
  color: #4DD0E1; }
  .text-info-300[href]:hover, .text-info-300[href]:focus {
    color: #2cc7db; }

.bg-info-300 {
  background-color: #4DD0E1; }

.border-info-300 {
  border-color: #4DD0E1; }

.border-top-info-300 {
  border-top-color: #4DD0E1; }

.border-bottom-info-300 {
  border-bottom-color: #4DD0E1; }

.border-left-info-300 {
  border-left-color: #4DD0E1; }

.border-right-info-300 {
  border-right-color: #4DD0E1; }

.text-info-400 {
  color: #26C6DA; }
  .text-info-400[href]:hover, .text-info-400[href]:focus {
    color: #20a9ba; }

.bg-info-400 {
  background-color: #26C6DA; }

.border-info-400 {
  border-color: #26C6DA; }

.border-top-info-400 {
  border-top-color: #26C6DA; }

.border-bottom-info-400 {
  border-bottom-color: #26C6DA; }

.border-left-info-400 {
  border-left-color: #26C6DA; }

.border-right-info-400 {
  border-right-color: #26C6DA; }

.text-info-600 {
  color: #00ACC1; }
  .text-info-600[href]:hover, .text-info-600[href]:focus {
    color: #008a9b; }

.bg-info-600 {
  background-color: #00ACC1; }

.border-info-600 {
  border-color: #00ACC1; }

.border-top-info-600 {
  border-top-color: #00ACC1; }

.border-bottom-info-600 {
  border-bottom-color: #00ACC1; }

.border-left-info-600 {
  border-left-color: #00ACC1; }

.border-right-info-600 {
  border-right-color: #00ACC1; }

.text-info-700 {
  color: #0097A7; }
  .text-info-700[href]:hover, .text-info-700[href]:focus {
    color: #007481; }

.bg-info-700 {
  background-color: #0097A7; }

.border-info-700 {
  border-color: #0097A7; }

.border-top-info-700 {
  border-top-color: #0097A7; }

.border-bottom-info-700 {
  border-bottom-color: #0097A7; }

.border-left-info-700 {
  border-left-color: #0097A7; }

.border-right-info-700 {
  border-right-color: #0097A7; }

.text-info-800 {
  color: #00838F; }
  .text-info-800[href]:hover, .text-info-800[href]:focus {
    color: #006069; }

.bg-info-800 {
  background-color: #00838F; }

.border-info-800 {
  border-color: #00838F; }

.border-top-info-800 {
  border-top-color: #00838F; }

.border-bottom-info-800 {
  border-bottom-color: #00838F; }

.border-left-info-800 {
  border-left-color: #00838F; }

.border-right-info-800 {
  border-right-color: #00838F; }

.alpha-pink {
  background-color: #FCE4EC; }

.text-pink {
  color: #E91E63; }
  .text-pink[href]:hover, .text-pink[href]:focus {
    color: #cd1453; }

.bg-pink {
  background-color: #E91E63; }

.border-pink {
  border-color: #E91E63; }

.border-top-pink {
  border-top-color: #E91E63; }

.border-bottom-pink {
  border-bottom-color: #E91E63; }

.border-left-pink {
  border-left-color: #E91E63; }

.border-right-pink {
  border-right-color: #E91E63; }

.text-pink-300 {
  color: #F06292; }
  .text-pink-300[href]:hover, .text-pink-300[href]:focus {
    color: #ed3f7a; }

.bg-pink-300 {
  background-color: #F06292; }

.border-pink-300 {
  border-color: #F06292; }

.border-top-pink-300 {
  border-top-color: #F06292; }

.border-bottom-pink-300 {
  border-bottom-color: #F06292; }

.border-left-pink-300 {
  border-left-color: #F06292; }

.border-right-pink-300 {
  border-right-color: #F06292; }

.text-pink-400 {
  color: #EC407A; }
  .text-pink-400[href]:hover, .text-pink-400[href]:focus {
    color: #e91d62; }

.bg-pink-400 {
  background-color: #EC407A; }

.border-pink-400 {
  border-color: #EC407A; }

.border-top-pink-400 {
  border-top-color: #EC407A; }

.border-bottom-pink-400 {
  border-bottom-color: #EC407A; }

.border-left-pink-400 {
  border-left-color: #EC407A; }

.border-right-pink-400 {
  border-right-color: #EC407A; }

.text-pink-600 {
  color: #D81B60; }
  .text-pink-600[href]:hover, .text-pink-600[href]:focus {
    color: #b61751; }

.bg-pink-600 {
  background-color: #D81B60; }

.border-pink-600 {
  border-color: #D81B60; }

.border-top-pink-600 {
  border-top-color: #D81B60; }

.border-bottom-pink-600 {
  border-bottom-color: #D81B60; }

.border-left-pink-600 {
  border-left-color: #D81B60; }

.border-right-pink-600 {
  border-right-color: #D81B60; }

.text-pink-700 {
  color: #C2185B; }
  .text-pink-700[href]:hover, .text-pink-700[href]:focus {
    color: #a0144b; }

.bg-pink-700 {
  background-color: #C2185B; }

.border-pink-700 {
  border-color: #C2185B; }

.border-top-pink-700 {
  border-top-color: #C2185B; }

.border-bottom-pink-700 {
  border-bottom-color: #C2185B; }

.border-left-pink-700 {
  border-left-color: #C2185B; }

.border-right-pink-700 {
  border-right-color: #C2185B; }

.text-pink-800 {
  color: #AD1457; }
  .text-pink-800[href]:hover, .text-pink-800[href]:focus {
    color: #8b1046; }

.bg-pink-800 {
  background-color: #AD1457; }

.border-pink-800 {
  border-color: #AD1457; }

.border-top-pink-800 {
  border-top-color: #AD1457; }

.border-bottom-pink-800 {
  border-bottom-color: #AD1457; }

.border-left-pink-800 {
  border-left-color: #AD1457; }

.border-right-pink-800 {
  border-right-color: #AD1457; }

.alpha-violet {
  background-color: #F3E5F5; }

.text-violet {
  color: #9C27B0; }
  .text-violet[href]:hover, .text-violet[href]:focus {
    color: #802091; }

.bg-violet {
  background-color: #9C27B0; }

.border-violet {
  border-color: #9C27B0; }

.border-top-violet {
  border-top-color: #9C27B0; }

.border-bottom-violet {
  border-bottom-color: #9C27B0; }

.border-left-violet {
  border-left-color: #9C27B0; }

.border-right-violet {
  border-right-color: #9C27B0; }

.text-violet-300 {
  color: #BA68C8; }
  .text-violet-300[href]:hover, .text-violet-300[href]:focus {
    color: #ad4cbe; }

.bg-violet-300 {
  background-color: #BA68C8; }

.border-violet-300 {
  border-color: #BA68C8; }

.border-top-violet-300 {
  border-top-color: #BA68C8; }

.border-bottom-violet-300 {
  border-bottom-color: #BA68C8; }

.border-left-violet-300 {
  border-left-color: #BA68C8; }

.border-right-violet-300 {
  border-right-color: #BA68C8; }

.text-violet-400 {
  color: #AB47BC; }
  .text-violet-400[href]:hover, .text-violet-400[href]:focus {
    color: #933ba2; }

.bg-violet-400 {
  background-color: #AB47BC; }

.border-violet-400 {
  border-color: #AB47BC; }

.border-top-violet-400 {
  border-top-color: #AB47BC; }

.border-bottom-violet-400 {
  border-bottom-color: #AB47BC; }

.border-left-violet-400 {
  border-left-color: #AB47BC; }

.border-right-violet-400 {
  border-right-color: #AB47BC; }

.text-violet-600 {
  color: #8E24AA; }
  .text-violet-600[href]:hover, .text-violet-600[href]:focus {
    color: #741d8a; }

.bg-violet-600 {
  background-color: #8E24AA; }

.border-violet-600 {
  border-color: #8E24AA; }

.border-top-violet-600 {
  border-top-color: #8E24AA; }

.border-bottom-violet-600 {
  border-bottom-color: #8E24AA; }

.border-left-violet-600 {
  border-left-color: #8E24AA; }

.border-right-violet-600 {
  border-right-color: #8E24AA; }

.text-violet-700 {
  color: #7B1FA2; }
  .text-violet-700[href]:hover, .text-violet-700[href]:focus {
    color: #631982; }

.bg-violet-700 {
  background-color: #7B1FA2; }

.border-violet-700 {
  border-color: #7B1FA2; }

.border-top-violet-700 {
  border-top-color: #7B1FA2; }

.border-bottom-violet-700 {
  border-bottom-color: #7B1FA2; }

.border-left-violet-700 {
  border-left-color: #7B1FA2; }

.border-right-violet-700 {
  border-right-color: #7B1FA2; }

.text-violet-800 {
  color: #6A1B9A; }
  .text-violet-800[href]:hover, .text-violet-800[href]:focus {
    color: #541579; }

.bg-violet-800 {
  background-color: #6A1B9A; }

.border-violet-800 {
  border-color: #6A1B9A; }

.border-top-violet-800 {
  border-top-color: #6A1B9A; }

.border-bottom-violet-800 {
  border-bottom-color: #6A1B9A; }

.border-left-violet-800 {
  border-left-color: #6A1B9A; }

.border-right-violet-800 {
  border-right-color: #6A1B9A; }

.alpha-purple {
  background-color: #EDE7F6; }

.text-purple {
  color: #673AB7; }
  .text-purple[href]:hover, .text-purple[href]:focus {
    color: #57319a; }

.bg-purple {
  background-color: #673AB7; }

.border-purple {
  border-color: #673AB7; }

.border-top-purple {
  border-top-color: #673AB7; }

.border-bottom-purple {
  border-bottom-color: #673AB7; }

.border-left-purple {
  border-left-color: #673AB7; }

.border-right-purple {
  border-right-color: #673AB7; }

.text-purple-300 {
  color: #9575CD; }
  .text-purple-300[href]:hover, .text-purple-300[href]:focus {
    color: #7f59c3; }

.bg-purple-300 {
  background-color: #9575CD; }

.border-purple-300 {
  border-color: #9575CD; }

.border-top-purple-300 {
  border-top-color: #9575CD; }

.border-bottom-purple-300 {
  border-bottom-color: #9575CD; }

.border-left-purple-300 {
  border-left-color: #9575CD; }

.border-right-purple-300 {
  border-right-color: #9575CD; }

.text-purple-400 {
  color: #7E57C2; }
  .text-purple-400[href]:hover, .text-purple-400[href]:focus {
    color: #6a41b2; }

.bg-purple-400 {
  background-color: #7E57C2; }

.border-purple-400 {
  border-color: #7E57C2; }

.border-top-purple-400 {
  border-top-color: #7E57C2; }

.border-bottom-purple-400 {
  border-bottom-color: #7E57C2; }

.border-left-purple-400 {
  border-left-color: #7E57C2; }

.border-right-purple-400 {
  border-right-color: #7E57C2; }

.text-purple-600 {
  color: #5E35B1; }
  .text-purple-600[href]:hover, .text-purple-600[href]:focus {
    color: #4e2c94; }

.bg-purple-600 {
  background-color: #5E35B1; }

.border-purple-600 {
  border-color: #5E35B1; }

.border-top-purple-600 {
  border-top-color: #5E35B1; }

.border-bottom-purple-600 {
  border-bottom-color: #5E35B1; }

.border-left-purple-600 {
  border-left-color: #5E35B1; }

.border-right-purple-600 {
  border-right-color: #5E35B1; }

.text-purple-700 {
  color: #512DA8; }
  .text-purple-700[href]:hover, .text-purple-700[href]:focus {
    color: #42258a; }

.bg-purple-700 {
  background-color: #512DA8; }

.border-purple-700 {
  border-color: #512DA8; }

.border-top-purple-700 {
  border-top-color: #512DA8; }

.border-bottom-purple-700 {
  border-bottom-color: #512DA8; }

.border-left-purple-700 {
  border-left-color: #512DA8; }

.border-right-purple-700 {
  border-right-color: #512DA8; }

.text-purple-800 {
  color: #4527A0; }
  .text-purple-800[href]:hover, .text-purple-800[href]:focus {
    color: #382081; }

.bg-purple-800 {
  background-color: #4527A0; }

.border-purple-800 {
  border-color: #4527A0; }

.border-top-purple-800 {
  border-top-color: #4527A0; }

.border-bottom-purple-800 {
  border-bottom-color: #4527A0; }

.border-left-purple-800 {
  border-left-color: #4527A0; }

.border-right-purple-800 {
  border-right-color: #4527A0; }

.alpha-indigo {
  background-color: #E8EAF6; }

.text-indigo {
  color: #3F51B5; }
  .text-indigo[href]:hover, .text-indigo[href]:focus {
    color: #354499; }

.bg-indigo {
  background-color: #3F51B5; }

.border-indigo {
  border-color: #3F51B5; }

.border-top-indigo {
  border-top-color: #3F51B5; }

.border-bottom-indigo {
  border-bottom-color: #3F51B5; }

.border-left-indigo {
  border-left-color: #3F51B5; }

.border-right-indigo {
  border-right-color: #3F51B5; }

.text-indigo-300 {
  color: #7986CB; }
  .text-indigo-300[href]:hover, .text-indigo-300[href]:focus {
    color: #5d6dc0; }

.bg-indigo-300 {
  background-color: #7986CB; }

.border-indigo-300 {
  border-color: #7986CB; }

.border-top-indigo-300 {
  border-top-color: #7986CB; }

.border-bottom-indigo-300 {
  border-bottom-color: #7986CB; }

.border-left-indigo-300 {
  border-left-color: #7986CB; }

.border-right-indigo-300 {
  border-right-color: #7986CB; }

.text-indigo-400 {
  color: #5C6BC0; }
  .text-indigo-400[href]:hover, .text-indigo-400[href]:focus {
    color: #4555b1; }

.bg-indigo-400 {
  background-color: #5C6BC0; }

.border-indigo-400 {
  border-color: #5C6BC0; }

.border-top-indigo-400 {
  border-top-color: #5C6BC0; }

.border-bottom-indigo-400 {
  border-bottom-color: #5C6BC0; }

.border-left-indigo-400 {
  border-left-color: #5C6BC0; }

.border-right-indigo-400 {
  border-right-color: #5C6BC0; }

.text-indigo-600 {
  color: #3949AB; }
  .text-indigo-600[href]:hover, .text-indigo-600[href]:focus {
    color: #2f3d8e; }

.bg-indigo-600 {
  background-color: #3949AB; }

.border-indigo-600 {
  border-color: #3949AB; }

.border-top-indigo-600 {
  border-top-color: #3949AB; }

.border-bottom-indigo-600 {
  border-bottom-color: #3949AB; }

.border-left-indigo-600 {
  border-left-color: #3949AB; }

.border-right-indigo-600 {
  border-right-color: #3949AB; }

.text-indigo-700 {
  color: #303F9F; }
  .text-indigo-700[href]:hover, .text-indigo-700[href]:focus {
    color: #273382; }

.bg-indigo-700 {
  background-color: #303F9F; }

.border-indigo-700 {
  border-color: #303F9F; }

.border-top-indigo-700 {
  border-top-color: #303F9F; }

.border-bottom-indigo-700 {
  border-bottom-color: #303F9F; }

.border-left-indigo-700 {
  border-left-color: #303F9F; }

.border-right-indigo-700 {
  border-right-color: #303F9F; }

.text-indigo-800 {
  color: #283593; }
  .text-indigo-800[href]:hover, .text-indigo-800[href]:focus {
    color: #202a75; }

.bg-indigo-800 {
  background-color: #283593; }

.border-indigo-800 {
  border-color: #283593; }

.border-top-indigo-800 {
  border-top-color: #283593; }

.border-bottom-indigo-800 {
  border-bottom-color: #283593; }

.border-left-indigo-800 {
  border-left-color: #283593; }

.border-right-indigo-800 {
  border-right-color: #283593; }

.alpha-blue {
  background-color: #E1F5FE; }

.text-blue {
  color: #03A9F4; }
  .text-blue[href]:hover, .text-blue[href]:focus {
    color: #038fce; }

.bg-blue {
  background-color: #03A9F4; }

.border-blue {
  border-color: #03A9F4; }

.border-top-blue {
  border-top-color: #03A9F4; }

.border-bottom-blue {
  border-bottom-color: #03A9F4; }

.border-left-blue {
  border-left-color: #03A9F4; }

.border-right-blue {
  border-right-color: #03A9F4; }

.text-blue-300 {
  color: #4FC3F7; }
  .text-blue-300[href]:hover, .text-blue-300[href]:focus {
    color: #2ab7f5; }

.bg-blue-300 {
  background-color: #4FC3F7; }

.border-blue-300 {
  border-color: #4FC3F7; }

.border-top-blue-300 {
  border-top-color: #4FC3F7; }

.border-bottom-blue-300 {
  border-bottom-color: #4FC3F7; }

.border-left-blue-300 {
  border-left-color: #4FC3F7; }

.border-right-blue-300 {
  border-right-color: #4FC3F7; }

.text-blue-400 {
  color: #29B6F6; }
  .text-blue-400[href]:hover, .text-blue-400[href]:focus {
    color: #0aa7ef; }

.bg-blue-400 {
  background-color: #29B6F6; }

.border-blue-400 {
  border-color: #29B6F6; }

.border-top-blue-400 {
  border-top-color: #29B6F6; }

.border-bottom-blue-400 {
  border-bottom-color: #29B6F6; }

.border-left-blue-400 {
  border-left-color: #29B6F6; }

.border-right-blue-400 {
  border-right-color: #29B6F6; }

.text-blue-600 {
  color: #039BE5; }
  .text-blue-600[href]:hover, .text-blue-600[href]:focus {
    color: #0381bf; }

.bg-blue-600 {
  background-color: #039BE5; }

.border-blue-600 {
  border-color: #039BE5; }

.border-top-blue-600 {
  border-top-color: #039BE5; }

.border-bottom-blue-600 {
  border-bottom-color: #039BE5; }

.border-left-blue-600 {
  border-left-color: #039BE5; }

.border-right-blue-600 {
  border-right-color: #039BE5; }

.text-blue-700 {
  color: #0288D1; }
  .text-blue-700[href]:hover, .text-blue-700[href]:focus {
    color: #026fab; }

.bg-blue-700 {
  background-color: #0288D1; }

.border-blue-700 {
  border-color: #0288D1; }

.border-top-blue-700 {
  border-top-color: #0288D1; }

.border-bottom-blue-700 {
  border-bottom-color: #0288D1; }

.border-left-blue-700 {
  border-left-color: #0288D1; }

.border-right-blue-700 {
  border-right-color: #0288D1; }

.text-blue-800 {
  color: #0277BD; }
  .text-blue-800[href]:hover, .text-blue-800[href]:focus {
    color: #025f97; }

.bg-blue-800 {
  background-color: #0277BD; }

.border-blue-800 {
  border-color: #0277BD; }

.border-top-blue-800 {
  border-top-color: #0277BD; }

.border-bottom-blue-800 {
  border-bottom-color: #0277BD; }

.border-left-blue-800 {
  border-left-color: #0277BD; }

.border-right-blue-800 {
  border-right-color: #0277BD; }

.alpha-teal {
  background-color: #E0F2F1; }

.text-teal {
  color: #009688; }
  .text-teal[href]:hover, .text-teal[href]:focus {
    color: #007065; }

.bg-teal {
  background-color: #009688; }

.border-teal {
  border-color: #009688; }

.border-top-teal {
  border-top-color: #009688; }

.border-bottom-teal {
  border-bottom-color: #009688; }

.border-left-teal {
  border-left-color: #009688; }

.border-right-teal {
  border-right-color: #009688; }

.text-teal-300 {
  color: #4DB6AC; }
  .text-teal-300[href]:hover, .text-teal-300[href]:focus {
    color: #409d94; }

.bg-teal-300 {
  background-color: #4DB6AC; }

.border-teal-300 {
  border-color: #4DB6AC; }

.border-top-teal-300 {
  border-top-color: #4DB6AC; }

.border-bottom-teal-300 {
  border-bottom-color: #4DB6AC; }

.border-left-teal-300 {
  border-left-color: #4DB6AC; }

.border-right-teal-300 {
  border-right-color: #4DB6AC; }

.text-teal-400 {
  color: #26A69A; }
  .text-teal-400[href]:hover, .text-teal-400[href]:focus {
    color: #1f877d; }

.bg-teal-400 {
  background-color: #26A69A; }

.border-teal-400 {
  border-color: #26A69A; }

.border-top-teal-400 {
  border-top-color: #26A69A; }

.border-bottom-teal-400 {
  border-bottom-color: #26A69A; }

.border-left-teal-400 {
  border-left-color: #26A69A; }

.border-right-teal-400 {
  border-right-color: #26A69A; }

.text-teal-600 {
  color: #00897B; }
  .text-teal-600[href]:hover, .text-teal-600[href]:focus {
    color: #006359; }

.bg-teal-600 {
  background-color: #00897B; }

.border-teal-600 {
  border-color: #00897B; }

.border-top-teal-600 {
  border-top-color: #00897B; }

.border-bottom-teal-600 {
  border-bottom-color: #00897B; }

.border-left-teal-600 {
  border-left-color: #00897B; }

.border-right-teal-600 {
  border-right-color: #00897B; }

.text-teal-700 {
  color: #00796B; }
  .text-teal-700[href]:hover, .text-teal-700[href]:focus {
    color: #005349; }

.bg-teal-700 {
  background-color: #00796B; }

.border-teal-700 {
  border-color: #00796B; }

.border-top-teal-700 {
  border-top-color: #00796B; }

.border-bottom-teal-700 {
  border-bottom-color: #00796B; }

.border-left-teal-700 {
  border-left-color: #00796B; }

.border-right-teal-700 {
  border-right-color: #00796B; }

.text-teal-800 {
  color: #00695C; }
  .text-teal-800[href]:hover, .text-teal-800[href]:focus {
    color: #00433a; }

.bg-teal-800 {
  background-color: #00695C; }

.border-teal-800 {
  border-color: #00695C; }

.border-top-teal-800 {
  border-top-color: #00695C; }

.border-bottom-teal-800 {
  border-bottom-color: #00695C; }

.border-left-teal-800 {
  border-left-color: #00695C; }

.border-right-teal-800 {
  border-right-color: #00695C; }

.alpha-green {
  background-color: #F1F8E9; }

.text-green {
  color: #8BC34A; }
  .text-green[href]:hover, .text-green[href]:focus {
    color: #78ad39; }

.bg-green {
  background-color: #8BC34A; }

.border-green {
  border-color: #8BC34A; }

.border-top-green {
  border-top-color: #8BC34A; }

.border-bottom-green {
  border-bottom-color: #8BC34A; }

.border-left-green {
  border-left-color: #8BC34A; }

.border-right-green {
  border-right-color: #8BC34A; }

.text-green-300 {
  color: #AED581; }
  .text-green-300[href]:hover, .text-green-300[href]:focus {
    color: #9ccb64; }

.bg-green-300 {
  background-color: #AED581; }

.border-green-300 {
  border-color: #AED581; }

.border-top-green-300 {
  border-top-color: #AED581; }

.border-bottom-green-300 {
  border-bottom-color: #AED581; }

.border-left-green-300 {
  border-left-color: #AED581; }

.border-right-green-300 {
  border-right-color: #AED581; }

.text-green-400 {
  color: #9CCC65; }
  .text-green-400[href]:hover, .text-green-400[href]:focus {
    color: #8ac248; }

.bg-green-400 {
  background-color: #9CCC65; }

.border-green-400 {
  border-color: #9CCC65; }

.border-top-green-400 {
  border-top-color: #9CCC65; }

.border-bottom-green-400 {
  border-bottom-color: #9CCC65; }

.border-left-green-400 {
  border-left-color: #9CCC65; }

.border-right-green-400 {
  border-right-color: #9CCC65; }

.text-green-600 {
  color: #7CB342; }
  .text-green-600[href]:hover, .text-green-600[href]:focus {
    color: #699738; }

.bg-green-600 {
  background-color: #7CB342; }

.border-green-600 {
  border-color: #7CB342; }

.border-top-green-600 {
  border-top-color: #7CB342; }

.border-bottom-green-600 {
  border-bottom-color: #7CB342; }

.border-left-green-600 {
  border-left-color: #7CB342; }

.border-right-green-600 {
  border-right-color: #7CB342; }

.text-green-700 {
  color: #689F38; }
  .text-green-700[href]:hover, .text-green-700[href]:focus {
    color: #55832e; }

.bg-green-700 {
  background-color: #689F38; }

.border-green-700 {
  border-color: #689F38; }

.border-top-green-700 {
  border-top-color: #689F38; }

.border-bottom-green-700 {
  border-bottom-color: #689F38; }

.border-left-green-700 {
  border-left-color: #689F38; }

.border-right-green-700 {
  border-right-color: #689F38; }

.text-green-800 {
  color: #558B2F; }
  .text-green-800[href]:hover, .text-green-800[href]:focus {
    color: #446e25; }

.bg-green-800 {
  background-color: #558B2F; }

.border-green-800 {
  border-color: #558B2F; }

.border-top-green-800 {
  border-top-color: #558B2F; }

.border-bottom-green-800 {
  border-bottom-color: #558B2F; }

.border-left-green-800 {
  border-left-color: #558B2F; }

.border-right-green-800 {
  border-right-color: #558B2F; }

.alpha-orange {
  background-color: #FFF3E0; }

.text-orange {
  color: #FF9800; }
  .text-orange[href]:hover, .text-orange[href]:focus {
    color: #d98100; }

.bg-orange {
  background-color: #FF9800; }

.border-orange {
  border-color: #FF9800; }

.border-top-orange {
  border-top-color: #FF9800; }

.border-bottom-orange {
  border-bottom-color: #FF9800; }

.border-left-orange {
  border-left-color: #FF9800; }

.border-right-orange {
  border-right-color: #FF9800; }

.text-orange-300 {
  color: #FFB74D; }
  .text-orange-300[href]:hover, .text-orange-300[href]:focus {
    color: #ffa827; }

.bg-orange-300 {
  background-color: #FFB74D; }

.border-orange-300 {
  border-color: #FFB74D; }

.border-top-orange-300 {
  border-top-color: #FFB74D; }

.border-bottom-orange-300 {
  border-bottom-color: #FFB74D; }

.border-left-orange-300 {
  border-left-color: #FFB74D; }

.border-right-orange-300 {
  border-right-color: #FFB74D; }

.text-orange-400 {
  color: #FFA726; }
  .text-orange-400[href]:hover, .text-orange-400[href]:focus {
    color: #ff9700; }

.bg-orange-400 {
  background-color: #FFA726; }

.border-orange-400 {
  border-color: #FFA726; }

.border-top-orange-400 {
  border-top-color: #FFA726; }

.border-bottom-orange-400 {
  border-bottom-color: #FFA726; }

.border-left-orange-400 {
  border-left-color: #FFA726; }

.border-right-orange-400 {
  border-right-color: #FFA726; }

.text-orange-600 {
  color: #FB8C00; }
  .text-orange-600[href]:hover, .text-orange-600[href]:focus {
    color: #d57700; }

.bg-orange-600 {
  background-color: #FB8C00; }

.border-orange-600 {
  border-color: #FB8C00; }

.border-top-orange-600 {
  border-top-color: #FB8C00; }

.border-bottom-orange-600 {
  border-bottom-color: #FB8C00; }

.border-left-orange-600 {
  border-left-color: #FB8C00; }

.border-right-orange-600 {
  border-right-color: #FB8C00; }

.text-orange-700 {
  color: #F57C00; }
  .text-orange-700[href]:hover, .text-orange-700[href]:focus {
    color: #cf6900; }

.bg-orange-700 {
  background-color: #F57C00; }

.border-orange-700 {
  border-color: #F57C00; }

.border-top-orange-700 {
  border-top-color: #F57C00; }

.border-bottom-orange-700 {
  border-bottom-color: #F57C00; }

.border-left-orange-700 {
  border-left-color: #F57C00; }

.border-right-orange-700 {
  border-right-color: #F57C00; }

.text-orange-800 {
  color: #EF6C00; }
  .text-orange-800[href]:hover, .text-orange-800[href]:focus {
    color: #c95b00; }

.bg-orange-800 {
  background-color: #EF6C00; }

.border-orange-800 {
  border-color: #EF6C00; }

.border-top-orange-800 {
  border-top-color: #EF6C00; }

.border-bottom-orange-800 {
  border-bottom-color: #EF6C00; }

.border-left-orange-800 {
  border-left-color: #EF6C00; }

.border-right-orange-800 {
  border-right-color: #EF6C00; }

.alpha-brown {
  background-color: #EFEBE9; }

.text-brown {
  color: #795548; }
  .text-brown[href]:hover, .text-brown[href]:focus {
    color: #61443a; }

.bg-brown {
  background-color: #795548; }

.border-brown {
  border-color: #795548; }

.border-top-brown {
  border-top-color: #795548; }

.border-bottom-brown {
  border-bottom-color: #795548; }

.border-left-brown {
  border-left-color: #795548; }

.border-right-brown {
  border-right-color: #795548; }

.text-brown-300 {
  color: #A1887F; }
  .text-brown-300[href]:hover, .text-brown-300[href]:focus {
    color: #90746a; }

.bg-brown-300 {
  background-color: #A1887F; }

.border-brown-300 {
  border-color: #A1887F; }

.border-top-brown-300 {
  border-top-color: #A1887F; }

.border-bottom-brown-300 {
  border-bottom-color: #A1887F; }

.border-left-brown-300 {
  border-left-color: #A1887F; }

.border-right-brown-300 {
  border-right-color: #A1887F; }

.text-brown-400 {
  color: #8D6E63; }
  .text-brown-400[href]:hover, .text-brown-400[href]:focus {
    color: #775c53; }

.bg-brown-400 {
  background-color: #8D6E63; }

.border-brown-400 {
  border-color: #8D6E63; }

.border-top-brown-400 {
  border-top-color: #8D6E63; }

.border-bottom-brown-400 {
  border-bottom-color: #8D6E63; }

.border-left-brown-400 {
  border-left-color: #8D6E63; }

.border-right-brown-400 {
  border-right-color: #8D6E63; }

.text-brown-600 {
  color: #6D4C41; }
  .text-brown-600[href]:hover, .text-brown-600[href]:focus {
    color: #553b33; }

.bg-brown-600 {
  background-color: #6D4C41; }

.border-brown-600 {
  border-color: #6D4C41; }

.border-top-brown-600 {
  border-top-color: #6D4C41; }

.border-bottom-brown-600 {
  border-bottom-color: #6D4C41; }

.border-left-brown-600 {
  border-left-color: #6D4C41; }

.border-right-brown-600 {
  border-right-color: #6D4C41; }

.text-brown-700 {
  color: #5D4037; }
  .text-brown-700[href]:hover, .text-brown-700[href]:focus {
    color: #452f29; }

.bg-brown-700 {
  background-color: #5D4037; }

.border-brown-700 {
  border-color: #5D4037; }

.border-top-brown-700 {
  border-top-color: #5D4037; }

.border-bottom-brown-700 {
  border-bottom-color: #5D4037; }

.border-left-brown-700 {
  border-left-color: #5D4037; }

.border-right-brown-700 {
  border-right-color: #5D4037; }

.text-brown-800 {
  color: #4E342E; }
  .text-brown-800[href]:hover, .text-brown-800[href]:focus {
    color: #362420; }

.bg-brown-800 {
  background-color: #4E342E; }

.border-brown-800 {
  border-color: #4E342E; }

.border-top-brown-800 {
  border-top-color: #4E342E; }

.border-bottom-brown-800 {
  border-bottom-color: #4E342E; }

.border-left-brown-800 {
  border-left-color: #4E342E; }

.border-right-brown-800 {
  border-right-color: #4E342E; }

.alpha-grey {
  background-color: #FAFAFA; }

.text-grey {
  color: #777; }
  .text-grey[href]:hover, .text-grey[href]:focus {
    color: #646464; }

.bg-grey {
  background-color: #777; }

.border-grey {
  border-color: #777; }

.border-top-grey {
  border-top-color: #777; }

.border-bottom-grey {
  border-bottom-color: #777; }

.border-left-grey {
  border-left-color: #777; }

.border-right-grey {
  border-right-color: #777; }

.text-grey-300 {
  color: #999; }
  .text-grey-300[href]:hover, .text-grey-300[href]:focus {
    color: #868686; }

.bg-grey-300 {
  background-color: #999; }

.border-grey-300 {
  border-color: #999; }

.border-top-grey-300 {
  border-top-color: #999; }

.border-bottom-grey-300 {
  border-bottom-color: #999; }

.border-left-grey-300 {
  border-left-color: #999; }

.border-right-grey-300 {
  border-right-color: #999; }

.text-grey-400 {
  color: #888; }
  .text-grey-400[href]:hover, .text-grey-400[href]:focus {
    color: #757575; }

.bg-grey-400 {
  background-color: #888; }

.border-grey-400 {
  border-color: #888; }

.border-top-grey-400 {
  border-top-color: #888; }

.border-bottom-grey-400 {
  border-bottom-color: #888; }

.border-left-grey-400 {
  border-left-color: #888; }

.border-right-grey-400 {
  border-right-color: #888; }

.text-grey-600 {
  color: #666; }
  .text-grey-600[href]:hover, .text-grey-600[href]:focus {
    color: #535353; }

.bg-grey-600 {
  background-color: #666; }

.border-grey-600 {
  border-color: #666; }

.border-top-grey-600 {
  border-top-color: #666; }

.border-bottom-grey-600 {
  border-bottom-color: #666; }

.border-left-grey-600 {
  border-left-color: #666; }

.border-right-grey-600 {
  border-right-color: #666; }

.text-grey-700 {
  color: #555; }
  .text-grey-700[href]:hover, .text-grey-700[href]:focus {
    color: #424242; }

.bg-grey-700 {
  background-color: #555; }

.border-grey-700 {
  border-color: #555; }

.border-top-grey-700 {
  border-top-color: #555; }

.border-bottom-grey-700 {
  border-bottom-color: #555; }

.border-left-grey-700 {
  border-left-color: #555; }

.border-right-grey-700 {
  border-right-color: #555; }

.text-grey-800 {
  color: #444; }
  .text-grey-800[href]:hover, .text-grey-800[href]:focus {
    color: #313131; }

.bg-grey-800 {
  background-color: #444; }

.border-grey-800 {
  border-color: #444; }

.border-top-grey-800 {
  border-top-color: #444; }

.border-bottom-grey-800 {
  border-bottom-color: #444; }

.border-left-grey-800 {
  border-left-color: #444; }

.border-right-grey-800 {
  border-right-color: #444; }

.alpha-slate {
  background-color: #ECEFF1; }

.text-slate {
  color: #607D8B; }
  .text-slate[href]:hover, .text-slate[href]:focus {
    color: #506974; }

.bg-slate {
  background-color: #607D8B; }

.border-slate {
  border-color: #607D8B; }

.border-top-slate {
  border-top-color: #607D8B; }

.border-bottom-slate {
  border-bottom-color: #607D8B; }

.border-left-slate {
  border-left-color: #607D8B; }

.border-right-slate {
  border-right-color: #607D8B; }

.text-slate-300 {
  color: #90A4AE; }
  .text-slate-300[href]:hover, .text-slate-300[href]:focus {
    color: #7a929e; }

.bg-slate-300 {
  background-color: #90A4AE; }

.border-slate-300 {
  border-color: #90A4AE; }

.border-top-slate-300 {
  border-top-color: #90A4AE; }

.border-bottom-slate-300 {
  border-bottom-color: #90A4AE; }

.border-left-slate-300 {
  border-left-color: #90A4AE; }

.border-right-slate-300 {
  border-right-color: #90A4AE; }

.text-slate-400 {
  color: #78909C; }
  .text-slate-400[href]:hover, .text-slate-400[href]:focus {
    color: #657d89; }

.bg-slate-400 {
  background-color: #78909C; }

.border-slate-400 {
  border-color: #78909C; }

.border-top-slate-400 {
  border-top-color: #78909C; }

.border-bottom-slate-400 {
  border-bottom-color: #78909C; }

.border-left-slate-400 {
  border-left-color: #78909C; }

.border-right-slate-400 {
  border-right-color: #78909C; }

.text-slate-600 {
  color: #546E7A; }
  .text-slate-600[href]:hover, .text-slate-600[href]:focus {
    color: #445a63; }

.bg-slate-600 {
  background-color: #546E7A; }

.border-slate-600 {
  border-color: #546E7A; }

.border-top-slate-600 {
  border-top-color: #546E7A; }

.border-bottom-slate-600 {
  border-bottom-color: #546E7A; }

.border-left-slate-600 {
  border-left-color: #546E7A; }

.border-right-slate-600 {
  border-right-color: #546E7A; }

.text-slate-700 {
  color: #455A64; }
  .text-slate-700[href]:hover, .text-slate-700[href]:focus {
    color: #35464d; }

.bg-slate-700 {
  background-color: #455A64; }

.border-slate-700 {
  border-color: #455A64; }

.border-top-slate-700 {
  border-top-color: #455A64; }

.border-bottom-slate-700 {
  border-bottom-color: #455A64; }

.border-left-slate-700 {
  border-left-color: #455A64; }

.border-right-slate-700 {
  border-right-color: #455A64; }

.text-slate-800 {
  color: #37474F; }
  .text-slate-800[href]:hover, .text-slate-800[href]:focus {
    color: #273338; }

.bg-slate-800 {
  background-color: #37474F; }

.border-slate-800 {
  border-color: #37474F; }

.border-top-slate-800 {
  border-top-color: #37474F; }

.border-bottom-slate-800 {
  border-bottom-color: #37474F; }

.border-left-slate-800 {
  border-left-color: #37474F; }

.border-right-slate-800 {
  border-right-color: #37474F; }

.border-transparent {
  border-color: transparent !important; }

.border-top-transparent {
  border-top-color: transparent !important; }

.border-bottom-transparent {
  border-bottom-color: transparent !important; }

.border-left-transparent {
  border-left-color: transparent !important; }

.border-right-transparent {
  border-right-color: transparent !important; }

.border-light-alpha {
  border-color: rgba(255, 255, 255, 0.1); }

.border-top-light-alpha {
  border-top-color: rgba(255, 255, 255, 0.1); }

.border-bottom-light-alpha {
  border-bottom-color: rgba(255, 255, 255, 0.1); }

.border-left-light-alpha {
  border-left-color: rgba(255, 255, 255, 0.1); }

.border-right-light-alpha {
  border-right-color: rgba(255, 255, 255, 0.1); }

.border-dark-alpha {
  border-color: rgba(0, 0, 0, 0.1); }

.border-top-dark-alpha {
  border-top-color: rgba(0, 0, 0, 0.1); }

.border-bottom-dark-alpha {
  border-bottom-color: rgba(0, 0, 0, 0.1); }

.border-left-dark-alpha {
  border-left-color: rgba(0, 0, 0, 0.1); }

.border-right-dark-alpha {
  border-right-color: rgba(0, 0, 0, 0.1); }

.bg-dark-alpha {
  background-color: rgba(0, 0, 0, 0.2); }

.bg-light-alpha {
  background-color: rgba(255, 255, 255, 0.1); }

/* ------------------------------------------------------------------------------
 *
 *  # Global configuration
 *
 *  Here you can change main theme, enable or disable certain components and
 *  optional styles. This allows you to include only components that you need.
 *
 *  'true'  - enables component and includes it to main CSS file.
 *  'false' - disables component and excludes it from main CSS file.
 *
 *  Layout helper: @if $layout == 'base' {...}
 *  Theme helper: @if $theme == 'material' {...}
 *  Component helper: @if $enable-* {...}
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Custom Limitless functions
 *
 *  Utility mixins and functions for evalutating source code across our variables, maps, and mixins.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Custom template mixins
 *
 *  All custom mixins are prefixed with "ll-" to avoid conflicts
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Main colors
 *
 *  List of the template main color palettes
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Default Bootstrap variable overrides
 *
 *  Variables should follow the `$component-state-property-size` formula for
 *  consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
 *  Also includes custom variables, all marked with "!default" flag.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Additional variables
 *
 *  Mainly 3rd party libraries and additional variables for default
 *  Bootstrap components.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Mixins
 *
 *  Import Bootstrap mixins with overrides
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Badge mixin
 *
 *  Override and extend default badge mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Buttons mixin
 *
 *  Override and extend default buttons mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Caret mixin
 *
 *  Override and extend default cared mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Forms mixin
 *
 *  Override and extend default forms mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Background mixin
 *
 *  Override and extend default background mixin.
 *
 * ---------------------------------------------------------------------------- */
.dash {
  padding: 0 .625rem; }

.navbar-nav-link {
  padding: 0; }
  .navbar-nav-link i {
    color: #42A5F5; }

.breadcrumb-line {
  padding-top: 0.3rem; }

.breadcrumb > li {
  padding: .625rem 0; }
  .breadcrumb > li > a {
    display: inline-block;
    color: inherit;
    transition: opacity ease-in-out 0.15s; }
    @media screen and (prefers-reduced-motion: reduce) {
      .breadcrumb > li > a {
        transition: none; } }
    .breadcrumb > li > a:hover, .breadcrumb > li > a:focus,
    .show > .breadcrumb > li > a {
      opacity: 0.75; }

.content-wrapper .alert-page-header {
  width: 100%;
  text-align: center; }

.balInfo, .balAmount {
  display: inline-block; }

.balInfo {
  padding-right: 1rem; }

#infoBox {
  width: 100%;
  clear: both;
  display: none;
  min-height: 40px;
  color: #fff; }
  #infoBox .alert {
    margin-bottom: 0; }

.sidebar-user .media-body {
  margin-top: 0.6rem; }

.navigation li a i {
  font-size: 17px; }

.navbar-brand img {
  margin: 0;
  height: 22px; }

.navbar-nav > li > a {
  text-align: center; }

.secTop {
  margin-bottom: 0; }
  .secTop .card-body {
    padding: 0.55rem; }
  @media (max-width: 770px) {
    .secTop {
      margin-top: 15px;
      text-align: center;
      text-align: -webkit-center; }
      .secTop .balBars {
        display: none; } }

.fulltext-container {
  margin-top: 1rem; }
  .fulltext-container img {
    max-width: 100% !important;
    max-height: 100% !important; }

td.hasSuborders input {
  display: none; }

/* copy from aquincum theme - mystyle.css*/
.assessment_of_satisfaction_div, .prod_rejected_reason_div {
  display: none;
  padding: 15px 5px 0 0; }

.block_correctional, .block_correctional_border {
  display: none; }

.color_version_block {
  display: none; }

.storage_block, .cut_slats_block {
  display: none; }

.tab_container {
  overflow: visible !important; }

.moscow_note {
  display: none; }

.courier_shipping_address {
  display: none; }

.use_divisions_block {
  display: none; }

.change_cargo_consignee {
  display: none; }

.brigade_div {
  padding: 15px 5px 0 0;
  display: none; }

.brigades_separate_div {
  display: none; }

.text_div, .design_comment_div {
  display: none; }

.text_div .errorMessage {
  display: none; }

.corr_text_div, .comment_div {
  display: none; }

.corr_text_div .errorMessage2, .comment_div .errorMessage3, .production_paused_comment_div .errorMessage4, .text_div .errorMessage {
  display: none;
  color: red;
  font-size: 11px; }

.complaint_div {
  margin-top: 10px;
  display: none; }
  .complaint_div .checker {
    margin-right: 5px; }

/* /copy from aquincum theme - mystyle.css*/
.processing_div {
  margin-top: 10px; }
  .processing_div .bg-teal {
    color: #ffffff; }

#uniform-Complaint_files_0 .action {
  font-size: 11px;
  padding: 7px;
  border: 1px solid #ccc; }

.fulltext-container img {
  max-width: 100% !important;
  max-height: 100% !important; }

#dyn .empty {
  padding: 10px; }

#dyn .titleIcon h5 {
  margin-top: -3px; }

#dyn .datatable-header {
  position: absolute;
  right: 80px;
  top: -76px;
  border: none; }
  @media (max-width: 620px) {
    #dyn .datatable-header {
      right: 45px; } }

#dyn .panel-heading .checker {
  top: -1px; }

#dyn .panel-heading .panel-title {
  display: inline-block;
  margin-left: 15px; }

#dyn .search-btn {
  background-color: inherit;
  position: absolute;
  top: 25px;
  right: 35px;
  font-size: 25px; }

#dyn .table td {
  padding: 5px;
  font-size: 11px; }

#dyn .table .child li {
  border-bottom: 1px solid #dad8d8; }

#dyn .table tr:first-child > th {
  font-size: 11px;
  padding: 0 5px; }

#dyn .order-payment:hover {
  cursor: pointer; }

#dyn .dataTable .panel-heading {
  padding-left: 10px; }
  #dyn .dataTable .panel-heading .panel-title {
    display: inline-block;
    margin-left: 10px; }

#dyn .dataTable > thead > tr > th[class*="sort"]:after, #dyn .dataTable > thead > tr > th[class*="sort"]:before {
  content: "" !important; }

#dyn .dataTable td.child li span {
  padding: 8px 34px;
  border-top: 1px solid #e0e0e085;
  font-size: 12px; }
  @media (max-width: 490px) {
    #dyn .dataTable td.child li span {
      font-size: 12px;
      padding: 7px 0;
      display: block;
      text-align: center; } }

#dyn .dataTable .icons-list .fa-cog {
  font-size: 20px; }

@media (min-width: 479px) {
  #dyn .dataTable .open > .dropdown-menu {
    top: auto; } }

#dyn .dataTable .dropdown, #dyn .dataTable .dropleft, #dyn .dataTable .dropright, #dyn .dataTable .dropup {
  position: initial; }

#dyn .datatable-scroll-wrap {
  border-top: 1px solid #cccc; }

#dyn .order_status {
  display: block;
  text-align: center; }

#dyn .search_buttons {
  text-align: center;
  padding-bottom: 20px; }
  @media (max-width: 490px) {
    #dyn .search_buttons {
      text-align: left;
      padding-left: 25px; } }
  #dyn .search_buttons .btn {
    display: inline-block; }

#dyn .dataTables_paginate {
  border: none;
  margin: 0 10px 8px 0; }
  #dyn .dataTables_paginate ul {
    list-style-type: none; }
    #dyn .dataTables_paginate ul li {
      display: inline-block;
      border-radius: 3px;
      padding: 3px;
      border: 1px solid #ccc; }
      #dyn .dataTables_paginate ul li a {
        padding: 7px;
        color: #333333; }
    #dyn .dataTables_paginate ul li.selected {
      background-color: #455A64; }
      #dyn .dataTables_paginate ul li.selected a {
        color: #ffffff; }

#dyn #uniform-orderPaginateSize {
  display: inline-block; }

#dyn .datatable-footer {
  padding: 0;
  padding-top: 10px; }
  #dyn .datatable-footer .dataTables_info {
    padding: 15px; }

#dyn .days_left_to_order {
  text-align: center;
  font-size: 12px; }

#dyn .uniform-checker {
  display: inline-block; }

.order_status {
  color: #ffffff;
  padding: 9px;
  border-radius: 3px;
  font-size: 12px; }

#order-search-form .selector .bootstrap-select, #order-search-form .selector > span:first-child {
  display: none; }

#order-search-form .input-group,
#order-search-form .input-group input {
  width: 100%; }

#order-search-form #orderPaginateSize {
  width: initial; }

#order-search-form .search_div h6 {
  text-align: left; }

@media (max-width: 1000px) {
  a.suborders {
    display: none; } }

.yellow {
  background: #FFD8AB !important; }

.thisRow {
  background: #2196f32e !important; }

.attention_row {
  background: #f9eeee !important; }

.order_payment_status {
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0 2px 3px silver;
  color: white;
  display: block;
  max-width: 10px;
  min-height: 10px;
  margin: auto; }

.dialog-with-overflow {
  overflow-y: paged-y !important;
  overflow-y: -webkit-paged-y !important; }

.ui-dialog {
  border: none;
  border-radius: 0;
  min-width: 470px;
  z-index: 1075;
  position: absolute; }
  @media (max-width: 500px) {
    .ui-dialog {
      min-width: 270px;
      top: 20px; } }
  .ui-dialog .ui-dialog-titlebar {
    padding: 10px;
    border: none;
    border-radius: 0;
    background: #42A5F5;
    color: #ffffff; }
  .ui-dialog .picker {
    position: relative; }
  .ui-dialog .selector {
    max-width: 100%; }
    .ui-dialog .selector > span:first-child {
      display: none; }
  .ui-dialog .ui-dialog-buttonset button,
  .ui-dialog #uniform-Order_traffic_ticket_name .action button {
    border: none;
    padding: 7px 12px;
    border-radius: 3px;
    background-color: #2196F3;
    color: #ffffff; }
    .ui-dialog .ui-dialog-buttonset button:hover,
    .ui-dialog #uniform-Order_traffic_ticket_name .action button:hover {
      background: #2196F3;
      color: #ffffff; }

.ui-widget-overlay {
  background: #000;
  opacity: 0.5; }

#status-form .selector {
  width: 100% !important; }

.ui-dialog .table-row {
  font-size: 11px; }
  .ui-dialog .table-row td, .ui-dialog .table-row th {
    padding: 6px; }
  .ui-dialog .table-row .order_status {
    padding: 3px;
    display: block;
    font-size: 11px; }

#order-form-steps .formRow {
  border: 1px solid #cccccc94;
  padding: 10px; }
  #order-form-steps .formRow .selector > span:first-child {
    display: none; }

.invoice_products .footable-row-detail-row {
  text-align: center;
  padding: 6px;
  border-bottom: 1px solid #ccc; }

.file_link, .file_note {
  font-size: inherit; }

.picker__footer button:focus, .picker__footer button:hover {
  background: #2196F3;
  color: #ffffff; }

.hidden {
  display: none; }

span.hide, .material_template, .borders, .need_border, .not_standard, .is_circular {
  display: none; }

.compare {
  display: none; }

.respect_series {
  background: none repeat scroll 0 0 #fff;
  border: 1px solid silver;
  padding: 20px; }

#Order_shipping_address,
#Order_courier_shipping_address,
#Order_shipping_city {
  padding-left: 10px !important; }

.suggestions-suggestions {
  top: 35px !important;
  left: 10px !important;
  z-index: 1000;
  padding: 13px;
  width: 100% !important;
  background: #e2e2e2; }
  .suggestions-suggestions .suggestions-suggestion {
    padding: 5px;
    left: 5px !important;
    padding-left: 8px !important; }
    .suggestions-suggestions .suggestions-suggestion:hover {
      cursor: pointer;
      background: #ededed; }

.suggestions-input {
  padding-left: 14px !important; }

.errorMessage {
  padding: 5px;
  margin-top: 5px;
  background: #E57373;
  color: #FFEBEE;
  border: 1px solid #F44336; }

.files-items-view .card {
  margin: 15px;
  min-width: 300px; }

img.loading {
  display: none; }

.product_category {
  padding: 3px; }

.category_items, .product_item {
  display: none;
  background-color: #fafafa; }

.product_category.cat_level_2, .product_tree .cat_level_2 {
  display: none; }

.cat_level_2 {
  padding-left: 15px; }

.product_item {
  padding-left: 40px; }

.product_tree .cat_level_2 td {
  background-color: #f6f6f6; }

.product_tree .cat_level_1 td {
  background-color: #f1f1f1; }

.order_image img {
  max-width: 100%; }

@media (max-width: 400px) {
  .tbl_total {
    display: none; } }

.image_box img {
  max-width: 500px; }

.ui-accordion .ui-accordion-header:first-child {
  margin-top: 5px; }

.dt-button-collection.fixed {
  overflow-y: scroll;
  max-height: 90%; }
  @media (max-width: 820px) {
    .dt-button-collection.fixed {
      left: 40%; } }
  @media (max-width: 430px) {
    .dt-button-collection.fixed {
      left: 30%; } }

table.table-statuses-main .order_status {
  max-width: 300px; }

table.table-statuses-main thead tr > th {
  padding: 8px !important; }

.page-letters #dyn .table tr:first-child > th, .page-with-search #dyn .table tr:first-child > th {
  padding: 7px; }

.page-letters #dyn .table td, .page-with-search #dyn .table td {
  font-size: 13px; }

.page-with-search .table-search-button {
  font-size: 20px; }

.font-size-25 {
  font-size: 25px !important; }

.font-size-15 {
  font-size: 15px !important; }

table.table-statuses-access {
  font-size: 12px; }
  table.table-statuses-access tbody > tr:hover, table.table-statuses-access tbody tr.parent {
    background: #E0F2F1; }
  table.table-statuses-access thead > tr > td {
    padding: 8px; }
  table.table-statuses-access .dtr-data {
    text-align: right;
    width: 15px; }

.project_status, .idea_status {
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 2px 3px silver;
  color: white;
  display: block;
  font-size: 12px;
  font-weight: bolder;
  padding: 4px 10px;
  text-align: center;
  max-width: 200px; }

.ui-datepicker:not(.ui-datepicker-inline) {
  z-index: 1076 !important; }

.permission-table .dtr-data .uniform-checker {
  float: right; }

.dtr-inline.collapsed tbody tr td:first-child {
  white-space: normal; }

.table-user-group-permission .dataTable tr.child .dtr-title {
  padding-bottom: 10px; }

@media (max-width: 463px) {
  .catalog-product-control-btns div, .catalog-product-control-btns a {
    width: 100%; } }

.image_name a:hover {
  color: #ffffff; }

.ui-datepicker .ui-datepicker-calendar td.ui-datepicker-today .ui-state-highlight,
.ui-datepicker .ui-datepicker-calendar td a:hover {
  background: #26A69A;
  color: #ffffff; }

.error-title {
  color: #fff;
  font-size: 8.125rem;
  line-height: 1;
  margin-bottom: 2.5rem;
  font-weight: 300;
  text-stroke: 1px transparent;
  display: block;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2), 0 20px 20px rgba(0, 0, 0, 0.15); }
  @media (min-width: 576px) {
    .error-title {
      font-size: 12.5rem; } }

.offline-title {
  font-size: 6.875rem; }
  @media (min-width: 576px) {
    .offline-title {
      font-size: 10rem; } }

.catalog-product-admin .product_tree .cat_level_2 {
  display: inherit; }

@media (max-width: 650px) {
  #brigades-work-graph {
    display: none; } }

#order-list-brigades-graph {
  height: 180px;
  top: 0px;
  width: 100%; }
